import {Component} from '@angular/core';
import {HelperService} from "../../services/helper.service"
import {NavigationEnd, Router} from "@angular/router"
import {trigger, state, style, animate, transition} from '@angular/animations';
@Component
	({
		selector: 'app-tradeidea',
		templateUrl: './tradeidea.component.html',
		styleUrls: ['./tradeidea.component.scss'],
		animations: [
			// EXTENSION CARDS SHOW / HIDE ANIMATION
			trigger('enterExitLeft', [
				transition(':enter', [
					style({
						opacity: 0,
						transform: 'translateX(-500px)'
					}),
					animate(
						'400ms ease-in',
						style({
							opacity: 1,
							transform: 'translateX(0)'
						})
					),
				]),
				transition(':leave', [
					animate(
						'400ms ease-in',
						style({
							opacity: 0,
							transform: 'translateX(-500px)'
						})
					),
				]),
			]),

			// EXTENSION OVERVIEW SHOW / HIDE ANIMATION
			trigger('enterExitRight', [
				transition(':enter', [
					style({
						opacity: 0,
						transform: 'translateX(500px)'
					}),
					animate(
						'400ms ease-in',
						style({
							opacity: 1,
							transform: 'translateX(0)'
						})
					),
				]),
				transition(':leave', [
					animate(
						'400ms ease-in',
						style({
							opacity: 0,
							transform: 'translateX(500px)'
						})
					),
				]),
			]),
		]
	})

export class TradeIdeaComponent
{
	// CONFIGURATION FOR ADD NEW LIST BUTTON AND MESSAGES
	config: any =
		{
			search: false,
			overviewSwitch: true,
			addNew: false
		};

	// HOLD THE CURRENT ROUTE TAB.
	public currentRoute: string = '';

	public currentView: any = 'dashboard';

	// THIS ARRAY WILL HOLD THE ALL TABS AND THEIR RESPECTIVE ROUTES
	public tradeIdeaTabs =
		[
			{
				id: 'BOOLEAN-TAB-TRADEFREE',
				route: 'tradeideafree',
				name: 'Your Trade Ideas | Free',
			},
			{
				id: 'BOOLEAN-TAB-TRADEKIPS',
				route: 'tradeideakip',
				name: 'All Trade Ideas | Kips',
			},
		]

	constructor
		(
			public helper: HelperService, // LOADING HelperService CLASS WITH ALIAS helper TO GET ACCESS TO THE GLOBAL DATA.
			public router: Router, // LOADING ROUTER CLASS, THIS WILL REDIRECT FROM ONE COMPONENT TO ANOTHER EITHER FROM A CLICK EVENT, A URL, OR A JUMPCODE.
		)
	{
	}

	// THIS ANGULAR INBUILT FUNCTION WILL BE CALLED AFTER THIS COMPONENT IS INITIALIZED,
	// TO HANDLE ANY ADDITIONAL INITIALIZATION TASKS.
	ngOnInit()
	{
		// SET THE CURRENT ROUTE NAME.
		const urlArray = this.router.url.split('/'); // SPLITTING THE URL ON THE BASIS OF SLASH.
		console.log('urlArray', urlArray);
		if (urlArray[2])
		{
			this.currentRoute = urlArray[2];
		}
		else // DEFAULT ROUTE SET.
		{
			this.currentRoute = 'tradeideafree';
		}

		// ROUTE EVENTS SUBSCRIBE FOR GETTING ROUTE URL CHANGE AND APPLY CURRENT ROUTE NAME.
		this.router.events.subscribe((routeinfo) =>
		{
			if (routeinfo instanceof NavigationEnd)
			{
				if (routeinfo.url)
				{
					const url = routeinfo.url; // STORING THE URL INTO LOCAL VARIABLE.
					const urlArray = url.split('/'); // SPLITTING THE URL ON THE BASIS OF SLASH.
					if (urlArray[2])
					{
						this.currentRoute = urlArray[2];
					}
					else // DEFAULT ROUTE SET.
					{
						this.currentRoute = 'tradeideafree';
					}
				}
			}
		});
	}

	// ON TOGGLE VIEW / OVERVIEW SWITCH CHANGE.
	onToggleOverview(switchState: boolean)
	{
		console.log('receiving', switchState);
		this.currentView = '';

		// IF VIEW EXTENSION IS ENABLED.
		setTimeout(() =>
		{
			this.currentView = switchState ? 'overview' : 'dashboard';
		}, 500);
	}
}
