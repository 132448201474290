/*
 FILENAME: lk_client/src/app/services/init.provider.ts
 AUTHOR: Qasim Zubair
 SUMMARY: LOADS ALL REQUIRED DATA FROM DATABASE THAT IS NECESSARY FOR THE APP TO RUN SUCCESSFULLY.
 PURPOSE: IT WILL INITIALIZE THE APP BY MAKING VARIABLES, MESSAGES AND LAYOUT DATA AVAILABLE TO THE APP.
 IMPORTING FILES: db.service.ts | helper.service.ts | login.service.ts
 SUBSCRIBING FILES: app.component.ts
 LAST COMMIT DATE: July 13, 2020
 */

// IMPORTING THE ANGULAR MODULES FOR PERFORMING BASIC ANGULAR FRAMEWORK OPERATIONS.
import {Injectable, Injector} from '@angular/core';
// IMPORTING THE LK DB SERVICE TO COMMUNICATE WITH THE DATABASE.
import {DbService} from "./db.service";
// IMPORTING THE LK HELPER SERVICE TO GET ACCESS TO THE GLOBAL DATA AND SERVICES
// SO THAT WE CAN USE THE GLOBAL DATA AND FUNCTIONS LIKE MESSAGING AND SITE VARIABLES.
import {HelperService} from "./helper.service";
// IMPORTING NODE MODULE TO DETECT THE DEVICE. IS IT MOBILE, DESKTOP OR TABLET?
import {DeviceDetectorService} from "ngx-device-detector";
// IMPORTING THE TOASTER SERVICE SO THAT WE CAN SHOW THE TOAST MESSAGES AT THE TOP RIGHT OF THE SCREEN.
// IMPORTING JAVASCRIPT LIBRARY TO READ THE USER AGENT STRING PROVIDED BY BROWSER.
// IT PROVIDES INFORMATION ABOUT BROWSER, BROWSER VERSION, OPERATING SYSTEM AND ITS VERSION.
import * as UAParser from 'ua-parser-js';
import { ToastrService } from 'ngx-toastr';

// @Injectable DECLARES THIS AS A SERVICE SO THAT WE CAN INJECT THIS SERVICE INTO ANY OTHER COMPONENT OR SERVICE.
@Injectable 
({
	providedIn: "root"
})

// DECLARING THE InitProvider CLASS WITH EXPORT SO THAT WE CAN IMPORT THIS SERVICE INTO ANY OTHER COMPONENT OR SERVICE.
export class InitProvider
{
	private toastr: ToastrService;
	// CLASS CONSTRUCTOR, THIS WILL BE THE FIRST FUNCTION TO BE EXECUTED WHEN THIS CLASS LOADS.
	// HERE WE WILL TELL ANGULAR TO INJECT A DEPENDENCY BY SPECIFYING A CONSTRUCTOR
	// PARAMETER WITH THE DEPENDENCY TYPE.
	constructor
	( 
		private injector:Injector,
		public dbService: DbService,
		public helper: HelperService,
		public deviceService: DeviceDetectorService,
	)
	{
		// WAITING FOR LAYOUT DATA TO BE AVAILABLE IN THIS APP. THIS LAYOUT DATA WILL COME FROM
		// DATABASE WHEN THE PAGE LOADS.
		this.dbService.roomLayoutObs.subscribe (layoutData =>
		{
			// IF WE GOT THE LAYOUT DATA FROM DATABASE. . .
			if (layoutData)
			{
				// STORE IT IN GLOBAL VARIABLE SO IT CAN BE ACCESSIBLE IN WHOLE APP.
				this.helper.layoutData = layoutData;
			}
			else
			{
				this.toastr = this.injector.get(ToastrService);
				// IF THERE WAS AN ERROR WHILE GETTING THE LAYOUT DATA THEN WE WILL DISPLAY THE ERROR.
				this.toastr.error ("App failed to get layouts for this device.", "App failed to start", {disableTimeOut:true});
				console.error ("App failed to get layouts for this device");
			}
		});
	}
	
	// THIS FUNCTION IS CALLED ASYNCHRONOUSLY WHILE THE APPLICATION IS INITIALIZING, AND
	// RETRIEVES ALL STATIC DATA LIKE SITEVARIABLES, SITEMESSAGES, JUMPCODES, LAYOUT DATA ETC
	public async load()
	{
		return new Promise ((resolve, reject) =>
		{
			// GETTING SITEVARIABLE, MESSAGES AND LAYOUT DATA FROM DATABASE.
			this.dbService.getAppInitialData();
			
			// SUBSCRIBING TO THE RETRIEVAL OF DATA, MENTIONED ABOVE, FROM DATABASE.
			this.dbService.appStaticDataObs.subscribe (data =>
			{
				// console.log('getAppInitialData',data);
				// GET THE SITE VARIABLES AND STORE IN THE GLOBAL VARIABLE IN HELPER SERVICE.
				if (data ['sitevariables'])
				{
					this.helper.sitevariables = data ['sitevariables'];
				}

				// GET THE SITE VARIABLES LKP AND STORE IN THE GLOBAL VARIABLE IN HELPER SERVICE.
				if (data ['sitevariableslkp'])
				{
					this.helper.sitevariableslkp = data ['sitevariableslkp'];
					// console.log('getAppInitialData sitevariableslkp',this.helper.sitevariableslkp);
				}
				
				// GETTING ROOM STATE TIMES, TO DISPLAY DIFFERENT STATES OF THE ROOM DEPENDING ON THE TIME.
				// WE WILL BE ABLE TO USE IT ON FIND ROOM PAGE.
				this.helper.roomStateData = data ['room_state_data'];
				
				// GET BUTTONS FOR THE FIND ROOM PAGE.
				this.helper.buttonFR = data ['buttonFR'];

				// GET BUTTONIC DATA FOR THE ROOM PAGE.
				this.helper.buttonIC = data ['buttonIC'];
				
				// GET BUTTONIC DATA FOR THE ROOM PAGE.
				this.helper.roomFeedbackButtons = data ['room_feedback'];

				// GET LIST OF SUPPORTED BROWSERS SO WE CAN COMPARE THE CURRENT BROWSER WITH THIS LIST.
				this.helper.browserList = data ['browserList'];
				
				// INITIALIZE THE ARRAY TO HOLD THE STATE TIMES OF ROOM. THIS WILL BE FOR TIMED ROOM.
				// WE WILL BE STORING WHAT SHALL BE THE STATE OF THE ROOM AT THE GIVEN TIME.
				this.helper.roomStateTimes = [];
				
				// WE WILL GET DATA FROM DATABASE IN THE FORM OF ROWS. NOW WE WILL GO THROUGH EACH ROW
				// AND SET THE DATA IN AN ARRAY SO WE CAN EASILY USE IT IN THE CODE.
				// IF WE GOT THE DATA AND NUMBER OF ROWS ARE GREATER THAN 0. . .
				if (this.helper.roomStateData.length > 0)
				{
					// . . . LOOP THROUGH THE ROWS TO FIX THE FORMAT.
					this.helper.roomStateData.forEach (row =>
					{
						// PUT DATA IN GLOBAL VARIABLE IN HELPER SERVICE.
						this.helper.roomStateTimes [row['button-state_id']] = row.start;
					});
				}
				
				// IF WE GOT THE JUMPCODES FROM DATABASE. . .
				if (data ['sitejumpcodes'])
				{
					// . . .STORE THE JUMPCODES DATA INTO A VARIABLE.
					this.helper.sitejumpcodeSource = data ['sitejumpcodes'];

					// PROCESS SITE JUMPCODE FOR CURRENT USER.
					this.helper.processCurrentUserJumpcodes ();
				}
				
				// GET THE SITEMESSAGES AND STORE IN THE HELPER.SITEMESSAGES.
				this.helper.sitemessages = data ['sitemessages'];

				// GET THE LKP SITEMESSAGES AND STORE IN THE HELPER.SITEMESSAGESLKP.
				this.helper.sitemessageslkp = data ['sitemessageslkp'];
				
				// GET THE ROOMFORMATS AND STORE IN THE HELPER.ROOMFORMATSDATA.
				if (data ["roomformats"])
				{
					this.helper.roomformatsData = data ["roomformats"];
				}
				
				// GET THE ROOM CATEGORIES. WE WILL DISPLAY THIS DATA ON THE FIND ROOM PAGE.
				if (data ["room_categories"])
				{
					this.helper.roomCategories = data ["room_categories"];
				}
				
				// SET THE VARIABLE THAT STORES THE FIND ROOM MARGINS. THESE VALUES COME FROM THE SITEVARIABLE TABLE.
				// THIS IS ONE BIG VARIABLE BUT WHEN COMPILED IT WILL LOOK LIKE THIS CSS PROPERTY:
				// MARGIN: 10PX 20PX 20PX 10PX
				this.helper.svFindRoomMargins = this.helper.sitevariables ['SV-CSS-FINDROOM-MAINDIV-TOP'].value // MARGIN TOP IN NUMBER.
				+ this.helper.getValueType (this.helper.sitevariables ['SV-CSS-FINDROOM-MAINDIV-TOP'].valuetype) // VALUE FOR MARGIN TOP IN % OR PX
				+ this.helper.sitevariables ['SV-CSS-FINDROOM-MAINDIV-RIGHT'].value // MARGIN TOP IN NUMBER.
				+ this.helper.getValueType (this.helper.sitevariables ['SV-CSS-FINDROOM-MAINDIV-RIGHT'].valuetype) // VALUE FOR MARGIN TOP IN % OR PX
				+ this.helper.sitevariables ['SV-CSS-FINDROOM-MAINDIV-BOTTOM'].value // MARGIN TOP IN NUMBER.
				+ this.helper.getValueType (this.helper.sitevariables ['SV-CSS-FINDROOM-MAINDIV-BOTTOM'].valuetype) // VALUE FOR MARGIN TOP IN % OR PX
				+ this.helper.sitevariables ['SV-CSS-FINDROOM-MAINDIV-LEFT'].value // MARGIN TOP IN NUMBER.
				+ this.helper.getValueType (this.helper.sitevariables ['SV-CSS-FINDROOM-MAINDIV-LEFT'].valuetype); // VALUE FOR MARGIN TOP IN % OR PX
				
				// GATHER INFORMATION ABOUT THE CLIENT DEVICE NOW.
				this.clientInfo();
				
				// SAVE THE USER DEVICE INFORMATION LIKE OS, OS VERSION, BROWSER, SCREEN RESOLUTION ETC.
				this.helper.saveDeviceInfo();
				
				// GET THE LAYOUT FOR THE USER'S DEVICE. FOR EXAMPLE, IF THE USER IS JOINING FROM MOBILE
				// DEVICE THEN WE WILL GET LAYOUTS FOR MOBILE.
				this.dbService.getLayoutData (this.helper.devicetype_id);
				
				// IF THE USER IS ON MOBILE OR TABLET, THEN WE WILL CHECK FROM SITEVARIABLE WHETHER
				// WE DISPLAY THEM DEBUG NOTIFICATIONS OR NOT.
				if (this.helper.deviceType == "mobile" || this.helper.deviceType == "tablet")
				{
					// GET VALUE FROM SITEVARIABLE.
					this.helper.debug = (this.helper.sitevariables ['SV-DEBUG-MOBILE'] == "ON" ? true: false);
				}
				else if (this.helper.deviceType == "desktop")
				{
					// GET VALUE FROM SITEVARIABLE. WHETHER TO DISPLAY DEBUG NOTIFICATIONS FOR
					// DESKTOP OR NOT.
					this.helper.debug = (this.helper.sitevariables ['SV-DEBUG-DESKTOP'] == "ON" ? true: false);
				}

				// CHANGE THE STATIC DATA LOADED FLAG TO TRUE.
				this.helper.staticDataLoaded = true;

				console.log('Resolving initial provider. Now angular will load');
				
				// RESOLVING THE PROMISE.
				resolve(true);
			});
		});
	}
	
	// FUNCTION THAT GETS THE CLIENT DEVICE INFO LIKE BROWSER, OS AND DEVICE TYPE
	private clientInfo()
	{
		// GET USER DEVICE INFORMATION.
		this.helper.deviceInfo = new UAParser().getResult(); // GET RESULT FROM BROWSER USER AGENT.
		
		console.log ("code153a deviceInfo", this.helper.deviceInfo);
		console.log ("code153, this.deviceService", this.deviceService);
		
		// GET NAME OF OPERATING SYSTEM FROM BROWSER'S USER AGENT AND CONVERT IT TO LOWERCASE.
		let osName = this.helper.deviceInfo.os.name.toLowerCase();
		
		// ALL THE VARIABLES ARE SET IN THE HELPER SERVICE SO THEY CAN BE SHARED BY ALL COMPONENTS.
		// VARIABLE TO STORE CLIENT'S DEVICE INFORMATION.
		this.helper.clientInfo = this.deviceService.getDeviceInfo();
		this.helper.isMobile = this.deviceService.isMobile(); // IS IT A MOBILE DEVICE THAT IS OPENING THE APP?
		this.helper.isTablet = this.deviceService.isTablet(); // IS IT A TABLET.
		this.helper.isDesktopDevice = this.deviceService.isDesktop(); // IS IT A DESKTOP?
		this.helper.operatingSystem = this.deviceService.os; // NAME OF OPERATING SYSTEM.

		let height = document.documentElement.clientHeight; // STORE THE DOCUMENT HEIGHT IN LOCAL VARIABLE.
		let width = document.documentElement.clientWidth; // STORE THE DOCUMENT WIDTH IN LOCAL VARIABLE.
		
		// WE WILL CALCULATE IT BY DIVIDING HEIGHT BY WIDTH AND THE RESULT WILL BE USED TO DETERMINE
		// IF THE MOBILE IS SHORT, MEDIUM OR LONG.
		let calc: number = 0;
		
		// IF HEIGHT IS GREATER THAN WIDTH THEN WE WILL DIVIDE HEIGHT BY WIDTH, OTHERWISE WE WILL DIVIDE WIDTH BY
		// HEIGHT TO GET THE RATIO.
		if (height > width)
		{
			calc = Number ((height / width).toFixed (2)); // GETTING THE RATIO OF HEIGHT AND WIDTH.
		}
		else
		{
			calc = Number ((width / height).toFixed (2)); // GETTING THE RATIO OF WIDTH AND HEIGHT.
		}
		console.log ("code153, width:%s, height:%s, ratio:%s", width, height, calc);
		
		// IF USER IS ON MOBILE DEVICE THEN WE WILL CHECK WHAT DEVICE IS IT, SHORT, MEDIUM OR LONG.
		// EACH MOBILE TYPE WILL HAVE ITS OWN LAYOUT VALUES IN DATABASE. SO WE WILL FIGURE OUT
		// WHAT TYPE OF DEVICE IT IS.
		if (this.helper.isMobile)
		{
			// SETTING DEVICE TYPE AS MOBILE. SO WE CAN USE IT IN THE APP.
			this.helper.layoutType = "mobile";
			
			// WHAT TYPE IS IT? LONG, SHORT OR MEDIUM?
			let type;
			
			// IF RATIO IS GREATER THAN WHAT IS REQUIRED TO BE LONG MOBILE THEN IT IS LONG MOBILE.
			if (calc > Number (this.helper.sitevariables ['SV-MOBILE-ANDROIDLONG'].value))
			{
				type = "LONG"; // MOBILE TYPE IS LONG.
			}
			
			// IF RATIO IS LESS THAT WHAT IS REQUIRED TO BE SHORT MOBILE THEN IT IS SHORT MOBILE.
			else if (calc < Number (this.helper.sitevariables ['SV-MOBILE-ANDROIDSHORT'].value))
			{
				type = "SHORT"; // MOBILE TYPE IS SHORT.
			}
			else
			{
				// OTHERWISE, IF THE VALUES ARE BETWEEN LONG AND SHORT LIMIT,
				// THEN IT IS MEDIUM DEVICE.
				type = "MEDIUM"; // MOBILE TYPE IS MEDIUM.
			}
			
			// IF OPERATING SYSTEM IS IOS THEN WE WILL CONCATENATE THE TYPE WITH IOS OTHERWISE IT WILL BE ANDROID.
			this.helper.devicetype_id = osName == "ios" ? "IPHONE" + type: "ANDROID" + type;
			
			// STORING DEVICE TYPE IN GLOBAL VARIABLE IN HELPER CLASS SO WE CAN ACCESS IT IN THE WHOLE APP.
			this.helper.deviceType = "mobile";
		}
		else if (this.helper.isTablet)
		{
			// WE WILL CALL THIS TABLET AS DESKTOP BECAUSE IT RESEMBLES A DESKTOP MORE THAN MOBILE.
			// SO IT WILL BE EASIER IN CODE TO HANDLE BOTH DEVICES AS ONE.
			this.helper.layoutType = "desktop";
			
			// IF OS IS IOS THEN ITS AN IPAD. OTHERWISE WE WILL JUST CALL IT A TABLET.
			// WE WILL PICK LAYOUT FOR IPAD OR TABLET FROM LAYOUT TABLE.
			this.helper.devicetype_id = ((osName == "ios" || osName.indexOf("mac") > -1 || osName.indexOf("ipad") > -1) ? "IPAD": "TABLET");
			
			// THIS IS A TABLET DEVICE. IPAD IS ALSO A TABLET SO IN GENERAL WE
			// CAN CALL IT A TABLET. THIS VARIABLE IS ONLY TO REFERENCE IT IN CSS. HERE WE DON'T NEED
			// ANY FINE DETAIL LIKE IPAD OR ANDROID DEVICE.
			this.helper.deviceType = "tablet";
		}
		else
		{
			// IF ITS NEITHER MOBILE NOR TABLET THEN ITS A DESKTOP.
			this.helper.layoutType = "desktop"; // SETTING DEVICE TYPE AS DESKTOP.
			this.helper.devicetype_id = "DESKTOP"; // WE WILL PICK LAYOUT FOR DESKTOP FROM LAYOUT TABLE.
			this.helper.deviceType = "desktop"; // THIS A DESKTOP DEVICE.
		}
		
		// WE WILL ASSIGN DEVICE TYPE AS A CLASS TO BODY ATTRIBUTE.
		document.querySelector("body").classList.add(this.helper.deviceType);
		console.log ("devicetype_id ", this.helper.devicetype_id);
	}
}
