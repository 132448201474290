/*
         FILENAME: lkphone_mobile/src/app/services/network.service.ts
           AUTHOR: ICI/QZ
          SUMMARY: SEND HTTP REQUEST TO SERVER.
          PURPOSE: TO FETCH DATA FROM SERVER OR DATABASE USING HTTP REQUEST.
  IMPORTING FILES: TBD
SUBSCRIBING FILES: TBD
 LAST COMMIT DATE: November 08, 2023
*/

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
@Injectable
({
	providedIn: "root",
})

// THIS CLASS WILL BE USED TO SEND HTTP REQUEST TO SERVER.
export class NetworkService
{
	constructor
	(
		private http: HttpClient,
	)
	{
	}

	// MAKE HTTP POST REQUEST TO SERVER.
	public async post (url: string, data: any, options: any = {})
	{
		return this.httpRequest (url, "POST", data, options);
	}

	/**
	 * MAKE HTTP GET REQUEST TO SERVER.
	 * @param url: DON'T PASS THE BASE URL AND "API", JUST PASS THE END POINT.
	 * @param data: DATA TO BE SENT TO SERVER.
	 * @param options: OPTIONS TO BE SENT TO SERVER.
	 */
	public async get (url: string, data?: any, options: any = {})
	{
		return this.httpRequest (url, "GET", data, options);
	}

	/**
	 * MAKE HTTP PUT REQUEST TO SERVER. PUT IS USED TO UPDATE THE DATA.
	 * @param url: DON'T PASS THE BASE URL AND "API", JUST PASS THE END POINT.
	 * @param data: DATA TO BE SENT TO SERVER.
	 * @param options: OPTIONS TO BE SENT TO SERVER.
	 */
	public async put (url: string, data: any, options: any = {})
	{
		return this.httpRequest (url, "PUT", data, options);
	}

	/**
	 * THIS FUNCTION WILL MAKE HTTP REQUEST TO SERVER.
	 * @param url: DON'T PASS THE BASE URL AND "API", JUST PASS THE END POINT.
	 * @param method: POST, GET, PUT, DELETE.
	 * @param data: DATA TO BE SENT TO SERVER.
	 * @param options: OPTIONS TO BE SENT TO SERVER.
	 */
	private async httpRequest (url: string, method: string = "POST", data?: any, options: any = {})
	{
		// DOING A PROMISE CALL, IT WILL NOT RETURN THE RESPONSE UNTIL IT IS RESOLVED.
		return new Promise ((resolve) =>
		{
			try
			{
				// IF THE URL DOES NOT START WITH HTTPS: THEN ADD THE BASE URL.
				if (url.indexOf ("https://") == -1 && url.indexOf ("http://") == -1)
				{
					url = environment.apiURL + "/api/" + url;
				}
				let request;

				// IF ANY DATA IS PROVIDED IN JSON FORMAT THEN CONVERT IT INTO URL ENCODED FORMAT.
				if (data && method == "GET")
				{
					// CONVERTING JSON DATA INTO NAME VALUE PAIR.
					let urlEncodedData = "";
					let urlEncodedDataPairs = [];
					for (let name in data)
					{
						urlEncodedDataPairs.push (encodeURIComponent (name) + "=" + encodeURIComponent (data[name]));
					}
					urlEncodedData = urlEncodedDataPairs.join ("&").replace (/ /g, "%20");

					// APPENDING THE DATA INTO URL BUT FIRST MAKE SURE THE URL DOES NOT HAVE ? MARK.
					if (url.indexOf ("?") == -1)
					{
						url += "?" + urlEncodedData;
					}
					else // IF THERE IS A QUESTION MAKE THEN JUST APPEND THE PARAMS AT THE END.
					{
						url += "&" + urlEncodedData;
					}
					request = this.http.get<any> (url);
				}
				else if (method == "GET")
				{
					request = this.http.get<any> (url);
				}
				else if (method == "POST")
				{
					request = this.http.post<any> (url, data, options);
				}
				else if (method == "PUT")
				{
					request = this.http.put<any> (url, data, options);
				}
				else
				{
					console.error ("Invalid method type: " + method);
					resolve ({success: false, error: "Invalid method type: " + method});
				}
				request.subscribe (response =>
				{
					resolve (response);
				}, error =>
				{
					console.error ("error in sending network request.", error);
					resolve ({success: false, error: "Something went wrong. Please try again later."});
				});
			}
			catch (e)
			{
				console.error ("error in network request", e);
				resolve ({success: false, error: "Something went wrong. Please try again later."});
			}
		});
	}
}
