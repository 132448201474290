import { Injectable } from '@angular/core';
import {SafeResourceUrl} from "@angular/platform-browser"
import {environment} from "../../environments/environment"
import {SafePipe} from "../pipes/safe.pipe"

@Injectable({
	providedIn: 'root',
})
export class IframeService
{
	public mainLKURL = environment.mainLKURL;
	public urlAccount = this.mainLKURL + 'lkphone/?iframe=true&ngsw-bypass=true';
	public safeIframeUrl: SafeResourceUrl;
	private isIframeUrlLoaded: boolean = false;
	
	constructor
	(
		// LOADING SafePipe CLASS WITH ALIAS safe TO SOLVE SECURITY ISSUES FOR THE IFRAME URL.
		public safe: SafePipe
	)
	{
		console.log ("IframeService. loaded :==>", this.urlAccount)
	}
	
	loadIframeUrl(): SafeResourceUrl
	{
		if (!this.isIframeUrlLoaded)
		{
			console.log ("this.mainLKURL + 'lkphone'", this.urlAccount)
			this.safeIframeUrl = this.safe.transform (this.urlAccount);
			this.isIframeUrlLoaded = true;
		}
		return this.safeIframeUrl;
	}
}
