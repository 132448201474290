/*
 FILENAME: lk_client/src/app/app.component.ts
 AUTHOR: Qasim Zubair, Zulaikha Moidin
 SUMMARY: THIS FILE CONTAINS MAIN APPLICATION SKELETON LOGIC. THIS CODE RUNS WHEN THE APPLICATION STARTS.
 PURPOSE: TO START THE APPLICATION AND CONFIGURE GLOBAL CONSTANTS AND SITE VARIABLES
 AND SET OTHER GLOBAL VARIABLES.
 IMPORTING FILES: db.service.ts | helper.service.ts | login.service.ts
 SUBSCRIBING FILES: N/A
 LAST COMMIT DATE: October 7, 2020
 */

// STANDARD ANGULAR IMPORTS.
import {Component, ElementRef, HostListener, ChangeDetectorRef, TemplateRef, ViewChild, Injector} from '@angular/core';
// USEFUL IMPORTS FOR THIS COMPONENT.
import {Socket} from 'ngx-socket-io';
// IMPORTING THE TOASTER SERVICE SO THAT WE CAN SHOW THE TOAST MESSAGES AT THE TOP RIGHT OF THE SCREEN.
import {ToastrService} from 'ngx-toastr';
import {DbService} from './services/db.service';
// IMPORTING THE LK HELPER SERVICE TO GET ACCESS TO THE GLOBAL DATA AND SERVICES SO THAT WE CAN USE THE GLOBAL DATA AND FUNCTIONS.
import {HelperService} from './services/helper.service';
import {LoginService} from './services/login.service';
import {ActivatedRoute, ChildrenOutletContexts, NavigationEnd, Router, RoutesRecognized} from '@angular/router';
// IMPORTING THE LK DIALOG SERVICE TO DISPLAY A KENDO DIALOG.
import {DialogService} from './services/dialog.service';
import {environment} from '../environments/environment';

import {slideInAnimation} from './router-animations';
import {ModalComponent} from './components/shared/modal/modal.component';
import {DialogCloseResult, DialogRef, DialogService as KendoDialogService} from '@progress/kendo-angular-dialog';
import { filter, pairwise } from 'rxjs/operators';
import {
    bounceOutUpOnLeaveAnimation,
    fadeInOnEnterAnimation,
    fadeOutOnLeaveAnimation,
    slideInLeftAnimation,
    slideInRightAnimation
} from 'angular-animations';
import {animate, group, query, style, transition, trigger} from '@angular/animations';

import {ServerSocket} from "./services/socket.service";
import { CookieService } from 'ngx-cookie-service';
import { ZoomService } from "./services/zoom.service";
import {IframeService} from "./services/iframe.service"

// ADDING HTML AND CSS FILES FOR THIS COMPONENT
@Component
({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [
        './app.component.scss',
        '../animations.css'
    ],
    animations: [
        trigger('routeAnimations', [
            transition('home => dashboard, dashboard => tradeidea, tradeidea => tradenow, tradenow => tradehistory,' +
				' tradehistory => avatarmanager, avatarmanager => links', [
                query(':enter, :leave',
                    style({ position: 'absolute', width: '100%' }),
                    { optional: true }),
                group([
                    query(':enter', [
                        style({ transform: 'translateX(-130%)' }),
                        animate('1s ease-in-out',
                            style({ transform: 'translateX(0%)' }))
                    ], { optional: true }),
                    query(':leave', [
                        style({ transform:   'translateX(0%)'}),
                        animate('1s ease-in-out',
                            style({ transform: 'translateX(140%)' }))
                    ], { optional: true }),
                ])
            ]),
            transition('home <=> tradeidea,tradeidea <=> tradenow', [
                query(':enter, :leave',
                    style({ position: 'absolute', width: '100%'}),
                    { optional: true }),
                group([
                    query(':enter', [
                        style({ transform:   'translateX(130%)'}),
                        animate('1s ease-in-out',
                            style({ transform: 'translateX(0)' }))
                    ], { optional: true }),
                    query(':leave', [
                        style({ transform:   'translateX(0%)'}),
                        animate('1s ease-in-out',
                            style({ transform: 'translateX(-130%)' }))
                    ], { optional: true }),
                ])
            ]),
        ]),
        slideInAnimation,
        bounceOutUpOnLeaveAnimation(),
    ]
})

// MAIN COMPONENT CLASS FOR THIS COMPONENT THAT WLL BE IMPORTED TO ANOTHER COMPONENT
export class AppComponent {
    dialogRef?: DialogRef;

    public config = {
        backdrop: true,
        ignoreBackdropClick: true
    };
    // URL OF THE NODE SERVER.
    public apiURL = environment.apiURL;
	public mainLKURL = environment.mainLKURL;

    public flipState = 'default';
    public fadeState = 'default';
    public slideState = 'default';

    // ACCESSING HTML ELEMENTS.
    @ViewChild('mainArea') mainArea: ElementRef; // MAIN HTML CONTAINER OF THE WEB PAGE.

    // THESE VARIABLES HOLD THE CSS VALUES FOR THE PAGE ELEMENTS.
    public svNormalFontSize = '1rem'; // FONT SIZE.
    public appContainerCss: any = {}; // APP CONTAINER CSS.
    public tabpanelHeight = 'auto'; // TABS HEIGHT ON FIND ROOM PAGE.

    // VARIABLES CONTROLLING THE HEADER ELEMENTS HEIGHTS
    public appHeaderHeight = '8%';

    // VARIABLE TO STORE CSS FOR HEADER/TITLE SECTION.
    public appHeaderCss: any;

    public alias: any = '';

    // ARRAY TO STORE VALUES FROM SITE VARIABLE
    public svValues: any[] = [];

    public userAvatar = './assets/images/user.svg';

    public reverse = false;
    private toastr: ToastrService // LOADING TOAST SERVICE TO DISPLAY TOOL TIPS.

    public isUserOnMobile:boolean = false // THIS VARIABLE WILL BE USED TO CHECK IF THE USER IS USING APP ON MOBILE OR BROWSER

    // CLASS CONSTRUCTOR, THIS WILL BE FIRST FUNCTION TO BE EXECUTED WHEN THIS CLASS LOADS.
    // HERE WE WILL TELL ANGULAR TO INJECT A DEPENDENCY BY SPECIFYING A CONSTRUCTOR
    // PARAMETER WITH THE DEPENDENCY TYPE.
    constructor
    (
        private injector:Injector,
        private contexts: ChildrenOutletContexts,
        public socket: Socket, // LOADING SOCKET TO SEND MESSAGES TO SERVER OR IN ROOM.
        public dbService: DbService, // LOADING DATABASE SERVICE TO INTERACT WITH DATABASE.
        public helper: HelperService, // LOADING HELPER SERVICE TO USE APP GLOBAL VARIABLES.
        public login: LoginService, // LOADING LOGIN SERVICE.
        private router: Router, // LOADING ROUTER SO WE CAN ACCESS ROUTE/URL PROPERTIES.
        private activatedRoute: ActivatedRoute,
        // INJECTING DialogService AS A DEPENDENCY TO DISPLAY KENDO DIALOGS.
        public dialogService: DialogService,
        // private modalService: BsModalService,
        private kendoDialogService: KendoDialogService,
	    private cdref: ChangeDetectorRef,
        // ADDING ZOOM SERVICE
        private zoomService: ZoomService,
        private cookieService:CookieService,
		private iframeService: IframeService
    ) {
        // SETTING A DEFAULT CLASS TO TOOLTIP. WE WILL INCLUDE DEVICE TYPE (MOBILE, TABLE, DESKTOP)
        // AS A CLASS NAME SO WE CAN ASSIGN DIFFERENT PROPERTIES TO TOOLTIP ON EACH DEVICE.
        // this.toastr.toastrConfig.toastClass = this.helper.layoutType + ' toast ngx-toastr';
        console.log ('this.helper.deviceType ', this.helper.devicetype_id);
        // this.router.events.pipe(filter(e => e instanceof NavigationEnd),
        // pairwise()).subscribe((e) => {
        //     console.log(event.urlAfterRedirects, '>>>');
        // });
    }

    // FUNCTION CALLED WHEN THE COMPONENT INITIALIZES. RUNS BEFORE THE VIEW IS RENDERED.
    async ngOnInit ()
	{
		this.adjustZoom();
		window.addEventListener('resize', () => this.adjustZoom());
		
		this.iframeService.loadIframeUrl();
		
		// SAVE THE SESSION ERROR LOG INTO DATABASE ON EACH 1 MINUTE INTERVAL.
        // setInterval (() => {
        //     //this.helper.storeSessionErrorInDatabase ();
        // }, 60000);

        //console.log('socket', this.socket);

        // WE WILL WAIT FOR DEVICE SAVED OBSERVER TO RECEIVE ALL DEVICE INFORMATION,
        // IF DEVICE DETAILS ARE AVAILABLE, TRY TO LOGIN THE USER AS WELL
        this.helper.deviceSavedObs.subscribe (async result =>
		{
			// IF DEVICE RESULT LIKE OS, BROWSER NAME, SAVED SUCCESSFULLY THEN WE TRY TO LOGIN.
			if (result)
			{
				//await this.login.attemptToLogin ();
			}
		});
        
		await this.login.attemptToLogin ();

        // LET'S CHECK IF THIS BROWSER IS IN OUR WHITELIST OR NOT.
        if (!this.helper.checkBrowserSupport ()) {
            // BROWSER IS NOT SUPPORTED.
            this.helper.browserSupport = false;
        }

        // SUBSCRIBE TO AVATAR CHANGE AT TOP BAR.
        this.helper.processSiteAvatar.subscribe ((val) => {
            console.log('processSiteAvatar');
            // IF THE USER IS LOGGED IN.
            if (this.helper.isUserLoggedIn) {
                if (this.login.user.urlProfileimg != '') {
                    const imageurl = this.apiURL + '/' + this.login.user.urlProfileimg + '?unique=' + Date.now ();
                    this.helper.checkImage (imageurl).then ((imageExist) => {
                        if (imageExist) {
                            this.userAvatar = imageurl;
                        } else {
                            this.userAvatar = './assets/images/user-green.svg';
                        }
                    });
                } else {
                    this.userAvatar = './assets/images/user-green.svg';
                }
            } else {
                this.userAvatar = './assets/images/user.svg';
            }
            this.cdref.detectChanges();
        });

        // SET THE HEIGHT OF THE APP CONTAINER. THIS WILL BE EQUAL TO THE COMPLETE VIRTUAL HEIGHT AVAILABLE FOR THE
        // DOCUMENT THAT IS LOADING.
        //this.setAppHeight ();

        // THIS WILL BE CALLED WHEN USER CHANGES THEIR SCREEN NAME. THIS WILL DEAL WITH THE RESULT
        // OF CHANGE SCREEN NAME ACTIVITY.
        this.helper.screenNameErrorObs.subscribe ((val) => {
            this.toastr = this.injector.get(ToastrService);
            // IF THERE WAS AN ERROR IN CHANGING SCREEN NAME THEN WE WILL DISPLAY THE ERROR. OTHERWISE
            // WE WILL DISPLAY THE SUCCESS MESSAGE.
            if (this.helper.joinRoomscreenNameError) {
                // DISPLAY THE ERROR IN TOOLTIP WITH A CLOSE BUTTON.
                this.toastr.error (this.helper.screenMessage, '', {closeButton: true});
            } else {
                // DISPLAY THE SUCCESS MESSAGE IN TOOLTIP WITH A CLOSE BUTTON.
                this.toastr.success (this.helper.screenMessage, '', {closeButton: true});
            }
        });

        // WHEN SITE VARIABLES ARE AVAILABLE, SET THE SITE FONT SIZE AND SITE SLOGAN ON THE HEADER
        if (this.helper.sitevariables) {
            // LET'S APPLY CSS ON FIND ROOM CONTAINER.
            this.svNormalFontSize = this.helper.sitevariables ['SV-CSS-FONT-SIZE-NORMAL'].value + this.helper.getValueType (this.helper.sitevariables ['SV-CSS-FONT-SIZE-NORMAL'].valuetype);

            // LOOK INTO THE SITEVARIABLE SV-MAIN-HEADER IF THE SLOGAN NEEDS TO BE DYNAMIC OR PULLED FROM SITEVARIABLE.
            if (this.helper.sitevariables ['SV-MAIN-HEADER-MODE'].value == 'SV') {
                // PULLING SITE SLOGAN FROM SITE VARIABLE.
                this.svValues ['SV-MAIN-HEADER'] = this.helper.sitevariables ['SV-MAIN-HEADER'].value;
            } else {
                // DISPLAYING EMPTY SPACE FOR SITE SLOGAN.
                this.svValues ['SV-MAIN-HEADER'] = '';
            }

            // SETTING THE FONT SIZE OF HEADER.
            this.svValues ['SV-CSS-FONT-SIZE-MAIN-HEADER'] = this.helper.sitevariables ['SV-CSS-FONT-SIZE-MAIN-HEADER'].value + this.helper.getValueType (this.helper.sitevariables ['SV-CSS-FONT-SIZE-MAIN-HEADER'].valuetype);

            // LET'S GET INFORMATION ABOUT THE CLIENT DEVICE TYPE THAT IS LOADING THIS APP.
            // WE WILL GET THE BROWSER NAME, OS, AND IF THIS IS A MOBILE, DESKTOP OR TABLET.
            console.log ('Device type ID: ' + this.helper.devicetype_id);
        }

        // ADDING DIFFERENT CLASS NAME TO BODY DEPENDING ON THE DIFFERENT ROUTE.
        // FOR EXAMPLE, THE BODY ATTRIBUTE OF ROOM PAGE WILL HAVE ROOM CLASS, WHILE ON
        // FIND ROOM PAGE IT WILL BE FINDROOM.
        this.router.events.subscribe ((routeinfo: any) => {
            if (routeinfo instanceof NavigationEnd) {
                // SUBSCRIBING TO NAVIGATIONEND WHICH IS ABOUT TO HAPPEN
                console.log ('Navigation end happened');
                // IF WE SUCCESSFULLY GOT THE URL?
                if (routeinfo.url) {
                    const url = routeinfo.url; // STORING THE URL INTO LOCAL VARIABLE.
                    const urlArray = url.split ('/'); // SPLITTING THE URL ON THE BASIS OF SLASH.
                    let route = urlArray[1]; // GETTING THE VALUE AT INDEX 1.

                    // IF THE VALUE AT INDEX 1 MATCHES THE WORD PRV OR PUB THEN WE ARE IN THE ROOM.
                    if (route == 'prv' || route == 'pub') {
                        route = 'room'; // SETTING ROUTE AS ROOM.
                        this.helper.isRoomPage = true; // THIS IS ROOM PAGE.
                    } else {
                        this.helper.isRoomPage = false; // THIS IS NOT THE ROOM PAGE.
                    }

                    if (route == 'privacy' || route == 'tradeidea' || route == 'tradenow' || route == 'contact')
                    {
	                    let body = document.body;
	                    body.classList.add("fixedbody");
                    } else {
	                    let body = document.body;
	                    body.classList.remove("fixedbody");
                    }

                    // ASSIGNING DEVICE TYPE
                    let deviceType = this.helper.deviceType;

                    // LOCAL VARIABLE TO STORE BROWSER NAME. WE WILL ALSO INCLUDE THAT IN CLASS LIST OF
                    // HTML BODY TAG.
                    let browserName;

                    // IF BROWSER NAME HAS ANY SPACE THEN WE WILL GET RID OF THE SPACE.
                    // BECAUSE A CLASS SHOULD BE ONE WORD.
                    if (this.helper.browserName.indexOf (' ') >= 0) {
                        const browserArray = this.helper.browserName.split (' '); // SPLITTING BROWSER WORD BY SPACE.
                        const newArray = []; // DEFINING A NEW ARRAY.

                        // LOOPING THROUGH THE WORDS OF BROWSER NAME.
                        for (let i = 0; i < browserArray.length; i++) {
                            // WE WILL REMOVE ANY WORD THAT IS MATCHING DESKTOP, TABLET OR MOBILE.
                            if (browserArray[i] != 'desktop' && browserArray[i] != 'tablet' && browserArray[i] != 'mobile') {
                                // PUSHING THE WORDS IN BROWSER NAME INTO AN ARRAY.
                                newArray.push (browserArray[i]);
                            }
                        }

                        // NOW WE WILL JOIN THE ARRAY. SO WE WILL HAVE ANY WORD LIKE DESKTOP, TABLET OR MOBILE
                        // REMOVED FROM THE BROWSER NAME. ALSO THERE WON'T BE ANY SPACES IN BROWSER NAME NOW.
                        browserName = newArray.join ();
                    } else {
                        // JUST USING THE BROWSER NAME AS IT IS.
                        browserName = this.helper.browserName;
                    }

                    // THIS CHANGE IS FOR IPAD PRO OR BIG SCREEN DEVICES ONLY
                    // IF USER'S DEVICE WIDTH IS GREATER THAN OR EQUAL TO 1024 PIXEL ASSIGN DESKTOP CLASS IN BODY TAG
                    if (document.documentElement.clientWidth >= 1024) {
                        deviceType = 'desktop'; // ASSIGNING DESKTOP CLASS.
                    }

                    // JOINING ALL THE CLASSES TOGETHER IN ONE VARIABLE.
                    route = deviceType + ' ' + browserName + ' ' + route;

                    // GETTING THE BODY TAG OF HTML.
                    const body = document.getElementsByTagName ('body')[0];
                    body.className = route; // ASSIGNING THE NEW CLASSES TO THE BODY TAG.
                }
            }
        });

        // THIS WILL AVOID USING ON MOBILE APPS
        if(window.innerWidth < 1366)
		{
			this.isUserOnMobile = true;
		}
		else
		{
			this.isUserOnMobile = false;
		}
    }

	// BY THE HELP OF THIS SERVICE IF THE SCREEN RESOLUTION IS LESS THEN THE REQUIRED RESOLUTION SO WE WILL ZOOM OUT TO GET THE WHOLE CONTENT
	adjustZoom() {
		const screenResolutionPercentage = this.zoomService.getScreenResolutionPercentage();

		if (screenResolutionPercentage > 100) {
			const zoomFactor = 1 - ((screenResolutionPercentage - 100) * 0.01);
			this.zoomService.setZoom('main-header-section', zoomFactor);
		}
		else if (screenResolutionPercentage < 100) {
			const zoomFactor = 1 + ((screenResolutionPercentage - 100) * 0.01);
			this.zoomService.setZoom('main-header-section', zoomFactor);
		} else {
			this.zoomService.setZoom('main-header-section', 1);
		}
	}
    ngDoCheck() {
        if(this.helper.isUserLoggedIn)
        {
            const currentUserId = this.cookieService.get('lk_user_id');
            if (!currentUserId) {
                this.helper.isUserLoggedIn = false;
                this.redirectHome();
            }
        }
    }

    // GO TO LOGIN
    public goToLogin () {
        this.router.navigate (['/login']);
    }

	// ACCEPT ONLY LETTERS AND NUMBERS
	public validateAlias (e) {
		var regex = new RegExp("^[a-zA-Z0-9 ]+$");
		var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
		if (regex.test(str)) {
			return true;
		}

		e.preventDefault();
		return false;
	}

    public callNow (){
	    this.router.navigate (['/callnow/'+this.alias]);
	    this.alias = '';
    }

    // THIS FUNCTION IS CALLED AFTER COMPONENT VIEW INITIALIZED
    ngAfterViewInit () {
        // WE WILL NOW MAKE SOME ADDITIONAL API CALLS TO RETRIEVE FORBIDDEN WORDS AND SOME OTHER STATIC DATA.
        this.helper.getForbiddenWords ();
        const url = window.location.href; // STORING URL OF CURRENT PAGE.

        // IF IT'S THE ROOM PAGE THEN WE WON'T DO ANYTHING. OTHERWISE WE WILL HIDE THE SITE LOADER.
        // SITE LOADER WAS STARTED WHEN THE USER OPENED THE LINK OF WEBSITE.
        // IF ITS NOT THE ROOM PAGE THEN WE CAN HIDE THE LOADER. FOR ROOM PAGE WE STILL NEED TO
        // DO SOME OTHER CALCULATION BEFORE WE CAN HIDE THE LOADER.
        if (url.match ('prv') || url.match ('pub')) {
            console.log ('code149, its the room');
        } else {
            this.helper.hideSiteLoader (); // HIDING THE SITE LOADER.
        }
    }

    // LISTENING TO ANY RESIZE EVENT.
    @HostListener ('window:resize', ['$event'])

    // THIS WILL BE CALLED WHENEVER RESIZE EVENT IS TRIGGERED.
    onResize () {
        // UPDATE DOM WITH CALCULATED RESPONSIVE HEIGHT OF THE APP CONTAINER.
        this.setAppHeight ();

        if (this.helper.deviceType == 'mobile') {
            // this.appContainerCss = { height: window.innerHeight + "px" };
            // document.querySelector("body").style.height = window.innerHeight + "px";
        }

        const screenWidth = window.innerWidth;

        if (screenWidth > 1199) {
            // tslint:disable-next-line:no-unused-expression
           this.dialogRef !== undefined ? this.dialogRef.close() : '';
        }

        // THIS WILL AVOID USING ON MOBILE APPS
		if(window.innerWidth < 1366)
		{
			this.isUserOnMobile = true;
			return ;
		}
		else
		{
			this.isUserOnMobile = false;
		}
    }

    // FUNCTION TO SET MAIN ELEMENT HEIGHT.
    private setAppHeight () {
        if (this.helper.deviceType == 'mobile')
		{
            this.appContainerCss = { height: window.innerHeight + "px" };
        } else
		{
            // SETTING CSS OF MAIN ELEMENT.
            this.appContainerCss = {height: document.documentElement.clientHeight + 'px'};
        }
    }

    // THIS FUNCTION IS CALLED FOR TO CONFIRM (POPUP WILL APPEAR) USER TO LEAVING OR STAY IN THE ROOM
    public redirectHome () {
        // CHECK IS THIS ROOM PAGE.
        if (this.helper.isRoomPage) {
            const message = this.helper.sitemessages['MSG-ROOM-USER-LEAVING'];
            console.log ('message', message);
            // SHOW KENDO DIALOG TO CONFIRM USER WAHTS TO STAY IN ROOM OR LEAVE
            this.dialogService.showDialog ('contentPopupLeavingRoom', 'You are leaving!', '', 'Do you want to leave the room?');
        } else {
            // NAVIGATE USER TO HOME PAGE
            this.router.navigate (['/']);
        }
    }

    public openModalWithComponent() {
        this.dialogRef = this.kendoDialogService.open ({
            // Show component
            content: ModalComponent,
        });
    }

    public logOut()
    {
	    this.login.logoutUser ().then (val =>
	    {
	    	this.redirectHome();
		    this.cdref.detectChanges(); // DETECTING ANY CHANGES IN ANGULAR VIEW.

	    });
    }
}
