/*
         FILENAME: lkphone_mobile/src/app/components/utils/kipscredits/counter.component.tsx
           AUTHOR: ICI/QZ
          SUMMARY: COMPONENT TO SHOW A COUNTER FORM ONE VALUE TO ANOTHER. VALUE MUST BE POSITIVE INTEGER.
          PURPOSE: A PORTABLE COMPONENT THAT CAN BE HOOKED INTO ANY OTHER COMPONENT TO SHOW A
          		   VALUE COUNTDOWN OR COUNT-UP.
  IMPORTING FILES: TBD
SUBSCRIBING FILES: TBD
 LAST COMMIT DATE: October 08, 2023
*/
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

// DEFINE A COMPONENT, ITS HTML SELECTOR AND ITS HTML TEMPLATE. HTML TEMPLATE WILL BE EMBEDDED INTO SELECTOR.
@Component
({
	selector: 'app-counter',
	template: `
		<div class="item" [style]="itemStyle">
			<div class="icon">{{pointType}}</div>
			<div class="text">
				<span>{{ (currentCount > 0 ? currentCount : 0) }}</span>
			</div>
		</div>
	`,
	styleUrls: ['./pointscounter.component.scss']
})

// EXPORT THE COUNTER COMPONENT THAT WILL SHOW THE COUNTER.
export class PointsCounterComponent implements OnChanges
{
	@Input() newPoints: number; // THE NEW POINTS, THAT IS THE FINAL VALUE OF COUNTER.
	@Input() pointType: string; // IS IT KIPS OR PHONE CREDITS. POSSIBLE VALUES ARE "C" OR "K".
	initialPoints: number = 0; // THE INITIAL POINTS, THAT IS THE INITIAL VALUE OF COUNTER.
	currentCount: number = 0; // THE CURRENT POINTS, THAT IS THE CURRENT VALUE AT ANY GIVEN MOMENT, OF THE COUNTER.
	itemStyle: any = {}; // STYLES FOR THE COUNTER.

	// WHENEVER THERE IS A CHANGE IN THE INPUT VALUE, THIS FUNCTION WILL BE CALLED.
	// INPUT VALUE IS THE NEW POINTS, WHICH WILL BE SENT FROM THE PARENT COMPONENT.
	ngOnChanges (changes: SimpleChanges)
	{
		// CURRENT VALUE WILL BE THE UPDATED/CHANGED VALUE OF THE POINTS. THAT IS, THE EXACT VALUE THAT IS SENT FROM
		// THE PARENT COMPONENT. COUNTER WILL END ON THIS VALUE.
		let newValue = changes.newPoints.currentValue || this.newPoints;
		let previousValue = changes.newPoints.previousValue || this.newPoints; // START POINT OF COUNTER.
		console.log ("changes_called",changes, newValue, previousValue);

		// IF THE POINTS ARE CHANGED THEN UPDATE THE COUNTER.
		if (changes.newPoints && newValue !== previousValue)
		{
			this.animateCounter (newValue, previousValue);
		}
		else // THIS IS LIKELY TO BE THE FIRST TIME THE PAE IS LOADED. JUST SHOW THE POINTS.
		{
			this.currentCount = this.newPoints;
		}
	}

	// SHOW THE COUNTER. IT WILL START FROM THE PREVIOUS POINTS AND END AT THE NEW POINTS.
	private animateCounter (newValue, previousValue)
	{
		// NOW INCREMENT OR DECREMENT NEW POINTS VALUE BY THE INTERVAL OF 1 DIGIT UPTO THE CURRENT POINTS VALUE.
		let interval = setInterval(() =>
		{
			console.log ("points_counter_interval");

			// IF THE UPDATED POINTS IS GREATER THAN THE CURRENT POINTS THEN INCREMENT THE POINTS BY 1.
			if (newValue > previousValue)
			{
				previousValue++;

				// SHOW GREEN BACKGROUND OF THE COUNTER.
				this.itemStyle = {background: "#19D0C2"};
			}

			// IF THE UPDATED POINTS IS LESS THAN THE CURRENT POINTS THEN DECREMENT THE POINTS BY 1.
			else if (newValue < previousValue)
			{
				previousValue--;

				// SHOW GREEN BACKGROUND OF THE COUNTER.
				this.itemStyle = {background: "#EB5757"};
			}

			// IF THE UPDATED POINTS IS EQUAL TO THE CURRENT POINTS THEN STOP THE INTERVAL.
			else
			{
				clearInterval (interval);

				// CLEAR THE BACKGROUND OF THE COUNTER.
				this.itemStyle = {};
			}
			this.currentCount = previousValue; // SET THE POINTS VALUE.
		}, 150);
	}
}
