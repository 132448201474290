import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {Component} from '@angular/core';

// IMPORTING THE LK HELPER SERVICE TO GET ACCESS TO THE GLOBAL DATA AND SERVICES SO THAT WE CAN USE THE GLOBAL DATA AND FUNCTIONS.
import {HelperService} from "./../../../services/helper.service";

import {Toast, ToastrService, ToastPackage} from 'ngx-toastr';
import {Socket} from "ngx-socket-io"

@Component ({
  selector: '[question-toast-component]',
  styleUrls: [`./question-toast.component.scss`],
  templateUrl: `./question-toast.component.html`,
  animations: [
    trigger ('flyInOut', [
      state ('inactive', style ({
        display: 'none',
        opacity: 0
      })),
      transition ('inactive => active', animate ('400ms ease-out', keyframes ([
        style ({
          opacity: 0,
        }),
        style ({
          opacity: 1,
        })
      ]))),
      transition ('active => removed', animate ('400ms ease-out', keyframes ([
        style ({
          opacity: 1,
        }),
        style ({
          transform: 'translate3d(10%, 0, 0) skewX(10deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class QuestionToastComponent extends Toast
{
  // used for demo purposes
  
  public username: string;
  public override message: any = {};
  
  // constructor is only necessary when not using AoT
  constructor (
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage,
    public socket: Socket,
    public helper: HelperService,
  )
  {
    super (toastrService, toastPackage);
    console.log (this, toastrService, toastPackage);
    this.username = this.helper.userscreenname;
    this.message = toastPackage.message;
  }
  answerQuestion (answer, name, message)
  {
    this.socket.emit ('answerQuestion', {
      answer: answer,
      roomName: message.roomName,
      name: name,
      question: message.question,
      socketId: message.socketId,
      source:"question-toast.component.ts > answerQuestion"
    })
    this.toastrService.clear ();
  }
  
  ignoreQuestion () {
    this.toastrService.clear ();
  }
  
  shareQuestion (data)
  {
    this.socket.emit ('shareQuestion', data);
    this.toastrService.clear ();
  }
  
}
