/*
         FILENAME: lkphone_mobile/src/app/components/utils/kipscredits/kipscredits.component.ts
           AUTHOR: ICI/QZ
          SUMMARY: DISPLAY THE CREDITS AND KIPS OF THE USER IN THE HEADER SECTION. WHENEVER THERE IS A CHANGE IN
                   KIPS OR CREDITS THEN SHOW THE COUNTER.
          PURPOSE: CREDITS FROM INVISIPHONE AND KIPS FROM LKPHONE WILL BE DISPLAYED ON THE HEADER.
  IMPORTING FILES: TBD
SUBSCRIBING FILES: TBD
 LAST COMMIT DATE: October 09, 2023
*/
import { ChangeDetectorRef, Component, Injector } from "@angular/core";
import { Firestore, collectionData, collection } from '@angular/fire/firestore';
import { Observable } from "rxjs";
import { HelperService } from "../../../services/helper.service";
import { Router } from "@angular/router";
import { LoginService } from "../../../services/login.service";

// DEFINE A COMPONENT, ITS HTML SELECTOR AND ITS HTML TEMPLATE. HTML TEMPLATE WILL BE EMBEDDED INTO SELECTOR
@Component({
  selector: 'app-kipscredits',
  templateUrl: './kipscredits.component.html',
  styleUrls: ['./kipscredits.component.scss']
})

// EXPORT THE COMPONENT TO SHOW THE CREDITS AND KIPS OF THE USER IN THE HEADER SECTION.
export class KipscreditsComponent
{
	// CLASS CONSTRUCTOR, THIS WILL BE FIRST FUNCTION TO BE EXECUTED WHEN THIS CLASS LOADS.
	// HERE WE WILL TELL ANGULAR TO INJECT A DEPENDENCY BY SPECIFYING A CONSTRUCTOR
	// PARAMETER WITH THE DEPENDENCY TYPE.
	constructor
	(
		public helper: HelperService, // LOADING HELPER SERVICE TO USE APP GLOBAL VARIABLES.
		private firestore: Firestore, // LOADING FIRESTORE SERVICE TO PERFORM CRUD OPERATIONS ON FIRESTORE DATABASE.
		private loginService: LoginService // LOADING LOGIN SERVICE TO UPDATE INVISIPHONE AND LKPHONE DATA.
	)
	{}

	// ANGULAR LIFE CYCLE HOOK THAT WILL BE CALLED WHEN THIS COMPONENT IS INITIALIZED.
	ngOnInit()
	{
		console.log ("this.helper.user_id",this.helper.user_id);

		// SUBSCRIBE TO CHANGE IN FIRESTORE DOCUMENT IN USER COLLECTION. COMPLETE PATH IS user/{uid}/phone.
		collectionData (collection (this.firestore, `user/${this.helper.user_id}/phone`)).subscribe ((phoneList: any) =>
		{
			this.loginService.updateInvisiPhoneVariables (phoneList);
		});

		// SUBSCRIBE TO CHANGE IN LKPHONE DB IN FIRESTORE. COMPLETE PATH IS user/{uid}/lkphone.
		collectionData (collection (this.firestore, `user/${this.helper.user_id}/lkphone`)).subscribe ((lkphones: any) =>
		{
			this.loginService.updateLKPhoneVariables (lkphones);
		});
	}
}
