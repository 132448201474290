// custom-reuse-strategy.ts
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class CustomReuseStrategy implements RouteReuseStrategy
{
	private storedRoutes = new Map<string, DetachedRouteHandle>();
	
	shouldDetach(route: ActivatedRouteSnapshot): boolean
	{
		return route.routeConfig?.data?.reuse;
	}
	
	store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void
	{
		if (handle && route.routeConfig?.data?.reuse)
		{
			this.storedRoutes.set(this.getKey(route), handle);
		}
	}
	
	shouldAttach(route: ActivatedRouteSnapshot): boolean
	{
		return !!this.storedRoutes.get(this.getKey(route));
	}
	
	retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null
	{
		return this.storedRoutes.get(this.getKey(route)) || null;
	}
	
	shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean
	{
		return future.routeConfig === curr.routeConfig;
	}
	
	private getKey(route: ActivatedRouteSnapshot): string
	{
		// You can customize the key based on your requirements
		return route.routeConfig?.path || '';
	}
}
