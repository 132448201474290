import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {UtilsModule} from '../utils/utils.module';

// KENDO
import {DropDownListModule, DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {DatePickerModule, TimePickerModule} from '@progress/kendo-angular-dateinputs';
import {LayoutModule} from '@progress/kendo-angular-layout';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {LabelModule} from '@progress/kendo-angular-label';
import {GridModule} from '@progress/kendo-angular-grid';
import {PagerModule} from '@progress/kendo-angular-pager';
import {LoaderModule} from '@progress/kendo-angular-indicators';

import {ModalComponent} from './modal/modal.component';
import {LoadingComponent} from './loading/loading.component';
import {DeleteDialogComponent} from './deletedialog/deletedialog.component';
import { SearchComponent } from './search/search.component';
import { DashboardoverviewComponent } from './overview/dashboardoverview.component';

@NgModule (
    {
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        DropDownListModule,
        DropDownsModule,
        LayoutModule,
        DatePickerModule,
        TimePickerModule,
        DialogModule,
        UtilsModule,
        ButtonsModule,
        InputsModule,
        LabelModule,
        PagerModule,
        GridModule,
        LoaderModule,
    ],
    declarations: [
        ModalComponent,
        SearchComponent,
        LoadingComponent,
        DeleteDialogComponent,
        DashboardoverviewComponent
    ],
    exports: [
        ModalComponent,
        SearchComponent,
        LoadingComponent,
        DeleteDialogComponent,
        DashboardoverviewComponent
    ],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class SharedModule
{
}
