/*
            FILENAME: lk_client/src/app/services/dialog.service.ts
              AUTHOR: Zulaikha Moidin, Qasim Zubair, Anindya Dutta
             SUMMARY: THIS FILE HOLDS THE DATA AND FUNCTIONS NEEDED FOR OPENING AND CLOSING A POPUP
             PURPOSE: DISPLAY AND HIDE A POPUP
     IMPORTING FILES: helper.service.ts
   SUBSCRIBING FILES: room.component.ts
    LAST COMMIT DATE: August 19 2020
*/

// IMPORTING THE ANGULAR MODULES FOR PERFORMING BASIC ANGULAR FRAMEWORK OPERATIONS.
import {ElementRef, Injectable, ViewChild} from '@angular/core';

// IMPORTING THE LK HELPER SERVICE TO GET ACCESS TO THE GLOBAL DATA AND SERVICES
// SO THAT WE CAN USE THE GLOBAL DATA AND FUNCTIONS LIKE MESSAGING AND SITE VARIABLES.
import {HelperService} from "./helper.service";

// IMPORTING THE ANGULAR SUBJECT TO USE AS AN OBSERVABLE.
import {Subject} from 'rxjs';

// THE @Injectable  DECLARE THIS AS A SERVICE SO THAT WE CAN INJECT
// THIS SERVICE IN ANY OTHER COMPONENT OR SERVICE
@Injectable
({
  providedIn: 'root'
})

// DECLARING THE DialogService CLASS WITH EXPORT SO THAT WE CAN IMPORT
// THIS SERVICE INTO ANY OTHER COMPONENT OR SERVICE.
export class DialogService
{
 
	// OBSERVER THAT WILL BE TRIGGERED WHEN WE NEED TO OPEN THE MODAL.
	public dialogObserver: Subject<any> = new Subject<any> ();

	// OBSERVER THAT WILL BE TRIGGERED WHEN WE CLICK ON ANY BUTTON.
	public poupButtonObserver: Subject<any> = new Subject<any> ();
	
	// THIS HOLDS THE DISPLYING DIALOG ID VALUE.
	public popup_id: string = '';
	
	// THIS HOLDS THE FLAG TO DIPLAY BACKGROUND DARK OVERLAY.
	public displayOverlay: boolean = false;
	
	// THIS VARIABLE WILL HOLD THE TITLE VALUE OF THE DIALOG.
	public title: string;
	
	// THIS VARIABLE WILL HOLD THE TAGLINE VALUE OF THE DIALOG.
	public tagline: string;
	
	// THIS VARIABLE WILL HOLD THE CONTENT VALUE OF THE DIALOG.
	public content: string;
	 
  // CLASS CONSTRUCTOR, THIS WILL BE FIRST FUNCTION TO BE EXECUTED WHEN THIS CLASS LOADS.
  // HERE WE WILL TELL ANGULAR TO INJECT A DEPENDENCY BY SPECIFYING A CONSTRUCTOR PARAMETER WITH THE DEPENDENCY TYPE.
  constructor
  (
      // helper IS THE ALIAS OF THE HelperService, WE WILL USE THIS helper ALIAS TO
      // CALL THE FUNCTIONS OF HelperService CLASS
      private helper: HelperService,
  )
  {
  
  }
  
  // THIS FUNCTION WILL SHOW THE KENDO DIALOG.
  public showDialog (popup_id: any, title: any, tagline: any, content: any)
  {
		// SET THE DIALOG ID
		this.popup_id = popup_id;
		
		// SHOW THE OVERLAY
		this.displayOverlay = true;
		
		// SET THE DIALOG TITLE.
		this.title = title;
		
		// SET THE DIALOG TAGLINE.
		this.tagline = tagline;
		
		// SET THE DIALOG CONTENT.
   		this.content = content;
  }

  // THIS FUNCTION WILL HIDE ANY KENDO DIALOG.
  public hideDialog ()
  {
    // SET THE DIALOG ID
    this.popup_id = '';

    // SHOW THE OVERLAY
    this.displayOverlay = false;

    // SET THE DIALOG TITLE.
    this.title = '';
    
    // SET THE DIALOG CONTENT.
    this.content = '';
  }

  // THIS FUNCTION WILL HIDE ANY KENDO DIALOG.
  public hideDialogAndReload ()
  {
    // SET THE DIALOG ID
    this.popup_id = '';

    // SHOW THE OVERLAY
    this.displayOverlay = false;

    // SET THE DIALOG TITLE.
    this.title = '';
    
    // SET THE DIALOG CONTENT.
    this.content = '';

    window.location.reload ();
    
  }

  // THIS FUNCTION WILL HIDE ANY KENDO DIALOG.
  public hideDialogAndNavigate (navigatePage)
  {
    // SET THE DIALOG ID
    this.popup_id = '';

    // SHOW THE OVERLAY
    this.displayOverlay = false;

    // SET THE DIALOG TITLE.
    this.title = '';
    
    // SET THE DIALOG CONTENT.
    this.content = '';

    window.location.href = '/'+navigatePage;
    
  }
  
}
