/*
 FILENAME: lk_client/src/app/app-routing.module.ts
 AUTHOR: Zulaikha Moidin
 SUMMARY: THIS FILE CONTAINS ALL THE ROUTES THAT THE APPLICATION NEEDS. THIS FILE WILL BE MODIFIED TO CONTAIN ONLY THOSE ROUTES THAT ARE NEEDED
 TO RUN THE COMPONENT THAT WILL BE ASSIGNED FOR DEVELOPMENT IN THE DEVELOPMENT PHASE. IN PRODUCTION IT WILL CONTAIN ALL OF THE ROUTES.
 PURPOSE: TO CONFIGURE URL ROUTES FOR THE APPLICATION.
 IMPORTING FILES: NONE
 SUBSCRIBING FILES: NONE
 LAST COMMIT DATE: July 14, 2020
 */

// IMPORTING THE ANGULAR MODULES FOR POPUPS.
import {NgModule} from '@angular/core';
// IMPORTING THE ANGULAR MODULES FOR DOING OPERATIONS ON URL.
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';
// TBD
import {PageNotFoundComponent} from "./components/utils/page-not-found/page-not-found.component";
import { AuthGuard } from "./guards/auth.guard";

// DEFINES ALL THE MAJOR ROUTES POSSIBLE IN THE APPLICATION. THESE ARE THE URLS THAT WILL BE USED TO LOAD PARTS OF THE APPLICATION
const routes: Routes =
	[
		// {
		// 	path: 'findroom', // LOADS FINDROOM
		// 	loadChildren: () => import ('./components/findroom/findroom.module').then (m => m.FindroomModule)
		// },
		{
			path: 'privacy', // LOADS PRIVACY
			loadChildren: () => import ('./components/privacy/privacy.module').then (m => m.PrivacyModule)
		},
		{
			path: 'terms', // LOADS PRIVACY
			loadChildren: () => import ('./components/terms/terms.module').then (m => m.TermsModule)
		},
		{
			path: 'about', // LOADS PROTOTYPE PAGE
			loadChildren: () => import ('./components/about/about.module').then (m => m.AboutModule)
		},
        {
            path: 'contact', // LOADS CONTACT
            loadChildren: () => import ('./components/contact/contact.module').then (m => m.ContactModule)
        },
		{
			path: 'login', // LOADS CONTACT
			loadChildren: () => import ('./components/login/login.module').then (m => m.LoginModule)
		},
		{
			path: 'dashboard', // LOADS CONTACT
			data: {animation: 'dashboard'},
			loadChildren: () => import ('./components/dashboard/dashboard.module').then (m => m.DashboardModule)
		},
		{
			path: 'tradeidea', // LOADS CONTACT
			data: {animation: 'tradeidea'},
			loadChildren: () => import ('./components/tradeidea/tradeidea.module').then (m => m.TradeideaModule)
		},
		{
			path: 'tradenow', // LOADS CONTACT
			data: {animation: 'tradenow'},
			canActivate: [AuthGuard],
			loadChildren: () => import ('./components/tradenow/tradenow.module').then (m => m.TradeNowModule)
		},
		{
			path: 'tradehistory',
			data: {animation: 'tradehistory'},
			loadChildren: () => import ('./components/tradehistory/tradehistory.module').then (m => m.TradehistoryModule)
		},
		{
			path: 'avatarmanager',
			data: {animation: 'avatarmanager'},
			loadChildren: () => import ('./components/avatarmanager/avatarmanager.module').then (m => m.AvatarManagerModule)
		},
		{
			path: 'links',
			data: {animation: 'links'},
			loadChildren: () => import ('./components/links/links.module').then (m => m.LinksModule)
		},
		{
			path: 'yourturn',
			data: {animation: 'yourturn'},
			loadChildren: () => import ('./components/yourturn/yourturn.module').then (m => m.YourturnModule)
		},
		{
			path: 'account',
			data: {animation: 'account', reuse: true},
			loadChildren: () => import ('./components/account/account.module').then (m => m.AccountModule)
		},
		{
			path: '',
			data: {animation: 'home'},
			pathMatch: 'full',
			loadChildren: () => import ('./components/landing/landing.module').then (m => m.LandingModule)
		}, // DEFAULT ROUTE
		{
			path: '**', // OTHER CATCH ALL ROUTES
			component: PageNotFoundComponent
		},
	];

// AN ANGULAR DECORATOR THAT IDENTIFIES THE MODULE'S OWN COMPONENTS, DIRECTIVES, AND PIPES. SO THAT EXTERNAL COMPONENTS CAN USE THEM.
@NgModule
({
	imports:
	[
		RouterModule.forRoot (routes, {preloadingStrategy: PreloadAllModules})
	],
	exports:
	[
		RouterModule
	],
	declarations: []
})

// DECLARING THE AppRoutingModule CLASS WITH EXPORT SO THAT WE CAN IMPORT THIS SERVICE INTO ANY OTHER COMPONENT OR SERVICE.
export class AppRoutingModule
{
}
