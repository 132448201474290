import {Component} from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';

@Component({
	selector: 'app-links',
	templateUrl: './links.component.html',
	styleUrls: ['./links.component.scss'],
	animations: [
		// EXTENSION CARDS SHOW / HIDE ANIMATION
		trigger('enterExitLeft', [
			transition(':enter', [
				style({
					opacity: 0,
					transform: 'translateX(-500px)'
				}),
				animate(
					'400ms ease-in',
					style({
						opacity: 1,
						transform: 'translateX(0)'
					})
				),
			]),
			transition(':leave', [
				animate(
					'400ms ease-in',
					style({
						opacity: 0,
						transform: 'translateX(-500px)'
					})
				),
			]),
		]),

		// EXTENSION OVERVIEW SHOW / HIDE ANIMATION
		trigger('enterExitRight', [
			transition(':enter', [
				style({
					opacity: 0,
					transform: 'translateX(500px)'
				}),
				animate(
					'400ms ease-in',
					style({
						opacity: 1,
						transform: 'translateX(0)'
					})
				),
			]),
			transition(':leave', [
				animate(
					'400ms ease-in',
					style({
						opacity: 0,
						transform: 'translateX(500px)'
					})
				),
			]),
		]),
	]
})
export class LinksComponent
{
	// CONFIGURATION FOR ADD NEW LIST BUTTON AND MESSAGES
	filterConfig: any =
		{
			search: false,
			overviewSwitch: true,
			addNew: false
		};

	// DEMO DATA OF SOURCE LIST
	public currentSource: Array<any> = [
		{
			"iconurl": "video1.svg",
			"source": "Bar Replay"
		},
		{
			"iconurl": "video2.svg",
			"source": "Trading View"
		},
		{
			"iconurl": "video3.svg",
			"source": "Trading Platform"
		},
		{
			"iconurl": "video4.svg",
			"source": "Trading Ideas"
		},
		{
			"iconurl": "video4.svg",
			"source": "Trading Ideas"
		},
		{
			"iconurl": "video4.svg",
			"source": "Trading Ideas"
		},
		{
			"iconurl": "video4.svg",
			"source": "Trading Ideas"
		},
	];

	// DEMO DATA OF SOURCE LIST
	public topLinkSource: Array<any> = [
		{
			"iconurl": "youtube.svg",
			"title": "YouTube Channels"
		},
		{
			"iconurl": "youtube.svg",
			"title": "YouTube Playlists"
		},
		{
			"iconurl": "video5.svg",
			"title": "Screeners"
		},
		{
			"iconurl": "video5.svg",
			"title": "Screeners"
		},
		{
			"iconurl": "video5.svg",
			"title": "Screeners"
		},
		{
			"iconurl": "video4.svg",
			"title": "Screeners"
		}
	];

	// THIS VARIABLES IS USED FOR DATA PAGER
	public pageSize: number = 7;
	public skip: number = 0;
	public total: number;

	public currentView: any = 'dashboard';

	public visibleListItems: number = 4; // NUMBER OF VISIBLE ITEMS
	public currentIndex: number = 0; // CURRENT INDEX FOR PAGINATION
	public activeListIndex: number = 0; // INITIAL SELECTED INDEX
	public startListIndex: number = 0;
	public incrementIndexValue: number = 0;
	public endListIndex: number = this.visibleListItems;

	navigate(direction: 'left' | 'right'): void
	{
		console.log("directiondirectiondirection", direction)
		if (direction === 'left' && this.currentIndex > 0)
		{
			this.currentIndex -= 1;

			// ADJUST ACTIVELISTINDEX BASED ON CURRENTINDEX CHANGE
			this.activeListIndex = this.currentIndex;

			// CHECK THE CURRENT ACTIVE INDEX IS EQUAL TO VISIBLE CURRENT ITEM SHOW.
			if (this.incrementIndexValue > 0 || this.currentIndex >= this.visibleListItems)
			{
				this.endListIndex -= 1;
				this.startListIndex -= 1;
				this.incrementIndexValue -= 1;
			}
		}
		else if (direction === 'right' && this.endListIndex < this.topLinkSource.length)
		{
			this.currentIndex += 1;

			// ADJUST ACTIVELISTINDEX BASED ON CURRENTINDEX CHANGE
			this.activeListIndex = this.currentIndex;

			// CHECK THE CURRENT ACTIVE INDEX IS EQUAL TO VISIBLE CURRENT ITEM SHOW.
			if (this.currentIndex >= this.visibleListItems)
			{
				this.startListIndex += 1;
				this.endListIndex += 1;
				this.incrementIndexValue += 1;
			}
		}
	}

	// THE IS FUNCTION TO SELECTED ACTIVE LIST ITEM INDEX.
	selectedActiveIndexItem(index: number): void
	{
		// CALCULATE THE OVERALL INDEX IN THE SOURCE ARRAY
		const overallIndex = (this.currentIndex >= this.visibleListItems ? this.incrementIndexValue : 0) + index;
		this.activeListIndex = overallIndex;
		this.currentIndex = index
	}

	// CHECK THE LIST IS TOP THEN DISABLE TO NAVIGATE THE UP TO LIST ITEM.
	isListLeftDisabled(): boolean
	{
		return this.startListIndex === 0;
	}

	// CHECK THE LIS IS BOTTOM THEN DISABLE TO NAVIGATE THE DOWN TO LIST ITEM.
	isListRightDisabled(): boolean
	{
		return this.endListIndex >= this.topLinkSource.length;
	}

	// ON TOGGLE VIEW / OVERVIEW SWITCH CHANGE.
	onToggleOverview(switchState: boolean)
	{
		console.log('receiving', switchState);
		this.currentView = '';

		// IF VIEW EXTENSION IS ENABLED.
		setTimeout(() =>
		{
			this.currentView = switchState ? 'overview' : 'dashboard';
		}, 500);
	}
}
