<form spellcheck="false" class="managesearchform" (keydown.enter)="$event.preventDefault()" [formGroup]="filterForm"
		(ngSubmit)="false">
	<div class="filters-bar">
		<kendo-textbox
				*ngIf="config.search"
				class="input-text first-item"
				formControlName="keyword"
				placeholder="{{config.searchPlaceHolder}}"
				[style.width.px]="searchFieldWidth"
		></kendo-textbox>
		
		<div class="dropdownlist" *ngIf="config.broadcast">
			<kendo-dropdownlist [popupSettings]="{ popupClass: 'custom-dropdown' }" [style.width.px]="160" [data]="filterData" class="filter-dropdown"
					formControlName="type"
					defaultItem="{{config.filterTypeText}}"></kendo-dropdownlist>
		</div>
		
		<div class="flex-grow-1 dropdownlist" *ngIf="config.mode">
			<kendo-dropdownlist [popupSettings]="{ popupClass: 'custom-dropdown' }" [data]="modeTypeDataSource" class="filter-dropdown" formControlName="modeType"
					[defaultItem]="'Select Mode'"></kendo-dropdownlist>
		</div>
		
		<div class="dropdownlist" *ngIf="config.sourceType">
			<kendo-dropdownlist
					[style.width.px]="250"
					class="boxed-border"
					[data]="sourceTypeDataSource"
					[textField]="'text'"
					[valueField]="'value'"
					[valuePrimitive]="true"
					formControlName="sourceType"
					[defaultItem]="defaultItempublic">
			</kendo-dropdownlist>
		</div>
		
		<div class="radio-buttons-wrapper boxed-border" *ngIf="config.extension">
			<label class="item">
				<input type="radio" formControlName="extensiontype" #all value="ALL" kendoRadioButton/><span>All</span>
			</label>
			
			<label class="item">
				<input type="radio" formControlName="extensiontype" #link value="L" kendoRadioButton/><span>Link</span>
			</label>
			
			<label class="item">
				<input type="radio" formControlName="extensiontype" #contact value="C" kendoRadioButton/><span>Contact</span>
			</label>
			
			<label class="item">
				<input type="radio" formControlName="extensiontype" #entity value="E" kendoRadioButton/><span>Entity</span>
			</label>
			
			<label class="item">
				<input type="radio" formControlName="extensiontype" #default value="D" kendoRadioButton/><span>Default</span>
			</label>
		</div>
		
		<div class="dropdownlist" *ngIf="config.extension" [style.width.px]="160">
			<kendo-dropdownlist
					[style.width.px]="140"
					formControlName="status_dropdown"
					class="boxed-border"
					[data]="statusListItems"
					textField="text"
					valueField="value"
					[valuePrimitive]="true"
			>
			</kendo-dropdownlist>
		</div>
		
		<div class="radio-buttons-wrapper boxed-border" *ngIf="config.activestatus">
			<label class="item">
				<input type="radio" formControlName="activestatus" #all value="ALL" kendoRadioButton/><span>All</span>
			</label>
			
			<label class="item">
				<input type="radio" formControlName="activestatus" #active value="A" kendoRadioButton/><span>Active
			</span>
			</label>
			
			<label class="item">
				<input type="radio" formControlName="activestatus" #inactive value="I" kendoRadioButton/><span>
				Inactive</span>
			</label>
			
			<label class="item" *ngIf="config.entityPhonesMembers">
				<input type="radio" formControlName="activestatus" #delete value="R" kendoRadioButton/><span>Deleted
			</span>
			</label>
		</div>
		<div class="radio-buttons-wrapper boxed-border" *ngIf="config.invisiphone">
			<label class="item">
				<input type="radio" formControlName="favoritestatus" #all value="" kendoRadioButton/><span>
				All</span>
			</label>
			
			<label class="item">
				<input type="radio" formControlName="favoritestatus" #favorites value="F" kendoRadioButton/><span>
				Favorites</span>
			</label>
			
			<label class="item">
				<input type="radio" formControlName="favoritestatus" #calls value="C" kendoRadioButton/><span>
				Calls</span>
			</label>
			
			<label class="item">
				<input type="radio" formControlName="favoritestatus" #text value="S" kendoRadioButton/><span>
				Text</span>
			</label>
		</div>
		
		<div class="radio-buttons-wrapper boxed-border" *ngIf="config.invisiphoneadvance">
			
			<label class="item">
				<input type="radio" formControlName="statusAdvance" #all value="ALL" kendoRadioButton/><span>
				All</span>
			</label>
			<label class="item">
				<input type="radio" formControlName="statusAdvance" #basic value="BASIC" kendoRadioButton/><span>
				Basic</span>
			</label>
			<label class="item">
				<input type="radio" formControlName="statusAdvance" #advance value="ADVANCED" kendoRadioButton/><span>
				Advanced</span>
			</label>
		</div>
		
		<div class="radio-buttons-wrapper boxed-border" [ngStyle]="screenWidth <= 1366 ? {'margin-right': '0px'} : {'margin-right': '164px'}"
				*ngIf="config.broadcastStatus">
			
			<label class="item">
				<input type="radio" formControlName="status" #all value="ALL" kendoRadioButton/><span>
				All</span>
			</label>
			<label class="item">
				<input type="radio" formControlName="status" #inprogress value="I" kendoRadioButton/><span>
				In Progress</span>
			</label>
			<label class="item">
				<input type="radio" formControlName="status" #completed value="C" kendoRadioButton/><span>
				Completed</span>
			</label>
			<label class="item">
				<input type="radio" formControlName="status" #withdrawn value="W" kendoRadioButton/><span>
				Withdrawn</span>
			</label>
			<label class="item">
				<input type="radio" formControlName="status" #draft value="D" kendoRadioButton/><span>
				Draft</span>
			</label>
		</div>
		
		<div *ngIf="config.extenstionFlex" class="flex-grow-1"></div>
		<div *ngIf="config.extenstionFlex" class="flex-grow-1"></div>
		<div *ngIf="config.extenstionFlex" class="flex-grow-1"></div>
		
		<div class="infottxt-container" *ngIf="config.broadcastOverview" [ngStyle]="config.broadcastStatus &&
		screenWidth <= 1366 ?
		{'width': '252px'} : {'width': 'auto'}">
			<div class="infottxt bold">
				Communications are only activated when switched on.
			</div>
		</div>
		
		
		<div class="infottxt-container" *ngIf="!config.message && config.extension &&
		!filterForm.controls.overviewSwitch.value">
			<div class="infottxt bold">
				{{helper.sitemessageslkp['MSGLKP-ADMIN-EXTENSION-OVERVIEWSWITCH'].message}}
			</div>
		</div>
		
		<div class="infottxt-container" *ngIf="!config.message && config.linkOverview &&
		!filterForm.controls.overviewSwitch.value">
			<div class="infottxt bold">
				Links are only activated when switched on.
			</div>
		</div>
		
		<div class="infottxt-container" *ngIf="!config.message && config.entityOverview &&
		!filterForm.controls.overviewSwitch.value">
			<div class="infottxt bold">
				For information on entities, please see the Overview.
			</div>
		</div>
		
		<div class="infottxt-container {{config.messageClass}}" *ngIf="config.message && config.messageText !== ''">
			<div class="infottxt bold">
				{{config.messageText}}
			</div>
		</div>
		
		<div class="infottxt-container me-2" *ngIf="config.getSicOverview">
			<div class="infottxt bold">
				For information on {{config.name}}, switch to see the <span style="font-weight: 500;">Overview.</span>
			</div>
		</div>
		
		<div class="switch-container" *ngIf="config.overviewSwitch">
			<span class="me-2">View</span>
			<kendo-switch
					formControlName="overviewSwitch"
					(valueChange)="onSwitchChange($event)"
					class="attoswitch"
			></kendo-switch>
			<span class="ms-2">Overview</span>
		</div>
		<div class="add-more-button" *ngIf="config.addNew" kendoButton (click)="onAddNewClick()">
			<span class="add-new-text" *ngIf="config.addNewText !== ''">{{config.addNewText}} </span>
			<img src="/assets/images/plus.svg" />
		</div>
	</div>
</form>
