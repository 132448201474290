/*
		 FILENAME: lk_traders_client/src/app/components/tradenow/leg/leg.component.tsx
		   AUTHOR: ICI/QZ
		  SUMMARY: TBD
		  PURPOSE: TBD
  IMPORTING FILES: TBD
SUBSCRIBING FILES: TBD
 LAST COMMIT DATE: January 19, 2024
*/
import { Component, Input, OnInit } from '@angular/core';
import { chevronRightIcon, chevronDownIcon, SVGIcon } from "@progress/kendo-svg-icons";

// DEFINE A COMPONENT, ITS HTML SELECTOR AND ITS HTML TEMPLATE. HTML TEMPLATE WILL BE EMBEDDED INTO SELECTOR.
@Component
	({
		selector: 'app-leg',
		templateUrl: "./leg.component.html",
		styleUrls: ["./../tradenow.component.scss", "./leg.component.scss"],
	})

// EXPORT THE COUNTER COMPONENT THAT WILL SHOW THE COUNTER.
export class LegComponent implements OnInit
{
	@Input() tradeType: string = '';
	public panelList = [];
	// public isFlipped: boolean = false;
	public chevronRightIcon: SVGIcon = chevronRightIcon;
	public chevronDownIcon: SVGIcon = chevronDownIcon;
	public orderTypeList: Array<any> = [
		{ text: "Market", value: 'M' },
		{ text: "Limit", value: 'L' },
	];
	public executionTimeList: Array<any> = [
		{ text: "Now", value: 'N' },
		{ text: "Time of the day", value: 'T' },
		{ text: "Market Open", value: 'O' },
		{ text: "Market Close", value: 'C' },
		{ text: "Any", value: 'A' },
	];
	public expirationList: Array<any> = [
		{ text: "Day", value: 'D' },
		{ text: "Good Til Cancelled", value: 'GTC' },
	];
	public takeProfitChecked: boolean = false;
	public takeProfitValue: string = '';
	public emptyLegForm =
		{
			isExpanded: true,
			entryquantity: 1,
			entryordertype: "M",
			entryexecutiontime: 'O',
			entryexpiration: "GTC",
			entrylimitprice: null,
			exittakeprofittype: 'N',
			exittakeprofitvalue: null,
			exittrailingstoplosstype: 'N',
			exittrailingstoplossvalue: null,
			exitstoplosstype: 'N',
			exitstoplossvalue: null,
			exittimedexittype: 'N',
			exittimedexitvalue: null,
			exittimedmarketdaypart: "C",
			exitparadigm: "C"
		};

	ngOnInit ()
	{
		this.panelList.push({ ...this.emptyLegForm });
	}

	// FUNCTION REMOVE THE INDEX IN PANEL LIST
	public removePanelItem (index: number)
	{
		this.panelList.splice(index, 1);
	}

	// FUNCTION TO TOGGLE THE EXPANDED STATE OF A SPECIFIC PANEL
	public togglePanel (index: number)
	{
		//this.panelList[index].isExpanded = !this.panelList[index].isExpanded;
		this.panelList.forEach((panel, idx) =>
		{
			if (idx !== index && panel.isExpanded)
			{
				panel.isExpanded = false;
			}
			else if (idx === index && !panel.isExpanded)
			{
				panel.isExpanded = true;
			}
			else if (idx === index && panel.isExpanded)
			{
				panel.isExpanded = false;
			}
		});
	}

	// FUNCTION TO ADD A NEW PANEL WITH DEFAULT STATE (NOT EXPANDED)
	public addPanel (): void
	{
		for (let index = 0; index < this.panelList.length; index++)
		{
			const panel = this.panelList[index];
			panel.isExpanded = false;
		}
		this.panelList.push({ ...this.emptyLegForm });
	}

	// FUNCTION TO DECREASE THE QUANTITY ON CLICK BUTTON
	public decreaseQuantity (panel)
	{
		if (panel.entryquantity > 1)
		{
			panel.entryquantity--;
		}
	}

	// FUNCTION TO INCREASE THE QUANTITY ON CLICK BUTTON
	public increaseQuantity (panel)
	{
		panel.entryquantity++;
	}

	// FUNCTION TO UPDATE THE VALUES OF CHECKBOX ITEMS
	public onCheckboxChange (panel: any, name: string, value: boolean): void 
	{
		if (value) 
		{
			if (name === 'exittimedexittype') 
			{
				panel[name] = 'C';
			}
			else if (name === 'exittimedmarketdaypart')
			{
				panel[name] = 'O';

			}
			else 
			{
				panel[name] = 'P';
			}
		}
		else 
		{
			panel[name] = 'N';
			if (name === 'exittakeprofittype')
			{
				panel['exittakeprofitvalue'] = null;
			}
			else if (name === 'exittrailingstoplosstype')
			{
				panel['exittrailingstoplossvalue'] = null;
			}
			else if (name === 'exitstoplosstype')
			{
				panel['exitstoplossvalue'] = null;
			}
			else if (name === 'exittimedexittype')
			{
				panel['exittimedexitvalue'] = null;
			}
		}
	}

	// FUNCTION TO UPDATE THE FIELDS
	public onChangeValue (panel: any, type: string, percentageField: string, amountField: string, value: boolean): void 
	{
		if (type === 'P')
		{
			panel[percentageField] = value;
			panel[amountField] = null;
		}
		else 
		{
			panel[percentageField] = value;
			panel[amountField] = null;
		}
	}

	// FUNCTION TO UPDATE THE VALUE ON CHANGE ORDER TYPE
	public onOrderTypeChange (panel, newValue: any) 
	{
		// console.log('Order Type changed to:', newValue);
		if (newValue === 'L')
		{
			panel.entryexecutiontime = "A";
		}
		else
		{
			panel.entryexecutiontime = "O";
		}
	}


}
