/*
 FILENAME: lk_client/src/app/components/shared/search/search.component.ts
 AUTHOR: AK
 SUMMARY: THIS FILE HOLDS ALL THE LOGICS FOR THE ROOM SCHEDULE ADD EDIT FORM.
 PURPOSE: DISPLAYING THE SEARCH FORM.
 IMPORTING FILES: helper.service.ts
 SUBSCRIBING FILES: shared.module.ts
 LAST COMMIT DATE: Nov 30, 2022
 */

// IMPORTING THE ANGULAR MODULES FOR PERFORMING BASIC ANGULAR FRAMEWORK OPERATIONS.
import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

// IMPORTING THE LK HELPER SERVICE TO GET ACCESS TO THE GLOBAL DATA AND SERVICES SO THAT WE CAN USE THE GLOBAL DATA AND FUNCTIONS LIKE MESSAGING AND SITE VARIABLES.
import {HelperService} from "../../../services/helper.service";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

// COMPONENT DECLARATION. HERE WE CAN DEFINE HTML TEMPLATE, CSS FILES AND COMPONENT OPTIONS.
@Component ({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})

// DECLARING THE ScheduleRoomComponent CLASS WITH EXPORT SO THAT WE CAN IMPORT THIS COMPONENT INTO ANY OTHER PARENT COMPONENT.
export class SearchComponent implements OnInit
{
	// SUBJECT TO HANDLE UNSUBSCRIPTION
	public notifier: Subject<boolean> = new Subject ();

	// DEFINING filterCourseForm VARIABLE NEEDED TO CREATE THE REACTIVE FORM
	public filterForm: FormGroup;
	public formGroup: FormGroup | undefined;
	public screenWidth: any;

	@Input () config: any = {
		search: true,
		searchPlaceHolder: 'Search Broadcast . . .',
		addNew: true,
		addNewText: "",
		overviewSwitch: true,
		extension: false,
		broadcast: true,
        broadcastStatus: false,
        broadcastOverview: false,
        filterTypeText: 'Filter by type',
        sourceType: false,
        extenstionFlex: false,
        getExtension: false,
        extensionOverview: false,
        mode: false,
        invisiphone:false,
		invisiphoneadvance:false,
        getSicOverview: false,
        name: '',
        activestatus: false,
        entity: false,
		message: false,
		messageText: '',
		messageClass: '',
		entityPhonesMembers: false
	}

	@Input () public sourceTypeDataSource: any;

	// GET WIDTH FROM PARENT COMPONENT
	@Input () public searchFieldWidth: any;


	public defaultItempublic: Array<{ text: string; value: string }> =
    [
        {
            text: 'Select Source Type',
            value: ''
        }
    ];

	@Input () public modeTypeDataSource: any;

	@Input () public filterData: any;

	// NEW COURSE BUTTON
	@Output () onAddNew = new EventEmitter ();

	// NEW UPLOAD BUTTON
	@Output () onUpload = new EventEmitter ();

	// NEW DOWNLOAD BUTTON
	@Output () onDownload = new EventEmitter ();

	// ON FILTER CHANGE
	@Output () onFilter = new EventEmitter ();

	// ON FILTER CHANGE
	@Output () onChangeSwitch = new EventEmitter ();

	// ROLE DROPDOWN DATA SOURCE FOR FILTER
	@Input () userRoleFilterDatasource: any;

	// ROLE DROPDOWN DATA SOURCE FOR FILTER
	@Input () roleFilterList: any;

	// COURSE LIST FILTER FOR STATIC DATA.
	@Input () courseListFilter: any;

	// COURSE LIST FILTER FOR STATIC DATA.
	@Input () inheritStatusFilter: any;

	// ALL MODULES LIST TO FILTER STATIC DATA
	@Input () modulesListsFilter: any;

	// STATUS DROPDOWN DATA SOURCE.
    public statusListItems: Array<any> = [
        {
            text: 'Any status',
            value: 'ALL'
        },
        {
            text: 'Active',
            value: 'A'
        },
        {
            text: 'Inactive',
            value: 'I'
        }
    ];

	// ROUTE TO DROPDOWN DATA SOURCE.
	public routeFilterListItems: Array<any> = [{
		text: 'Route to',
		value: ''
	}];

	// URGENCY DROPDOWN DATA SOURCE.
	public urgencyFilterListItems: Array<any> = [{
		text: 'Any urgency',
		value: ''
	}];

	public checked: boolean = true;


    public selectedValue: any = this.statusListItems[0];

    // CLASS CONSTRUCTOR, THIS WILL BE FIRST FUNCTION TO BE EXECUTED WHEN THIS CLASS LOADS.
    // HERE WE WILL TELL ANGULAR TO INJECT A DEPENDENCY BY SPECIFYING A CONSTRUCTOR PARAMETER WITH THE DEPENDENCY TYPE.
    constructor (
		// LOADING HelperService CLASS WITH ALIAS helper TO GET ACCESS TO THE GLOBAL DATA.
		public helper: HelperService,
	    private formBuilder: FormBuilder, // FORM BUILDER FOR CREATING REACTIVE FORMS
    )
    {
    }

    // REQUIRED BY ANGULAR AND CAN BE USED FOR INITIAL SETUP IF REQUIRED.
    ngOnInit (): void
    {
    	this.onResize();

		// SET DEFAULT WIDTH FOR SEARCH FIELD
		if(!this.searchFieldWidth)
		{
			this.searchFieldWidth = 160;
		}

	    // CREATING REACTIVE FORM SO THAT WE CAN CONTROL THE HTML FORM FROM THIS FILE.
	    this.filterForm = this.formBuilder.group
	    ({
		    ...(this.config.search && {keyword: ''}),
		    ...(this.config.extension && {status_dropdown: 'ALL'}),
		    ...(this.config.extension && {extensiontype: 'ALL'}),
		    ...(this.config.broadcast && {status: 'ALL'}),
		    ...(this.config.broadcast && {type: this.config.filterTypeText}),
		    ...(this.config.overviewSwitch && {overviewSwitch: false}),
            ...(this.config.sourceType && {sourceType: ''}),
            ...(this.config.mode && {modeType: 'Select Mode'}),
            ...(this.config.activestatus && {activestatus: 'ALL'}),
            ...(this.config.invisiphone && {favoritestatus: ''}),
            ...(this.config.invisiphoneadvance && {statusAdvance: 'ALL'}),
			...(this.config.entityPhonesMembers && {entityPhonesMembers: false}),
	    });

	    // INITIAL FILTER REQUEST
	    //const options = this.getFilterOptions (this.filterForm.value);
	    // this.onFilter.emit (this.filterForm.value);
	    // INITIAL FILTER REQUEST
	    const options = this.getFilterOptions (this.filterForm.value);
	    this.onFilter.emit ({
		    filters: options,
		    resetGrid: true
	    });

	    // TRIGGER SEARCH IF THERE IS ANY CHANGE OF FILTER TOOLBAR
	    this.filterForm.valueChanges.pipe
	    (
		    debounceTime (200),
		    distinctUntilChanged (),
		    takeUntil (this.notifier)
	    ).subscribe ((res: any) =>
	    {
		    //console.log(res, '>>PO')
		    //const options = this.getFilterOptions (res);
		    // this.onFilter.emit (res);
		    const options = this.getFilterOptions (res);
		    this.onFilter.emit ({
			    filters: options,
			    resetGrid: true
		    });
	    });
    }

	// HANDLE THE FILTER OF GRID
	public getFilterOptions (values: any)
	{
		let filterOptions = [];
		// IF THE SEARCH TERM IS NOT BLANK PUSH ITS VALUE TO FILTER ARRAY
		const {keyword, extensiontype, status_dropdown, favoritestatus, statusAdvance, activestatus} = values;

		// IF TYPED STATUS VALUE IS NOT BLANK AND VALUE LENGTH > 1 . . .
		if (keyword && keyword != '')
		{
			//. . .PUSH THIS FILTER OPTION TO FILTER OPTIONS ARRAY
			filterOptions.push ({
				field: 'keyword',
				operator: 'LIKE',
				value: keyword
			});
		}

		// IF STATUS VALUE IS NOT BLANK AND VALUE LENGTH > 1 . . .
		if (status_dropdown && status_dropdown != '')
		{
			//. . .PUSH THIS FILTER OPTION TO FILTER OPTIONS ARRAY
			filterOptions.push ({
				field: 'status_dropdown',
				operator: 'equals',
				value: status_dropdown
			});
		}

		// IF TYPED STATUS VALUE IS NOT BLANK AND VALUE LENGTH > 1 . . .
		if (extensiontype && extensiontype != '')
		{
			//. . .PUSH THIS FILTER OPTION TO FILTER OPTIONS ARRAY
			filterOptions.push ({
				field: 'extensiontype',
				operator: 'equals',
				value: extensiontype
			});
		}

		// IF TYPED STATUS VALUE IS NOT BLANK AND VALUE LENGTH > 1 . . .
		if (favoritestatus && favoritestatus != '')
		{
			//. . .PUSH THIS FILTER OPTION TO FILTER OPTIONS ARRAY
			filterOptions.push ({
				field: 'favoritestatus',
				operator: 'equals',
				value: favoritestatus
			});
		}

		// IF TYPED STATUS VALUE IS NOT BLANK AND VALUE LENGTH > 1 . . .
		if (statusAdvance && statusAdvance != '')
		{
			//. . .PUSH THIS FILTER OPTION TO FILTER OPTIONS ARRAY
			filterOptions.push ({
				field: 'statusAdvance',
				operator: 'equals',
				value: statusAdvance
			});
		}

		// IF TYPED STATUS VALUE IS NOT BLANK AND VALUE LENGTH > 1 . . .
		if (activestatus && activestatus != '')
		{
			//. . .PUSH THIS FILTER OPTION TO FILTER OPTIONS ARRAY
			filterOptions.push ({
				field: 'activestatus',
				operator: 'equals',
				value: activestatus
			});
		}

		return filterOptions;
	}

	onSwitchChange(value: any)
	{
		console.log('sending receiving', this.filterForm.controls.overviewSwitch.value);
		this.onChangeSwitch.emit(this.filterForm.controls.overviewSwitch.value);
	}

	onAddNewClick()
	{
		this.onAddNew.emit();
	}

	@HostListener ('window:resize', ['$event'])
	onResize ()
	{
		this.screenWidth = window.innerWidth;
	}
}
