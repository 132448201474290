/*
		   FILENAME: lk_client/src/app/components/utils/page-not-found/page-not-found.component.ts
	         AUTHOR: Zulaikha Moidin, Qasim Zubair
 	        SUMMARY: HIDES THE SITE LOADER AND DISPLAYS AN ERROR MESSAGE.
            PURPOSE: TO DISPLAY AN ERROR MESSAGE IF THE PAGE USER HAS REQUESTED DOES NOT EXIST.
    IMPORTING FILES: helper.service.ts
  SUBSCRIBING FILES: helper.service.ts | app-routing.module.ts | app.module.ts | page-not-found.component.spec.ts
   LAST COMMIT DATE: July 14, 2020
*/

// IMPORTING THE ANGULAR MODULES FOR PERFORMING BASIC ANGULAR FRAMEWORK OPERATIONS.
import {Component, OnInit} from '@angular/core';

// IMPORTING THE LK HELPER SERVICE TO GET ACCESS TO THE GLOBAL DATA AND SERVICES SO THAT WE CAN USE THE GLOBAL DATA AND FUNCTIONS.
import {HelperService} from "../../../services/helper.service";

// COMPONENT DECLARATION. HERE WE CAN DEFINE HTML TEMPLATE, CSS FILES AND COMPONENT OPTIONS.
@Component
({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss']
})

// MAIN CLASS INITIALIZATION FOR THIS COMPONENT.
export class PageNotFoundComponent implements OnInit
{
	// CLASS CONSTRUCTOR, THIS WILL BE FIRST FUNCTION TO BE EXECUTED WHEN THIS CLASS LOADS.
	// HERE WE WILL TELL ANGULAR TO INJECT A DEPENDENCY BY SPECIFYING A CONSTRUCTOR
	// PARAMETER WITH THE DEPENDENCY TYPE.
	constructor
	(
	  public helper: HelperService
	)
	{}
	
	// ANGULAR LIFE CYCLE HOOK THAT WILL BE CALLED WHEN THIS COMPONENT IS INITIALIZED.
	ngOnInit()
	{
		// HIDING THE SITE LOADER. THIS LOADER MESSAGE STARTED DISPLAYING WHEN USER HAD OPENED SOME URL
		// OF THIS APP. IF THE URL DOES NOT EXIST THEN WE WILL HIDE THE LOAD AND DISPLAY PAGE NOT FOUND ERROR.
		this.helper.hideSiteLoader ();
	}
}
