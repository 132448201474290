<section class="main-layout-container bg-white position-relative">
	<div class="simple-content">
		<div class="filter-content">
			<div class="top-bar filters-bar pull-right">
				<ng-container>
					<app-search [config]="filterConfig" (onChangeSwitch)="onToggleOverview ($event)"></app-search>
				</ng-container>
			</div>
		</div>
		<div class="main-wrapper" *ngIf="currentView === 'dashboard'" @enterExitLeft>
			<div class="content-area">
				<div>
					<div class="links">
						<div class="d-flex">
							<div class="side-section">
								<div class="links-list left-aligned">
									<ul class="itemslinks itemslistscroll">
										<li *ngFor="let source of currentSource; let i = index" >
											<div class="row listrow" [ngClass]="{'active': i === 0}">
												<div class="col-md-2">
													<img src="assets/images/trade/{{source['iconurl']}}"/>
												</div>
												<div class="col-md-10">
													<div class="sourcename">{{source['source']}}</div>
												</div>
											</div>
										</li>
									</ul>
									
									<!--DATA PAGER-->
									<div class="row">
										<div class="col-12">
											<div class="w-100 pager-wrapper left-pager">
												<kendo-datapager
														class="theme-pager"
														[pageSize]="pageSize"
														[skip]="skip"
														[buttonCount]="2"
														[total]="total"
														[info]="false"
														[pageSizeValues]="false"
												>
												</kendo-datapager>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="main-section">
								<div style="height: 56px" class="top-list">
									<div class="top-list-container">
										<div
											class="top-list-nav-left"
											[class.disabled]="isListLeftDisabled()"
											(click)="navigate ('left')"
										>
											<button><img [src]="isListLeftDisabled() ?
											'assets/images/trade/arrow-left-disabled.svg' :
											'assets/images/trade/arrow-left.svg'" /></button>
										</div>
										<div class="top-list-wrapper" >
											<div class="top-list-item"
												*ngFor="let source of topLinkSource.slice (startListIndex,
												endListIndex); let i = index"
												[ngClass]="{'active': (i + (currentIndex >= visibleListItems ? incrementIndexValue : 0)) === activeListIndex}"
												(click)="selectedActiveIndexItem (i)"
											>
												<div class="col-md-2">
													<img src="assets/images/trade/{{source['iconurl']}}"/>
												</div>
												<div class="col-md-10">
													<div class="sourcename">{{source['title']}}</div>
												</div>
											</div>
										</div>
										<div
											class="top-list-nav-right"
											[class.disabled]="isListRightDisabled()"
											(click)="navigate ('right')"
										>
											<button ><img [src]="isListRightDisabled() ?
											'assets/images/trade/arrow-right-disabled.svg' :
											'assets/images/trade/arrow-right.svg'" /> </button>
										</div>
									</div>
								</div>
								<div class="linkplay-content">
									<img src="assets/images/trade/videoplayer.svg">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main-wrapper bg-white overview-wrapper" *ngIf="currentView === 'overview'"  @enterExitRight>
			<div class="row">
				<div class="col-sm-12">
					<app-dashboard-overview></app-dashboard-overview>
				</div>
			</div>	
		</div>
	</div>
</section>

