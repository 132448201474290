// IMPORTING Injectable FROM ANGULAR CORE.
import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from "@angular/router";
import { HelperService } from "../services/helper.service";

// INJECTING TO THE APP PROVIDER SO THAT WE CAN USE IT THROUGHOUT THE APPLICATION.
@Injectable
({
	providedIn: "root",
})

// DECLARING THE AuthGuard CLASS WITH EXPORT SO THAT WE CAN USE THIS CLASS IN OTHER FILES.
export class AuthGuard implements CanActivate
{
	// CLASS CONSTRUCTOR, THIS WILL BE THE FIRST FUNCTION TO BE EXECUTED WHEN THIS CLASS LOADS.
	// HERE WE WILL TELL ANGULAR TO INJECT A DEPENDENCY BY SPECIFYING A CONSTRUCTOR
	// PARAMETER WITH THE DEPENDENCY TYPE.
	constructor
	(
		private helperService: HelperService,
		private router: Router,
	)
	{
	}

	// THIS FUNCTION IS THE ROUTE ACTIVATOR USED IN app.routing.module. IT WILL RETURN TRUE IF USER
	// IS LOGGED IN, ELSE, REDIRECT THE USER TO LOGIN PAGE.
	async canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>
	{
		const url: string = state.url;
		return await this.checkLogin (url);
	}

	// THIS FUNCTION IS USED TO CHECK IF THE USER IS LOGGED IN OR NOT.
	// IF LOGGED IN, RETURN TRUE, ELSE, ROUTE THE USER TO LOGIN PAGE.
	private async checkLogin (url: string)
	{
		// IF THE USER IS LOGGED IN.
		if (this.helperService.isLoggedIn ())
		{
			let isAuthorized = this.helperService.isAuthorized (url);
			console.log ("isAuthorized result", isAuthorized);

			// IF USER IS NOT AUTHORIZED TO ACCESS THIS PAGE THEN REDIRECT THEm TO UNAUTHORIZED PAGE.
			if (!isAuthorized)
			{
				this.router.navigate (["/unauthorized"]);
				return false;
			}
			return true;
		}
		else // IF THE USER IS NOT LOGGED IN.
		{
			this.router.navigate (["/login"]);
			return false;
		}
	}
}
