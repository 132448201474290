<div class="content-area mt-0">
	<div class="overview-content">
		<div class="links">
			<div class="d-flex">
				<div class="side-section">
					<div class="links-list left-aligned">
						<div class="links-list-nav-up" [class.disabled]="isListTopDisabled()" (click)="navigate ('up')">
							<img
								[src]="isListTopDisabled() ? 'assets/images/trade/arrow-up-disabled.svg' : 'assets/images/trade/arrow-up.svg'" />
						</div>
						<div class="itemslinks itemslistscroll">
							<div class="listrow" *ngFor="let source of currentSource.slice (startListIndex, endListIndex); let i =
									 index" [ngClass]="{'active': (i + (currentIndex >= visibleListItems ? incrementIndexValue : 0)) === activeListIndex}"
								(click)="selectedActiveIndexItem (i)">
								<div class="sourcename">{{ source['title'] }}</div>

							</div>
						</div>
						<div class="links-list-nav-down" [class.disabled]="isListDownDisabled()"
							(click)="navigate ('down')">
							<img
								[src]="isListDownDisabled() ? 'assets/images/trade/arrow-down-disabled.svg' : 'assets/images/trade/arrow-down.svg'" />
						</div>
					</div>
				</div>
				<div class="main-section">
					<div class="linkplay-content">
						<img src="assets/images/trade/videoplayer.svg">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>