/*
 FILENAME: lk_client/src/app/services/db.service.ts
 AUTHOR: Qasim Zubair
 SUMMARY: HANDLES THE DATABASE RELATED ACTIVITIES. FOR EXAMPLE, SENDING REQUESTS TO SERVER TO
 STORE/UPDATE/DELETE DATA IN DATABASE, THOUGH DELETION WILL GENERALLY BE FORBIDDEN.
 PURPOSE: TO GROUP ALL THE FUNCTIONS THAT REQUIRE DATABASE COMMUNICATION IN THIS IMMEDIATE FILE.
 IMPORTING FILES: environment.ts
 SUBSCRIBING FILES: addeditroom.component.ts | schedule-room.component.ts | choose-name.component.ts
 | codes.component.ts | getting-started.component.ts | publicroom.component.ts
 | roomcreate-by-steps.component.ts | findroom.component.ts
 | roomcreate-final-step.component.ts | roomlist.component.ts
 LAST COMMIT DATE: July 14, 2020
 */

// IMPORTING THE ANGULAR MODULES FOR PERFORMING BASIC ANGULAR FRAMEWORK OPERATIONS.
import {Injectable} from '@angular/core';
// IMPORTING THE ANGULAR HTTP FOR HTTP REQUESTS.
import {HttpClient} from "@angular/common/http";
// IMPORTING THE ANGULAR SUBJECT TO USE AS AN OBSERVABLE.
import {Subject} from "rxjs";
// IMPORTING THE LK ENVIRONMENT FILE TO GET THE STATIC VARIABLES LIKE SERVER URL.
import {environment} from "../../environments/environment";

// @Injectable DECLARES THIS AS A SERVICE SO THAT WE CAN INJECT THIS SERVICE INTO ANY OTHER COMPONENT OR SERVICE.
@Injectable
({
	providedIn: 'root'
})

// DECLARING THE DbService CLASS WITH EXPORT SO THAT WE CAN IMPORT THIS SERVICE INTO ANY OTHER COMPONENT OR SERVICE.
export class DbService
{
	// URL OF THE NODE SERVER WHERE WE WILL SEND REQUESTS TO COMMUNICATE WITH DATABASE.
	public apiURL = environment.apiURL;
	
	// URL OF THE PHONE NODE SERVER WHERE WE WILL SEND REQUESTS TO COMMUNICATE WITH DATABASE.
	public phoneApiUrl = environment.phoneApiUrl;

	// ALL INITIAL STATIC DATA WILL BE LOADED INTO THIS ARRAY AT THE START OF APP INITIALIZATION
	public appStaticData: any;

	// STORE DATA FROM STATIC TABLES. WE ARE STORING ALL THIS DATA INTO THESE VARIABLES SO WE DON'T
	// HAVE TO GET THIS DATA FROM DATABASE AGAIN AND AGAIN.
	public sitevariables:any[] = []; // DATA FROM SITEVARIABLE TABLE.
	public sitemessages:any[] = []; // DATA FROM MESSAGE TABLE.
	public sitejumpcodes:any[] = []; // DATA FROM JUMPCODE TABLE.
	public roomLayout: any[] = []; // DATA FROM LAYOUT TABLE.
	public findroomJumpcodes: any[] = []; // FINDROOM PAGE JUMPCODES.
	
	// OBSERVABLE THAT WILL BE TRIGGERED WHEN WE RECEIVE INITIAL DATA FROM THE SERVER.
	// THIS INITIAL DATA THAT CONTAINS SITEVARIABLES, MESSAGES AND JUMPCODES IS REQUIRED TO RUN THE APP,
	// SO IT WILL BE THE FIRST CALL TO MAKE TO THE DATABASE AS SOON AS THE APP LOADS.
	public appStaticDataObs = new Subject <any>();

	// JUST LIKE THE ABOVE COMMENT, THIS WILL BE TRIGGERED AS SOON AS WE GET THE JUMPCODE DATA FROM THE DATABASE.
	public siteJumpcodesObs = new Subject <any>();
	
	// WE WILL SUBSCRIBE TO THIS OBSERVABLE TO TRIGGER SOME FUNCTIONS WHEN ROOM LAYOUT DATA IS RETRIEVED
	// FROM THE DATABASE. THESE FUNCTIONS WILL CREATE THE ROOM LAYOUT USING CSS.
	public roomLayoutObs = new Subject <any>();
	public findroomJumpcodesObs = new Subject <any>(); // OBSERVABLE FOR FINDROOM PAGE JUMPCODES.
	
	// CLASS CONSTRUCTOR, THIS WILL BE FIRST FUNCTION TO BE EXECUTED WHEN THIS CLASS LOADS.
	// HERE WE WILL TELL ANGULAR TO INJECT A DEPENDENCY BY SPECIFYING A CONSTRUCTOR
	// PARAMETER WITH THE DEPENDENCY TYPE.
	constructor
	(
	    private http: HttpClient,
	)
	{}

	// PERFORM A GET REQUEST
	public performGetRequest(url: string)
	{
		return this.http.get (this.apiURL+url);
	}

	// PERFORM POST REQUEST
	public performPostRequest(url: string, dataObj: any)
	{
		return this.http.post (this.apiURL+url, dataObj);
	}

	// GET ALL STATIC DATA FROM DATABASE. FOR EXAMPLE SITEVARIABLE, MESSAGE, JUMPCODE ETC.
	// WE NEED THIS DATA TO START THE APP.
	public getAppInitialData()
	{
		// SENDING REQUEST TO SERVER TO GET STATIC DATA.
		this.http.get (this.apiURL + "/api/getStaticData").subscribe ( (data) =>
		{
			// IF WE SUCCESSFULLY GOT THE DATA FROM DATABASE . . .
			if ( Object.keys (data ["data"]).length > 0)
			{
				this.appStaticData = data ["data"]; // . . .STORE IT INTO A CLASS VARIABLE.
				
				// TRIGGERING THE OBSERVABLE SO THE APP KNOWS THAT STATIC DATA IS AVAILABLE NOW.
				this.appStaticDataObs.next (this.appStaticData);
			}
		});
	}

	// REFRESH THE STATIC VARIABLES ON THE SERVER; FOR EXAMPLE, IF WE ADD A THE NEW MESSAGE IN
	// MESSAGE TABLE THEN IT WON'T WORK UNTIL WE REFRESH THE SERVER USING REFRESHSERVER JUMPCODE
	// ON FIND ROOM PAGE. ONLY ADMIN AND SUPER ADMIN WILL BE ABLE TO RUN THIS CODE.
	public refreshServerVar()
	{
		// SENDING REQUEST TO SERVER.
		return this.http.get (this.apiURL + "/api/refreshServerVar");
	}

	// GET FORBIDDEN WORDS DATA FROM THE SERVER
	public getForbiddenWordsData()
	{
		return this.http.get (this.apiURL + "/api/forbiddenwords");
	}

	// GET FORBIDDEN WORDS EVASION DATA FROM THE SERVER
	public getforbiddenRoomCombination()
	{
		return this.http.get (this.apiURL + "/api/forbiddenroomcombination");
	}

	// GET FIND REPLACE DATA FROM THE DATABASE. WE WILL GET THIS DATA WHEN THE APP LOADS.
	public getFindReplaceData()
	{
		return this.http.get (this.apiURL + "/api/replace");
	}

	// GETTING DATA FROM SUFFIX TABLE. THIS IS ALSO RELATED TO FORBIDDEN WORDS PROCESS.
	public getSuffixData()
	{
		return this.http.get (this.apiURL + "/api/suffix");
	}

	// FUNCTION TO GET ROOM LIST BY DEVICE ID FROM DATABASE. THIS FUNCTION WILL BE CALLED FORM FINDROOM
	// PAGE WHEN USER WANTS TO SEE THEIR ROOMS.
	public getRoomListByDeviceId (device_id)
	{
		return this.http.post (this.apiURL + "/api/get-roomlist-by-device-id", {device_id: device_id});
	}

	// GET LAYOUT VALUES FOR ROOM FROM LAYOUT TABLE.
	public getLayoutData (devicetype_id)
	{
		this.http.post (this.apiURL+"/api/layout", {devicetype_id: devicetype_id}).subscribe ( (data) =>
		{
			// IF WE GOT THE DATA FROM DATABASE . . .
			if (data ['rows'].length > 0)
			{
				this.roomLayout = data ['rows']; // . . .STORE IT TO CLASS VARIABLE.
				
				// TRIGGERING THE OBSERVABLE SO APP CAN USE THIS DATA.
				this.roomLayoutObs.next (this.roomLayout);
			}
			else
			{
				// THERE WAS SOME PROBLEM IN GETTING THE LAYOUT DATA. WE WILL RETURN FALSE.
				this.roomLayoutObs.next (false);
			}
		});
	}

	// GET JUMPCODES FROM DATABASE.
	public getJumpcodes()
	{
		this.http.get (this.apiURL + "/api/getjumpcodes").subscribe ( (data) =>
		{
			// IF WE SUCCESSFULLY GOT THE JUMPCODE DATA FROM DATABASE. . .
			if (data ['data'].length > 0)
			{
				this.sitejumpcodes = data ['data']; // . . .STORE DATA TO CLASS VARIABLE.
				
				// TRIGGERING THE OBSERVABLE SO APP CAN USE THIS DATA.
				this.siteJumpcodesObs.next (this.sitejumpcodes);
			}
		});
	}

	// GET PRIVATE JUMPCODES FROM DATABASE.
	public getPrivateJumpcode (user_id)
	{
		return this.http.get (this.apiURL + "/api/getprivatejumpcodes/" + user_id);
	}

	// GET FINDROOM JUMPCODES WHICH WILL RUN ON FIND ROOM PAGE.
	public getFindRoomJumpCodes()
	{
		this.http.get (this.apiURL + "/api/find-room-jumpcode").subscribe ( (data) =>
		{
			// IF WE SUCCESSFULLY GOT THE JUMPCODE DATA FROM DATABASE.
			if (data ['rows'].length > 0)
			{
				this.findroomJumpcodes = data ['rows']; // STORING DATA TO CLASS VARIABLE.
				
				// TRIGGERING THE OBSERVABLE SO APP CAN USE THIS DATA.
				this.findroomJumpcodesObs.next (this.findroomJumpcodes);
			}
		});
	}

	// FILTER ROOM NAMES FROM DATABASE ON THE BASIS OF PROVIDED STRING. THIS WILL RUN FROM
	// FINDROOM PAGE WHERE USER CAN SEARCH THE AVAILABLE ROOM BY TYPING SOME STRING IN THE SEARCH/FILTER FIELD.
	public getFilteredRoomNames (match)
	{
		return this.http.post (this.apiURL + "/api/getroomnames", {value: match});
	}

	// FIND IF THE GIVEN VALUE IS IN THE NAME OR THE WORD TABLE.
	// THIS WILL BE USED WHEN USER IS CHOOSING A ROOM NAME.
	public findRoomName (inputValue)
	{
		return this.http.post (this.apiURL + "/api/findroomname", {value: inputValue});
	}

	// FIND IF THE GIVEN VALUE FOR SCREEN NAME IS IN THE NAME OR THE WORD TABLE.
	public findScreenname (inputValue)
	{
		return this.http.post (this.apiURL + "/api/findscreenname", {value: inputValue});
	}

	// GET BUTTON IC INFORMATION FROM SERVER. THIS WILL RETURN DATA THAT WILL PROVIDE US THE BUILDING
	// BLOCKS OF BUTTONS ON THE IDENTITY RECTANGLE. THIS DATA HOLDS THE TRANSITION AND ACTION PROPERTIES
	// OF IC BUTTONS. FOR EXAMPLE, WHAT SHOULD THE CAMERA BUTTON DO AND WHAT COLOR SHOULD IT CHANGE TO
	// WHEN THE USER CLICKS ON IT.
	public getButtonIC()
	{
		return this.http.get (this.apiURL + "/api/get-button-ic");
	}

	// FILTER SCREEN NAMES FROM DATABASE ON THE BASIS OF THE PROVIDED STRING AND RETURN IT TO APP.
	// THIS WILL BE USED WHEN USER IS TYPING THE SCREEN NAME AND WE SHOW THEM SUGGESTIONS.
	public getFilteredScreenNames (match)
	{
		return this.http.post (this.apiURL + "/api/getscreennames", {value: match});
	}
	
	// HANDLING REQUEST TO CLEAR ALL ROOMS THROUGH CLEAR JUMP CODE. THIS JUMPCODE WILL RUN FROM FIND ROOM
	// PAGE. IT AND WILL EXPIRE (ELIMINATE) ALL THE ROOMS.
	public clearRooms()
	{
		return this.http.post (this.apiURL + "/api/clear-rooms", {value: ""});
	}

	// FUNCTION TO STORE DEVICE INFORMATION IN DB
	public saveDevice (components, fpHash, deviceInfo, devicetype_id, DetectRTC)
	{
		return this.http.post (this.apiURL+"/api/store/device",
		{
			components: components, // DIFFERENT COMPONENTS OF DEVICE LIKE SCREEN SIZE, WEBGL, CANVAS HASH ETC.
			fpHash: fpHash, // UNIQUE FINGERPRINT THAT WILL BE USED TO IDENTIFY THIS DEVICE.
			devicetype_id: devicetype_id, // IS IT DESKTOP, IPHONE, ANDROID OR IPAD?
			deviceInfo: deviceInfo, // DEVICE INFO THAT WE GET FROM USER AGENT STRING OF BROWSER, LIKE OS OS VERSION.
			hasWebcam: DetectRTC.hasWebcam, // DOES USER HAVE A WEBCAM?
			hasMicrophone: DetectRTC.hasMicrophone, // DOES USER HAS A MIC?
		});
	}

	// FUNCTION TO GET DEVICE INFORMATION FROM DATABASE, LIKE DEVICE ID, SCREEN NAME OF DEVICE, OS, OS VERSION ETC.
	public getDeviceInfo (fingerprint)
	{
		return this.http.post (this.apiURL + "/api/get-device", {fingerprint: fingerprint});
	}
	
	// FUNCTION TO GET DEVICE INFORMATION FROM DATABASE, LIKE DEVICE ID, SCREEN NAME OF DEVICE, OS, OS VERSION ETC.
	public doAutoLogin (obj: any)
	{
		return this.http.post (this.apiURL + "/api/user/auto_login", obj);
	}
	
	public deleteScanSuccess(obj: any)
	{
		return this.http.post (this.apiURL + "/api/user/delete_scan_success", obj);
	}
	
	public validateAlias(alias: any)
	{
		return this.http.post (this.apiURL + "/api/user/validate_alias", {alias: alias});
	}

	// FUNCTION TO SAVE USER DETAILED INFORMATION IN ROOMDETAIL TABLE, INFO LIKE DEVICE ID, USER NAME,
	// CLASS, USER TYPE AND OTHER PROPERTIES.
	public saveRoomDetail (data)
	{
		return this.http.post (this.apiURL + "/api/store/roomdetail", data);
	}

	// FUNCTION TO SAVE NEWLY CREATED ROOM INTO DATABASE. THIS WILL HAPPEN WHEN USER CREATES A NEW ROOM.
	public saveRoom (data)
	{
		return this.http.post (this.apiURL + "/api/store/room", data);
	}

	// UPDATING ROOM DETAIL INFORMATION, WE WILL NEED TO UPDATE THESE DETAILS TO KEEP TRACK OF
	// MULTIPLE EVENTS LIKE CALL DROP, USER BANDWIDTH, INTERNET SPEED ETC.
	public updateRoomDetail (data, id_detail)
	{
		this.http.post (this.apiURL + "/api/store/update_room_detail",
		{
			data: data,
			roomdetail_id : id_detail
		}).subscribe (res =>
		{
			// EVERYTHING WENT OK, DATA SAVED.
			console.log (res);
		});
	}

	// FUNCTION TO UPDATE DEVICE TABLE, WE CAN UPDATE THINGS LIKE DEVICE TEST RESULTS.
	public updateDevice (data, device_id)
	{
		this.http.post (this.apiURL + "/api/update_device",
		{
			data: data,
			device_id : device_id
		}).subscribe (res =>
		{
			// EVERYTHING WENT ALRIGHT. DATA SAVED.
			console.log (res);
		});
	}

	// FUNCTION TO UPDATE USER TABLE. THIS IS A GENERAL FUNCTION, USED
	// WHEN WE WANT TO UPDATE ANY USER RELATED DATA LIKE FIRST NAME, EMAIL, PASSWORD
	// OR ANY OTHER PROFILE INFORMATION.
	public updateUser (data, user_id, device_id)
	{
		return this.http.post (this.apiURL + "/api/user/update",
		{
			data: data, // DATA THAT WE WANT TO UPDATE.
			user_id : user_id, // USER ID OF THE USER WHOSE DATA WE ARE SAVING.
			device_id: device_id // DEVICE ID OF THE USER WHOSE DATA WE ARE SAVING.
		});
	}

	// FUNCTION TO UPDATE USER ROLE
	public updateUserRole(user_id, role_id)
	{
		return this.http.post (this.apiURL + "/api/user/updaterole",
		{
			user_id : user_id, // USER ID OF THE USER.
			role_id: role_id // NEW ROLE OF THE USER.
		});
	}
	
	// UPDATE SCREEN NAME IN DEVICE TABLE WHEN THE SCREENNAME IS CHANGED FOR A DEVICE
	public updateUserScreenName (data, device_id)
	{
		let apiData = {username: data.username, device_id : device_id};
		if (data.user_id)
		{
			apiData['user_id'] = data.user_id;
		}

		if (data.room_id)
		{
			apiData['room_id'] = data.room_id;
		}

		return this.http.post (this.apiURL + "/api/updateUserScreenName", apiData);
	}

	// FUNCTION TO VALIDATE SCREEN NAME. THIS WILL BE CALLED TO VALIDATE USER'S ENTERED NAME
	// WHEN THEY TRY TO CHANGE THEIR SCREEN NAME. NOTE THAT SCREEN NAME AND USERNAME ARE REFERRING TO THE SAME
	// THING HERE, A POSSIBLE FUTURE SOURCE OF CONFUSION
	public validateUserScreenname (username)
	{
		return this.http.post (this.apiURL + "/api/validateUserScreenName",
		{
		  username: username,
		});
	}

	// FUNCTION TO UPDATE ROOM INFORMATION IN DATABASE. WE WILL USE THIS FUNCTION FOR EVENTS LIKE
	// CLOSING THE ROOM, EXPIRING THE ROOM, ACTIVATING THE ROOM ETC.
	public updateRoom (data, room_id)
	{
		return this.http.post ( this.apiURL + "/api/update_room",
		{
			data: data,
			room_id: room_id
		});
	}

	// FUNCTION TO MAKE GENERAL API CALL. THIS CAN BE ANY MESSAGE WE WANT TO SEND TO SERVER.
	public callApi (link, data)
	{
		return this.http.post (this.apiURL + link,data);
	}

	// FUNCTION TO GET AVAILABLE ROOMS, WE WILL DISPLAY THESE ROOMS IN FIND ROOM PAGE.
	public getAvailableRooms (dateTime)
	{
		console.log ("Current date time in EST ", dateTime);
		
		// GET THE ROOMS FROM THE SERVER.
		return this.http.post (this.apiURL + "/api/getavailablerooms",{dateTime: dateTime});
	}

	// FUNCTION TO GET ROOM FROM DATABASE. THIS WILL GET THE DETAIL OF THE ROOM LIKE HOST NAME, ROOM NAME ETC. , AND
	// WILL BE CALLED WHEN THE USER TRIES TO JOIN THE ROOM USING A LINK OR BY CLICKING ON THE ROOM FROM
	// FIND ROOM PAGE.
	public getRoom (params)
	{
		return this.http.post (this.apiURL + "/api/get-room", params);
	}
	
	// HANDLING REQUEST TO GET ALL THE ROOM FORMATS TO POPULATE THE ROOMFORMAT WIDGET ON FIND ROOM.
	public getRoomFormat()
	{
        return this.http.get (this.apiURL + "/api/getroomformat");
	}
	
	// HANDLING REQUEST TO SEND TABLE DATA FOR A GIVEN TABLE TO USE WITH EDIT JUMPCODE.
	public getTableData (tablename)
	{
        return this.http.post (this.apiURL + "/api/gettabledata", {tablename: tablename});
	}

	// SENDING ALL THE USER PROVIDED DATA FROM REGISTRATION FORM TO DATABASE
	// SO AN ACCOUNT FOR THIS USER CAN BE CREATED.
	public registerUser (customerData: any)
	{
		return this.http.post (this.apiURL + "/api/user/register",customerData);
	}
	
	public autoLoginByActivationToken(activationtoken, device_id)
	{
		return this.http.post (this.apiURL + "/api/user/login/activationtoken", {activationtoken: activationtoken, device_id: device_id});
	}

	// API TO LOGIN USER. THIS WILL BE CALLED FROM THE ACCOUNT PAGE WHEN USER TRIES TO LOGIN TO THEIR ACCOUNT.
	public loginUser (username, device_id)
	{
		return this.http.post (this.apiURL + "/api/user/login", {username: username, device_id: device_id});
	}

	// API TO LOGOUT USER, CALLED WHEN USER TYPES LOGOUT JUMPCODE IN FIND ROOM PAGE.
	public logoutUser (user_id, device_id)
	{
		return this.http.post (this.apiURL + "/api/user/logout", {user_id: user_id, device_id: device_id});
	}

	// CALLED WHEN USER TRIES TO RESET THEIR LOGIN COOKIE.
	resetLogin (customerData: any)
	{
		return this.http.post (this.apiURL + "/api/user/reset", customerData);
	}
	
	// VALIDATING THE COOKIE STORED IN USER'S BROWSER. WE WILL COMPARE IT WITH
	// THE COOKIE PASSWORD STORED IN DATABASE. IF IT MATCHES THEN THE COOKIE IS VALID.
	validateCookie (passwordCookie: string)
	{
		return this.http.post (this.apiURL + "/api/user/validateCookie", {cookiepassword: passwordCookie});
	}

	// GET THE FILE FROM SERVER FROM THE GIVEN URL.
	// THIS IS TO GET THE CURRENT UPLOADED FILES FOR THE SELECTED JUMPCODE
	getFiles (url, ftype, fpath)
	{
		return this.http.post (this.apiURL + url, {filetype: ftype, filepath: fpath});
	}

	// FUNCTION TO UPDATE THE JUMPCODE IN THE JUMPCODE TABLE.
	// WE WILL DO THAT TO UPDATE THE URL VALUE OF ANY FILE.
	updateJumpcode (jid, jurl)
	{
		return this.http.post (this.apiURL + "/api/updateJumpcode", {jumpcode_id: jid, url: jurl});
	}

	// DELETE FILES FOR JUMPCODES. THIS FUNCTION WILL BE CALLED WHEN USER DELETES
	// AN UPLOADED FILE FROM JUMPCODE.
	deleteJumpcodeFile (filepath)
	{
		return this.http.post (this.apiURL + "/api/deleteJCFile", {filepath: filepath});
	}

	// DELETING SCROLL ITEM FILES.
	deleteScrollItems (obj)
	{
		return this.http.post (this.apiURL + "/api/deletescrollitems", obj);
	}
	
	// DELETES DATA FROM ROOM. THIS WILL HAPPEN WHEN HOST TYPES FLUSH JUMPCODE IN ROOM.
	deleteRoomData (room_id)
	{
		return this.http.post (this.apiURL + "/api/deleteroomData", {room_id: room_id});
	}

	// HOSTS CAN UPLOAD PRESENTATIONS. THE FUNCTION SAVES
	// THE PRESENTATION UPLOADED BY THE HOST.
	savePresentation (data)
	{
		return this.http.post (this.apiURL + "/api/presentation/save", data);
	}

	// REQUEST TO SERVER TO SEND PASSWORD RECOVERY EMAIL TO USER. THIS WILL HAPPEN
	// WHEN THE USER CLICKS ON RECOVER PASSWORD FROM ACCOUNT SECTION.
	resetPassword (data)
	{
		return this.http.post (this.apiURL + "/api/user/recoverpassword", data);
	}

	// THIS WILL UPDATE THE USER'S PASSWORD.
	resetNewPassword (data)
	{
		return this.http.post (this.apiURL + "/api/user/resetnewpassword", data);
	}

	// FUNCTION TO GET ALL THE CATEGORY WISE JUMPCODES. THIS WILL DISPLAY ON FIND ROOM CODE WHEN USER
	// TYPES CODES IN JUMPCODE FIELD.
	public getAllJumpCodes (device_id)
	{
		return this.http.post (this.apiURL + "/api/getalljumpcodes", {device_id: device_id});
	}

	// THIS WILL GET THE DATA OF ALL USERS FROM USER TABLE AND USER ROLE TABLE.
	getManageUserData(device_id, state)
	{
		return this.http.post (this.apiURL + "/api/getmanageuserdata",
		{
			device_id: device_id,
			state: state
		});
	}

	// CLEARING ANY PRESENTATION DATA OF CURRENT ROOM FROM DATABASE/SERVER. THIS WILL HAPPEN
	// WHEN HOST JOINS THE ROOM. WE WILL CLEAR ALL THE OLD DATA SO ROOM CAN BE STARTED FRESH.
	clearPresentationData (data)
	{
		return this.http.post (this.apiURL + "/api/presentation/delete", data);
	}

	// THIS FRUNCTION WILL STORE THE SESSION ERROR LOG DATA INTO DB.
	storeSessionErrorInDB (sessionError)
	{
		return this.http.post (this.apiURL + "/api/saveerrorfromsession", {sessionError: sessionError});
	}

	// THIS FUNCTION GET THE ROOM ERROR FOR A USER BY ROOM SESSION ID AND USER DEVICE ID.
	getUserLog(device_id, roomsession_id)
	{
		return this.http.post (this.apiURL + "/api/getroomusererrorfromdb",
		{
			device_id: device_id,
			roomsession_id: roomsession_id
		});
	}
	
	// THIS FUNCTION GET THE ROOM ERROR FOR A USER BY ROOM SESSION ID AND USER DEVICE ID.
	sendContactEmail(formData)
	{
		return this.http.post (this.phoneApiUrl + "/api/sendcontactemail", formData);
	}
    
    // THIS FUNCTION WILL BE CALLED TO FETCH INVISIPHONE NUMBERS BASED ON CURRENT LKPHONE_ID OF USER
    public getInvisiphoneNumbers (lkphoneId)
    {
        return this.http.get<any> (this.phoneApiUrl + '/api/phone/list?lkphone_id=' + lkphoneId);
    }

	public getAvatarId (lkphoneId)
    {
        return this.http.get<any> (this.apiURL + '/api/lktraders/trademanager/get-avatar?lkphone_id=' + lkphoneId);
    }
}
