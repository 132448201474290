/*
           FILENAME: lk_client/src/app/pipes/checkforbidden.pipe.ts
	         AUTHOR: Zulaikha Moidin
 	        SUMMARY: FIGURE OUT IF THE GIVEN CONTENT CONTAINS ANY FORBIDDEN WORDS
            PURPOSE: CHECK IF GIVEN CONTENT CONTAINS FORBIDDEN WORDS FROM THE DATABASE
    IMPORTING FILES: helper.service.ts
  SUBSCRIBING FILES: app.module.ts | privateroom.component.ts | nowroom.component.ts | privatechat.component.ts | publicchat.component.ts | jump.service.ts | homewidgets.component.ts
   LAST COMMIT DATE: July 13, 2020
*/

// IMPORTING ANGULAR CORE LIBRARIES
import {Injector, Pipe, PipeTransform} from '@angular/core';

// IMPORTING THE LK HELPER SERVICE TO GET ACCESS TO THE GLOBAL DATA AND SERVICES SO THAT WE CAN USE THE GLOBAL DATA AND FUNCTIONS.
import {HelperService} from "../services/helper.service";

// IMPORTING THE TOASTER SERVICE SO THAT WE CAN SHOW THE TOAST MESSAGES AT THE TOP RIGHT OF THE SCREEN.
import {ToastrService} from "ngx-toastr"

// ANGULAR DECLARATION FOR A PIPE
@Pipe
({
	name: 'checkForbiddenWord'
})

// DECLARING THE CheckForbiddenWord CLASS THAT IMPLEMENTS PIPETRANSFORM ANGULAR INTERFACE
export class CheckForbiddenWord implements PipeTransform
{
	private toastr: ToastrService
	private inputString: string = ''; // THE CURRENT VALUE BEING PROCESSED

	// CONSTRUCTOR FUNCTION OF THE CLASS. IT WILL BE THE FIRST FUNCTION THAT WILL BE EXECUTED WHEN THIS CLASS LOADS.
	constructor (
		private injector:Injector,
		private helper: HelperService) {}

	// STANDARD PIPE FUNCTION TO DISPLAY AFTER PROCESSING THE GIVEN INPUT
	transform(value: string, args?: any): any
	{
		let resultObj = {}; // RESULT TO BE RETURNED TO THE CALLER
		resultObj["error"] = false;

		// STORE THE VALUE TO TEST.
		this.inputString = value;

		// ASSIGN THE VALUE TO SEND.
		resultObj["value"] = value;

		// CHECK FOR FORBIDDEN WORDS
		resultObj["error"] = this.checkForbiddenWords();
		return resultObj;
	}

	// TEST IF ANY FORBIDDEN WORD IS PRESENT IN THE INPUT STRING.
	checkForbiddenWords()
	{
		let matchFound = false; // TRUE WHEN ANY FORBIDDEN WORD IS FOUND
		
		// WE REMOVE ALL THE CHARACTERS OTHER THAN LETTERS SO WE CAN GET ONLY WORDS;
		// IN OUR WORDS SINGLE QUOTES ARE ALLOWED.
		let wordsArray = this.inputString.match(/\b(\w|')+\b/gim);
		console.log(wordsArray);

		// IF THERE ARE WORDS IN THE ARRAY, CHECK EACH WORD IF IT IS A FORBIDDEN WORD
		if(wordsArray)
		{
			this.toastr = this.injector.get(ToastrService);
			wordsArray.forEach(word =>
			{
				// WE WILL TEST IF ANY FORBIDDEN WORDS ARE PRESENT IN THE INPUT STRING.
				if (new RegExp(this.helper.forbiddenWordsList.join("|"), "i").test(word))
				{
					// AT LEAST ONE POSSIBLE MATCH IS FOUND. SHOW ERROR MESSAGE
					// FIRST WE LOWERCASE ALL THE WORDS, SO OUR COMPARISON IS CASE INSENSITIVE.
					var lowerCaseWords = this.helper.forbiddenWordsList.map(function(value)
					{
						return value.toLowerCase();
					});
					  
					// THERE COULD HAVE BEEN A SUBSTRING MATCH. NOW CHECK IF THE ENTIRE WORD MATCHES.
					if(lowerCaseWords.indexOf(word.toLowerCase()) >= 0)
					{
						this.toastr.error("Forbidden word found. Please remove.");
						matchFound = true;
					}
				}
			});
		}

		// RETURN THE BOOLEAN WHICH SAYS IF THE FORBIDDEN WORD HAS BEEN FOUND OR NOT
		return matchFound;
	}
}
