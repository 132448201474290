/*
           FILENAME: lk_client/src/app/pipes/clickablelink.pipe.ts
	         AUTHOR: Anindya Dutta
 	        SUMMARY: THIS FILE IS USED TO GET A LINK AND RETURN IT AS AN ANCHOR TAG TO BE PLACED AS A CLICKABLE LINK IN PUBLIC AND PRIVATE CHAT AREA
            PURPOSE: GETTING CLICKABLE LINK FROM A URL
    IMPORTING FILES: helper.service.ts
  SUBSCRIBING FILES: helper.service.ts | app.module.ts | privatechat.component.ts | publicchat.component.ts
   LAST COMMIT DATE: July 13, 2020
*/

// IMPORTING ANGULAR CORE LIBRARIES
import {Injector, Pipe, PipeTransform} from '@angular/core';

// IMPORTING THE LK HELPER SERVICE TO GET ACCESS TO THE GLOBAL DATA AND SERVICES SO THAT WE CAN USE THE GLOBAL DATA AND FUNCTIONS.
import {HelperService} from "../services/helper.service";

// IMPORTING THE TOASTER SERVICE SO THAT WE CAN SHOW THE TOAST MESSAGES AT THE TOP RIGHT OF THE SCREEN.
import {ToastrService} from "ngx-toastr"

// DECLARING THIS AS A PIPE TO GET A CLICKABLE LINK FROM A URL
@Pipe
({
	name: 'findReplace'
})

// DECLARING THE TransformLink CLASS
export class TransformLink implements PipeTransform
{
	private toastr: ToastrService
	// DEFINING THE CONSTRUCTOR OF THE CLASS
	constructor (
		private injector:Injector,
		// toastr IS THE ALIAS OF THE ToastrService, WE WILL USE THIS toastr ALIAS TO CALL THE FUNCTIONS OF ToastrService CLASS, WHICH
		
		// helper IS THE ALIAS OF THE HelperService, WE WILL USE THIS helper ALIAS TO CALL THE FUNCTIONS OF HelperService CLASS, TO
		private helper: HelperService
	) 
	{
	}

	// HERE WE ARE TAKING A STRING INPUT AND CONVERTING ANY DOMAIN URL CLICKABLE LINK IN THE STRING,
	// THEN RETURNING THE STRING
	transform(text: string): any
	{
		// SPLITTING THE RECEIVED TEXT BY SPACE TO GENERATE AN ARRAY
		let textArray = text.split(' ');

		// LOOP THROUGH EACH ELEMENT OF THE ARRAY
		for (var i = 0; i < textArray.length; ++i)
		{
			// REGULAR EXPRESSION TO CHECK THE URL PATTERN
			let urlRegex = /(ftp|http|https|www):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
			
			// CHECK IF THE CURRENT ARRAY ELEMENT IS A VALID URL
			let isUrl = urlRegex.test(textArray[i]);

			// IF CURRENT ARRAY ELEMENT IS A VALID URL. . .
			if (isUrl)
			{
				//. . .SET THE HREF VALUE TO BE USED IN THE ANCHOR TAG
				var href = textArray[i];

				// IF CURRENT ARRAY ELEMENT DOESN'T HAVE https://
				if (!textArray[i].match('^https?:\/\/'))
				{
					// ADD http:// BEFORE THE HREF VALUE
					href = 'http://' + href;
				}

				// UPDATE THE ARRAY ELEMENT AS AN ANCHOR TAG 
			    textArray[i] = '<a href="'+href+'" target="_blank">' + textArray[i] + '</a>';
			}
			else
			{
				// GET THE EXTENSION PART OF THE INPUT IF IT WAS PRESENT,
				// IT COULD BE A DOMAIN EXTENSION OR
				// LOOKS LIKE INCOMPLETE COMMENT
				let extensionPattern = /.[aA-zZ]{2,3}$/; // TBD
				let matchingStr = textArray[i].match(extensionPattern); // TBD

				// IF THE EXTENSION MATCHES A DOMAIN NAME, WE WILL
				let domainNames =
				[
					".com",
					".net",
					".org",
					".us",
					".in"
				];

				// IF WE HAVE AN EXTENSION AND EXTENSION VALUE MATCH WITH THE DOMAIN NAMES. . .
				if (matchingStr && domainNames.indexOf(matchingStr[0].toLowerCase()) >= 0)
				{
					//. . .SET THE HREF VALUE TO BE USED IN THE ANCHOR TAG
					var href = textArray[i];

					// IF CURRENT ARRAY ELEMENT DOESN'T HAVE https://
					if (!textArray[i].match('^https?:\/\/'))
					{
						// ADD http:// BEFORE THE HREF VALUE
						href = 'http://' + href;
					}

					// UPDATE THE ARRAY ELEMENT AS AN ANCHOR TAG 
				    textArray[i] = '<a href="'+href+'" target="_blank">' + textArray[i] + '</a>';
				}
			}
		}

		// JOIN THE ARRAY ELEMENTS AGAIN WITH SPACE AND RETURN
		return textArray.join(' ');
	}
}
