<div class="modal-header">
	<button class="btn close-button" (click)="dialogRef.close()">
		<div class="close-container">
			<div class="leftright"></div>
			<div class="rightleft"></div>
		</div>
	</button>
</div>
<div class="modal-body">
	<div class="modal-logo-icon" (click)="redirectHome()">
		<img class="logo" src="./assets/images/logo.svg" alt="logo"/>
		<div class="logo-text">
			LetsKinect
		</div>
	</div>
	
	<div class="modal-text">
		<ul class="links mb-2">
			<li class="nav-item">
				<a class="nav-link active" (click)="redirectHome()" aria-current="page" href="#">Home</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" (click)="dialogRef.close()" [routerLink]="['/about']">About Us</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" (click)="dialogRef.close()" [routerLink]="['/privacy']">Privacy</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" (click)="dialogRef.close()" [routerLink]="['/contact']">Contact Us</a>
			</li>
			<li class="nav-item" *ngIf="helper.userBundleData">
				<a class="nav-link" (click)="dialogRef.close()" [routerLink]="['/lkphone']">Account</a>
			</li>
			<li class="nav-item" *ngIf="helper.isUserLoggedIn">
				<a class="nav-link" (click)="logOut()">Sign Out</a>
			</li>
			<li class="nav-item" *ngIf="!helper.isUserLoggedIn">
				<a class="nav-link" (click)="goToLogin()">Sign In</a>
			</li>
		</ul>
	</div>
	
	<div class="head-r-nav menu-avatar-continer last-menu-item d-flex align-items-center" *ngIf="helper.isUserLoggedIn">
		<div class="avatar {{helper.deviceType}} me-2" [ngStyle]="{color: helper.screenNameFontColor}" *ngIf="helper.isUserLoggedIn">
			<app-editable
					(click)="dialogRef.close()"
					(keyup.enter)="helper.updateUserScreenName($event, 'top');"
					label="username"
					(change)="helper.updateUserScreenName($event, 'top');"
					[(ngModel)]="helper.userscreenname"
					[required]="true" type="text" position="top">
			</app-editable>
		</div>
		<img class="avatar-image" *ngIf="helper.isUserLoggedIn" src="{{userAvatar}}" alt="profile-picture"/>
	</div>
	
	<div class="description">
		<span> Download the free LetsKinect App and let’s Turn Your Smartphone Into a Smart Phone! </span>
	</div>
	
	<div class="btn-group mt-5">
		<a target="_blank" href="https://play.google.com/store/apps/details?id=com.lkphone_mobile_react">
			<button class="btn store-btn-group">
				<img src="./assets/images/google-logo-white.svg"/>
				<span>
					<small class="google_store">GET IT ON</small>
					Google Play
				</span>
			</button>
		</a>
		
		<a target="_blank" href="https://apps.apple.com/in/app/lk-phone/id1546998724">
			<button class="btn store-btn-group">
				<img src="./assets/images/apple-store-white.svg"/>
				<span>
					<small class="google_store">Download on the</small>
					App Store
				</span>
			</button>
		</a>
	</div>

</div>
