<div class="k-overlay" *ngIf="showPopup"></div>

<!-- DROPDOWN CHANGE POPUP -->
<kendo-dialog title="" class="global-dialog" *ngIf="showPopup && popupData.type == 'dropdown_change'"
	[minWidth]="250" [width]="520">
	<div class="py-2">

		<div class="w-100 d-flex justify-content-end close-icon">
			<img class="tick" (click)="cancelChange()" [src]="'../../../../assets/images/close_icon_blue.svg'" />
		</div>

		<div class="px-3 box-feature">
			<p class="title">
				{{popupData.title}}
			</p>

			<p class="text mar-b-5" [innerHtml]="popupData.message"></p>
		</div>
		<div class="line"></div>

		<div class="px-3">
			<div class="d-flex justify-content-center mt-3">
				<a kendoButton size="large" look="clear" class="btn btn-primary me-3"
					(click)="confirmChange()">CONFIRM</a>
				<a kendoButton size="large" look="clear" class="btn btn-primary" (click)="cancelChange()">CANCEL</a>
			</div>
		</div>

	</div>
</kendo-dialog>

<!--ENITYT SCAN IMAGE POPUP -->
<kendo-dialog title="" class="global-dialog" (close)="closePopup()"
	*ngIf="showPopup && popupData.type == 'scanImage_feature'" [minWidth]="250" [width]="800">
	<div class="py-2">
		<div class="w-100 d-flex justify-content-end close-icon">
			<img class="tick" (click)="closePopup()" [src]="'../../../../assets/images/close_icon_blue.svg'" />
		</div>

		<div class="px-3 box-feature">
			<div class="entity-profile-wrapper">
				<div class="w-100 text-center form-tagline my-3">
					<div class="description-text">
						Add more people to your entity by giving this invitation link or QR+ / ScanKinect code
					</div>
				</div>

				<div class="d-flex justify-content-center mt-5 mb-5">
					<div class="link-container">{{link}}</div>
					<div class="copy-link-button d-flex cursor-pointer" (click)="copyToClipboard()">
						<div class="align-self-center">Copy Link</div>
						<img src="/assets/images/copy-green.svg" />
					</div>
				</div>

				<div class="d-flex justify-content-center mt-5 mb-5">
					<div class="qr-code">
						<div id="qr-image" [ngStyle]="qrOuterCSS">
							<qrcode [margin]="1" [qrdata]="link" [width]="qrSize"></qrcode>
							<img class="qr-logo" [ngStyle]="qrLogoCSS" src="./../../../assets/images/qrplus.png" />
						</div>

						<div class="download">
							<div class="text" (click)="downloadQrCode()">
								Download
							</div>
							<img src="/assets/images/download_icon.svg" class="icon" />
						</div>
					</div>
					<div>
						<div id="scan-image">
							<img src="{{popupData.scanImage}}" class="scan-images" />
						</div>

						<div class="download">
							<div class="text" (click)="downloadScanImage()">
								Download
							</div>
							<img src="/assets/images/download_icon.svg" class="icon" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="line"></div>

		<div class="px-3 ">
			<div class="video-wrapper d-flex justify-content-center align-items-center mt-2 py-1">
				<span class="text" (click)="closePopup()">Okay</span>&nbsp;<img src="/assets/images/green-tick.svg"
					class="icon" />
			</div>
		</div>
	</div>
</kendo-dialog>

<!-- PREMIUM FEATURE POPUP -->
<kendo-dialog title="" class="global-dialog premium_feature" (close)="closePopup()"
	*ngIf="showPopup && popupData?.type == 'premium_feature'" [width]="490">
	<div class="py-2">

		<div class="w-100 d-flex justify-content-end close-icon">
			<img class="tick" (click)="closePopup()" [src]="'../../../../assets/images/close_icon_blue.svg'" />
		</div>

		<div class="px-3 box-feature">
			<p class="title">
				Premium Feature
			</p>

			<p class="text mar-b-5" *ngIf="!popupData.content || popupData.content == ''">
				To unlock your <b>{{popupData?.title}}</b> drop down, you can either pay with {{kips}} kips, or upgrade
				your account. For more information, please see the video below.
			</p>
			<p class="text mar-b-5" *ngIf="popupData.content || popupData.content != ''"
				[innerHTML]="popupData.content">
			</p>
			<p class="text mar-b-5 message-text">
				<span class="{{pupupActionMessageClass}}" [innerHTML]="pupupActionMessage"></span>
			</p>
		</div>
		<div class="line"></div>

		<div class="px-3 ">
			<div class="d-flex justify-content-center mt-3">
				<a kendoButton size="large" look="clear" class="btn btn-primary me-3" (click)="unlockFeature()">Unlock
					with {{kips}} Kips</a>
				<a kendoButton size="large" look="clear" class="btn btn-primary" (click)="navigateToUpgrade()">Upgrade
					Now</a>
			</div>

			<div class="video-wrapper d-flex justify-content-center align-items-center mt-2 py-1">
				<img src="/assets/images/pouseicon.svg" class="me-1 " />
				<span class="text" (click)="showKipsInfoPopup()">Show me options to get more kips for free.</span>
			</div>
		</div>

	</div>
</kendo-dialog>

<!-- KIPS INFO POPUP -->
<kendo-dialog title="" class="global-dialog premium_feature" (close)="closePopup()"
	*ngIf="showPopup && popupData?.type == 'kips_info'" [width]="490">
	<div class="py-2">

		<div class="w-100 d-flex justify-content-end close-icon">
			<img class="tick" (click)="closePopup()" [src]="'../../../../assets/images/close_icon_blue.svg'" />
		</div>

		<div class="px-3 box-feature">
			<p class="title kips-info-title">
				Kips: What they do and how to get them
			</p>
			<img class="video-kips-popup" src="/assets/images/video-kips-popup.png" />
		</div>
		<div class="line"></div>

		<div class="px-3 ">

			<div class="video-wrapper d-flex justify-content-center align-items-center mt-1 py-1">
				<p class="kips-info-text">Get more kips easily by upgrading your account.</p>
			</div>

			<div class="d-flex justify-content-center mb-1">
				<a kendoButton size="large" look="clear" class="btn btn-primary me-3">Upgrade Now</a>
			</div>
		</div>

	</div>
</kendo-dialog>

<!-- ORDER INVISIPHONE POPUP -->
<kendo-dialog title="" class="global-dialog" *ngIf="showPopup && popupData.type == 'order_invisiphone'"
	[minWidth]="250" [width]="520">
	<div class="py-2">

		<div class="w-100 d-flex justify-content-end close-icon">
			<img class="tick" (click)="closePopup()" [src]="'../../../../assets/images/close_icon_blue.svg'" />
		</div>

		<div [@flipState]="(flip?'active':'inactive')" class="tp-box">
			<div class="dialog" *ngIf="currentStep == 1">
				<div class="px-3 box-feature">
					<p class="title">Get your Invisiphone!</p>
					<p class="text">So versatile: get a 2nd phone line which you can use for business and social calls
						at a <br>
						fraction of the price. Keep your real phone number invisible. Use in web forms, <br>
						Craigslist, Reddit, Discord, Tinder, and many other social apps. Get your US calls <br>
						overseas and never miss a call. Route your calls to friends. Automatically send people <br>
						meeting links, etc. Tons of other functionality, too!</p>
				</div>
				<div class="line"></div>

				<div class="action">
					<div class="button" (click)="navigateToUpgrade()">Order your Invisiphone</div>
					<div class="hint" (click)="gotoStep(2)">
						How to Order your Invisiphone <img src="../../../../../assets/images/green-eye.svg" alt="">
					</div>
				</div>
			</div>

			<div class="dialog" *ngIf="currentStep == 2">
				<div class="px-3">
					<p class="title">How to Order your Invisiphone</p>
					<div class="description">
						<img src="../../../../../assets/images/how-to-invisiable.png" alt=""
							class="how-to-order-invisiphone" />
					</div>
				</div>
				<div class="line"></div>

				<div class="action">
					<div class="hint" (click)="gotoStep(1)">
						Get your Invisiphone <img src="../../../../../assets/images/green-eye.svg" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</kendo-dialog>

<!-- UPGRADE POPUP -->
<kendo-dialog title="" class="global-dialog upgrade_invisiphoneg" (close)="closePopup()"
	*ngIf="showPopup && popupData.type == 'upgrade_invisiphone'" [minWidth]="250" [width]="900">
	<div class="py-2">
		<div class="w-100 d-flex justify-content-end close-icon">
			<img class="tick" (click)="closePopup()" [src]="'../../../../assets/images/close_icon_blue.svg'" />
		</div>

		<div class="box-feature" *ngIf="!isShowPlanLists">
			<div class="px-3">
				<p class="title">
					Renew your current plan
				</p>
			</div>
			<app-upgrade-plan-popup [planData]="currentPlan"></app-upgrade-plan-popup>
			<div class="readmore" *ngIf="isInvisiphoneExists">Change Plan <img class="eye" src="../../../assets/images/right-arrow-circle.svg"
				(click)="showAllPlanLists()" /></div>
		</div>

		<div class="box-feature dialog" *ngIf="isShowPlanLists">
			<div class="px-3">
				<p class="title">
					Choose your plan
				</p>
			</div>
			
			<div class="switch-container">
				<span class="me-2">Monthly</span>
				<kendo-switch class="attoswitch" (valueChange)="onSwitchChange($event)" [(ngModel)]="isMonthlyListsView"></kendo-switch>
				<span class="ms-2">Annually</span>
			</div>
			<div class="row no-margin">
				<div class="col-sm-6" *ngFor="let plan of currentBundleData">
					<app-upgrade-plan-popup [planData]="plan"></app-upgrade-plan-popup>
				</div>
			</div>
			<div class="readmore"  *ngIf="isInvisiphoneExists">Back <img class="eye" src="../../../assets/images/right-arrow-circle.svg"
				(click)="backToCurrentplan()" />
			</div>
		</div>


	</div>
</kendo-dialog>

<!-- ENTITY PLAN DETAILS POPUP -->
<kendo-dialog title="" class="global-dialog" *ngIf="showPopup && popupData.type == 'entity_plan_details'"
		[width]="900">
	<div class="py-2 entity-types-dialog">
		<div class="w-100 d-flex justify-content-end close-icon">
			<img class="tick" (click)="closePopup()" [src]="'../../../../assets/images/close_icon_blue.svg'" />
		</div>
		<div class="sub-container">
			<img src="assets/images/check.png" alt="">
			
			<div class="tag-line">
				As a part of your free plan, you are allowed to create 3 entities. <br>
				Please see the <span>Overview</span> for more information.
			</div>
			
			<div class="d-flex justify-content-between flex-wrap">
				<app-dialog-card type="paid" heading="Company"
						subtitle="This entity allows you to add, edit, and manage the phones of your employees, with many advanced features . . ."
						bottomLine="Member limits are controlled by the plan."></app-dialog-card>
				<app-dialog-card type="free" heading="Church" subtitle="A religious, non-profit organization."
						bottomLine="250 members allowed on the free plan."></app-dialog-card>
				<app-dialog-card type="free" heading="Neighborhood" subtitle="Connect with your fellow neighbors."
						bottomLine="500 members allowed on the free plan."></app-dialog-card>
				<app-dialog-card type="free" heading="Chamber of Commerce"
						subtitle="Available to accredited chamber of commerce."
						bottomLine="500 members allowed on the free plan."></app-dialog-card>
				<app-dialog-card type="free" heading="Club" subtitle="Create your club for people to join in."
						bottomLine="25 members allowed on the free plan."></app-dialog-card>
				<app-dialog-card type="free" heading="Entity"
						subtitle="Associate your extension with an entity and create a broadcast group."
						bottomLine="25 members allowed on the free plan."></app-dialog-card>
			</div>
		</div>
		<div class="action">
			<div class="ok-button">
				<div>Okay </div><img src="assets/images/green-tick.svg" alt="ok">
			</div>
		</div>
	</div>
</kendo-dialog>

<!-- TRADE IDEA SUBSCRIBE POPUP -->
<kendo-dialog title="" class="global-dialog"  *ngIf="showPopup && popupData.type == 'tradeidea_subscribe'" [minWidth]="250" [width]="520">
	<div class="py-2">
		<div class="w-100 d-flex justify-content-end close-icon">
			<img class="tick" (click)="closePopup()" [src]="'../../../../assets/images/close_icon_blue.svg'" />
		</div>
		<div class="px-3 box-feature">
			<p class="title">
				Subscribe Required
			</p>
			<p class="text mar-b-5">To unlock these trades, you need a subscription.</p>
		</div>
		<div class="line"></div>
		<div class="px-3">
			<div class="d-flex justify-content-center mt-3">
				<a kendoButton size="large" look="clear" class="btn btn-primary me-3"
				>Subscribe Now</a>
			</div>
		</div>
	</div>
</kendo-dialog>

<!-- TRADE KIP UNLOCK POPUP -->
<kendo-dialog title="" class="global-dialog unlocktrade" (close)="closePopup()"
		*ngIf="showPopup && popupData?.type == 'unlocktrade'" [width]="490">
	<div class="py-2">
		<div class="w-100 d-flex justify-content-end close-icon">
			<img class="tick" (click)="closePopup()" [src]="'../../../../assets/images/close_icon_blue.svg'" />
		</div>
		
		<div class="px-3 box-feature">
			<p class="title">
				{{popupData?.title}}
			</p>
			
			<p class="text mar-b-5" *ngIf="!popupData.content || popupData.content == ''">
				Confirming the trade unlock with {{kips}} kips, Please confirm your approval.
			</p>
		</div>
		<div class="line"></div>
		
		<div class="px-3 ">
			<div class="d-flex justify-content-center mt-3">
				<a kendoButton size="large" look="clear" class="btn btn-primary me-3" (click)="unlockTrade()">Unlock
					for {{kips}} Kips</a>
			</div>
		</div>
	
	</div>
</kendo-dialog>
