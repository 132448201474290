<section class="main-layout-container position-relative">
	<div class="container-wrapper">
		<div class="filter-content bg-white">
			<div class="top-bar filters-bar pull-right">
				<ng-container>
					<app-search [config]="filterConfig" (onChangeSwitch)="onToggleOverview ($event)"></app-search>
				</ng-container>
			</div>
		</div>
		<div class="main-wrapper" *ngIf="currentView === 'dashboard'" @enterExitLeft>
			<div class="row justify-content-center">
				<div class="col-md-offset-2 col-md-8 col-sm-12 main-content">
					<div class="content-wrapper" >
						<div class="header">
							<div class="customTab-kendo-strip">
								<kendo-tabstrip tabAlignment="center" id="tabs-wrapper" height="36"
										[scrollable]="true">
									<ng-container *ngFor="let tab of avatarTabs; let i=index">
										<kendo-tabstrip-tab [selected]="tab.active">
											<ng-template kendoTabTitle>
												<div [routerLink]="tab.route"
														class="tab-item k-item {{(tab.active)? 'active': ''}}">
													<a><span>{{tab.name}}</span></a>
												</div>
											</ng-template>
										</kendo-tabstrip-tab>
									</ng-container>
								</kendo-tabstrip>
							</div>
						</div>
						
						<div class="avatarmanage-content">
							<router-outlet></router-outlet>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content-wrapper bg-white" *ngIf="currentView === 'overview'" @enterExitRight>
			<div class="row">
				<div class="col-sm-12">
					<app-dashboard-overview></app-dashboard-overview>
				</div>
			</div>	
		</div>
	</div>
</section>
