import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {HelperService} from '../../../services/helper.service';
import {DialogService} from '../../../services/dialog.service';
import {Router} from '@angular/router';
import {DialogRef} from '@progress/kendo-angular-dialog';
import {LoginService} from '../../../services/login.service';
import {environment} from '../../../../environments/environment';

@Component ({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit
{
	
	public userAvatar: string = './assets/images/user.svg';
	// URL OF THE NODE SERVER.
	public apiURL = environment.apiURL;
	
	constructor (
		public dialogRef: DialogRef,
		public helper: HelperService,
		private dialogService: DialogService,
		private router: Router,
		public login: LoginService,
		public cdref: ChangeDetectorRef
	)
	{
	}
	
	ngOnInit ()
	{
		// SUBSCRIBE TO AVATAR CHANGE AT TOP BAR.
		this.helper.processSiteAvatar.subscribe ((val) =>
		{
			// IF THE USER IS LOGGED IN.
			if (this.helper.isUserLoggedIn)
			{
				if (this.login.user.urlProfileimg != '')
				{
					let imageurl = this.apiURL + '/' + this.login.user.urlProfileimg + '?unique=' + Date.now ();
					this.helper.checkImage (imageurl).then ((imageExist) =>
					{
						if (imageExist)
						{
							this.userAvatar = imageurl;
						}
						else
						{
							this.userAvatar = './assets/images/user-green.svg';
						}
					});
				}
				else
				{
					this.userAvatar = './assets/images/user-green.svg';
				}
			}
			else
			{
				this.userAvatar = './assets/images/user.svg';
			}
		});
	}
	
	// THIS FUNCTION IS CALLED FOR TO CONFIRM (POPUP WILL APPEAR) USER TO LEAVING OR STAY IN THE ROOM
	redirectHome ()
	{
		// CHECK IS THIS ROOM PAGE.
		if (this.helper.isRoomPage)
		{
			const message = this.helper.sitemessages['MSG-ROOM-USER-LEAVING'];
			console.log ('message', message);
			// SHOW KENDO DIALOG TO CONFIRM USER WAHTS TO STAY IN ROOM OR LEAVE
			this.dialogService.showDialog ('contentPopupLeavingRoom', 'You are leaving!', '', 'Do you want to leave the room?');
		}
		else
		{
			// NAVIGATE USER TO HOME PAGE
			this.router.navigate (['/']);
			this.dialogRef.close ();
		}
	}
	
	// GO TO LOGIN
	public goToLogin ()
	{
		this.dialogRef.close ();
		this.router.navigate (['/login']);
	}
	
	public logOut ()
	{
		this.login.logoutUser ().then (val =>
		{
			this.dialogRef.close ();
			this.redirectHome ();
			this.cdref.detectChanges (); // DETECTING ANY CHANGES IN ANGULAR VIEW.
			
		});
	}
	
}
