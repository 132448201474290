<section class="main-layout-container position-relative">
	<div class="container-wrapper">
		<div class="filter-content bg-white">
			<div class="top-bar filters-bar pull-right">
				<ng-container>
					<app-search [config]="config" (onChangeSwitch)="onToggleOverview ($event)"></app-search>
				</ng-container>
			</div>
		</div>
		<div class="main-wrapper" *ngIf="currentView === 'dashboard'" @enterExitLeft>
			<div class="row">
				<div class="col-md-4 col-sm-12 intro-content mt-2">
					<div *ngIf="currentRoute === 'tradeideafree'">
						<span class="heading">Congratulations, here are the trades you’ve unlocked for free!</span>
						<div class="intro-paragraph">
							On the first tab on right are all the free trades you’ve unlocked based on your rank. Feel
							free to learn from them. Remember, these trade ideas are not investment advice, they are given
							to you for learning purposes only.
						</div>
						<div class="intro-paragraph">
							On the second tab are trades from all other traders, which you can unlock for kips.
						</div>
						<div class="feature-list">
							<div class="feature-child">
								<img src="/assets/images/trade/checked.svg" class="list-icon" />
								<p class="list-text">On the right, we present trades from traders that are your
									equivalent, for free.
								</p>
							</div>
							<div class="feature-child">
								<img src="/assets/images/trade/wallet.svg" class="list-icon" />
								<p class="list-text">We unlock the trades for the three traders above you and below you.</p>
							</div>
							<div class="feature-child">
								<img src="/assets/images/trade/stats.svg" class="list-icon" />
								<p class="list-text">Level up and see better trades! <a>More info.</a></p>
							</div>
							<div class="feature-child">
								<img src="/assets/images/trade/setting.svg" class="list-icon" />
								<p class="list-text">Send your trades automatically to Alpaca, we manage them for you!
									<a>More info.</a>
								</p>
							</div>
						</div>
					</div>
					<div *ngIf="currentRoute === 'tradeideakip'">
						<span class="heading">
							Boost Your Learning Progress By Unlocking Trades From The Best . . .
						</span>
						<div class="intro-paragraph">
							On the right side are the trades you can unlock for kips. To learn all about your options
							on this page, and about how kips work and how you can acquire more of them, please use the
							<b>View/Overview</b> switch above. And remember, these trade ideas are not investment
							advice,	they are there for your learning purposes only.
						</div>
						<div class="feature-list">
							<div class="feature-child">
								<img src="/assets/images/trade/checked.svg" class="list-icon" />
								<p class="list-text">Filter the trades</p>
							</div>
							<div class="feature-child">
								<img src="/assets/images/trade/setting.svg" class="list-icon" />
								<p class="list-text">Unlock the ones you have kips for</p>
							</div>
							<div class="feature-child">
								<img src="/assets/images/trade/wallet.svg" class="list-icon" />
								<p class="list-text">Lower the kips you need with a <u>subscription</u></p>
							</div>
							
						</div>
					</div>
				</div>
				<div class="col-md-8 col-sm-12 main-content">
					<div class="content-wrapper">
						<div class="header">
							<div class="customTab-kendo-strip">
								<kendo-tabstrip tabAlignment="center" id="tabs-wrapper" height="36" [scrollable]="true">
									<ng-container *ngFor="let tab of tradeIdeaTabs; let i=index">
										<kendo-tabstrip-tab [selected]="currentRoute == tab.route">
											<ng-template kendoTabTitle>
													<div [routerLink]="tab.route"
														class="tab-item k-item {{(currentRoute == tab.route)? 'active': ''}}">
														<a><span>{{tab.name}}</span></a>
													</div>
											</ng-template>
										</kendo-tabstrip-tab>
									</ng-container>
								</kendo-tabstrip>
							</div>
						</div>
						
						<div class="tradeidea-content position-relative mt-3">
							<router-outlet></router-outlet>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main-wrapper bg-white" *ngIf="currentView === 'overview'" @enterExitRight>
			<div class="row">
				<div class="col-sm-12">
					<app-dashboard-overview></app-dashboard-overview>
				</div>
			</div>	
		</div>
	</div>
</section>
