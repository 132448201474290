<div class="row">
  <div class="col-12">
    <div
      [attr.aria-label]="message.name+' asked you question'"
      class="custom-title">
      {{ message.name + " asked the Question" }}
    </div>
    <div *ngIf="message" role="alert" aria-live="polite"
      class="custom-message"
    >
      <strong class="text-danger">{{message.question}} ?</strong>
      <div *ngFor="let answer of message.answers">
        <button (click)="answerQuestion(answer, username, message)"
          class="btn pl-2 btn-sm btn-dark btn-xs">{{answer}}</button>
      </div>
      
      <div class="row" *ngIf="message.userType !== 'host'">
        <div class="btn-group-sm offset-md-2 col-md-8">
          <button class="btn-warning btn-xs" (click)="ignoreQuestion()">Ignore</button>
          <button class="btn-primary btn-xs" (click)="shareQuestion(message)">Share</button>
        </div>
      </div>
    </div>
  </div>
</div>
