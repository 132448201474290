<section class="main-layout-container bg-white position-relative">
	<div class="simple-content">
		<div class="filter-content">
			<div class="top-bar filters-bar pull-right">
				<ng-container>
					<app-search [config]="topbarConfig" (onChangeSwitch)="onToggleOverview ($event)"></app-search>
				</ng-container>
			</div>
		</div>
		<div class="tradehistory-container" *ngIf="currentView === 'dashboard'" @enterExitLeft>
			<div class="tradefilter-content">
				<div class="top-bar d-flex justify-content-between mb-4">
					<ng-container>
						<form class="filters-form px-2 d-flex" [formGroup]="filterform" >
							<div class="filters-bar">
								<input kendoTextBox 
								formControlName="keyword" 
								class="input-text first-item" 
								placeholder="Search . . ."
								[style.width.px]="160"
								(keyup)="inputKeyup()"
								/>

								<div class="dropdownlist" [style.width.px]="200">
									<kendo-dropdownlist 
										[style.width.px]="180" 
										[data]="tradeAvatarDataSource"
										class="boxed-border" 
										textField="namefull" 
										valueField="avatar_id"
										[valuePrimitive]="true" 
										formControlName="tradeAvatar"
										(valueChange)="getTradeList()"
									></kendo-dropdownlist>
								</div>	

								<div class="dropdownlist" [style.width.px]="180">
									<kendo-dropdownlist 
										[style.width.px]="170" 
										[data]="tradeStatusSource"
										class="boxed-border" 
										textField="text"
										valueField="value"
										[valuePrimitive]="true" 
										formControlName="tradeStatus"
										(valueChange)="getTradeList()"
									></kendo-dropdownlist>
								</div>

								<div class="dropdownlist" [style.width.px]="190">
									<kendo-dropdownlist 
										[style.width.px]="180" 
										[data]="strategyTypeSource"
										class="boxed-border" 
										textField="tradestrategy" 
										valueField="tradestrategy_id"
										[valuePrimitive]="true" 
										formControlName="strategyType"
										(valueChange)="getTradeList()"
									></kendo-dropdownlist>
								</div>

								<div class="dropdownlist" [style.width.px]="190">
									<kendo-dropdownlist 
										[style.width.px]="180" 
										[data]="securityTypeSource"
										class="boxed-border" 
										textField="text" 
										valueField="value"
										[valuePrimitive]="true" 
										formControlName="securityType"
										(valueChange)="getTradeList()"
									></kendo-dropdownlist>
								</div>

								<div class="dropdownlist" [style.width.px]="160">
									<kendo-dropdownlist 
										[style.width.px]="140" 
										class="k-dropdown" 
										[data]="lastModifiedDatasource" 
										[textField]="'text'" 
										[valueField]="'value'" 
										[valuePrimitive]="true" 
										formControlName="lastmodified"
										(valueChange)="getTradeList()"
									></kendo-dropdownlist>
								</div>
								
								<button (click)="resetFilter()" kendoButton class="btn blue-button" [style.height.px]="32">Reset</button>
							</div>
						</form>
					</ng-container>
				</div>
			</div>
			<div class="main-content">
				<div id="tradeGrids" class="listManagerTableWrapper kendo-grid-style">
					<kendo-grid [kendoGridBinding]="tradelist" class="k-gridWrapper" kendoGridSelectBy="id"
						scrollable="none">
						<kendo-grid-column field="PNL" title="P&L">
							<ng-template kendoGridCellTemplate let-dataItem>
								<div class="pandl">{{ dataItem.PNL }}</div>
							</ng-template>
						</kendo-grid-column>
						<kendo-grid-column field="avatar_name" title="Avatar">
							<ng-template kendoGridCellTemplate let-dataItem>
								<div class="">{{ dataItem.avatar_name }}</div>
							</ng-template>
						</kendo-grid-column>
						<kendo-grid-column field="security" title="Security">
							<ng-template kendoGridCellTemplate let-dataItem>
								<div class="security">{{ dataItem.security }}</div>
							</ng-template>
						</kendo-grid-column>
						<kendo-grid-column field="" title="Price">
							<ng-template kendoGridCellTemplate let-dataItem>
								<span class="">Price</span>
							</ng-template>
						</kendo-grid-column>
						<kendo-grid-column field="strategy_type" title="Strategy Type">
							<ng-template kendoGridHeaderTemplate let-dataItem>
								<span class="">{{dataItem.title}}</span>
							</ng-template>
						</kendo-grid-column>
						<kendo-grid-column field="trade_status" title="Trade Status" [headerClass]="tradeHeaderStyle">
							<ng-template kendoGridCellTemplate let-dataItem>
								<span [class]="getTradeStatusColorClass(dataItem.trade_status)">{{ dataItem.trade_status
									}}</span>
							</ng-template>
						</kendo-grid-column>

						<kendo-grid-column field="date_created" title="Date Created">
							<ng-template kendoGridHeaderTemplate let-dataItem>
								<span class="">{{dataItem.title}}</span>
							</ng-template>
						</kendo-grid-column>
						<kendo-grid-column field="trade_entry_description" title="Trade Entry Description">
							<ng-template kendoGridHeaderTemplate let-dataItem>
								<span class="">{{dataItem.title}}</span>
							</ng-template>
						</kendo-grid-column>
						<kendo-grid-column field="action" title="Action">
							<ng-template kendoGridCellTemplate let-dataItem>
								<div class="btn-block">
									<div class="btn-child">Edit</div>
									<div class="btn-child" (click)="onCancel(dataItem)">Cancel</div>
								</div>
							</ng-template>
						</kendo-grid-column>
					</kendo-grid>
				</div>
				<div class="w-100 pager-wrapper right-pager">
					<kendo-datapager class="theme-pager listmanager-pager" 
						[pageSize]="gridPageSize"
						[skip]="gridSkip" 
						[total]="gridTotal" 
						[buttonCount]="4" 
						[info]="false"
						[pageSizeValues]="false" 
						[type]="'numeric'"
						(pageChange)="onPageChange($event)"
						>
						<!--(pageChange)="onContactsPageChange($event)">-->
					</kendo-datapager>
				</div>
			</div>
		</div>
		<div class="main-wrapper bg-white overview-wrapper" *ngIf="currentView === 'overview'" @enterExitRight>
			<div class="row">
				<div class="col-sm-12">
					<app-dashboard-overview></app-dashboard-overview>
				</div>
			</div>	
		</div>
	</div>
</section>