import {Component, ChangeDetectorRef, Input} from '@angular/core';

// IMPORTING THE LK DIALOG SERVICE TO DISPLAY A KENDO DIALOG.
import {DialogService} from "../../../services/dialog.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

import {HelperService} from "../../../services/helper.service";
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ToastrService} from 'ngx-toastr';
import {Clipboard} from '@angular/cdk/clipboard';
import * as htmlToImage from 'html-to-image';
import {NgxOtpInputConfig} from 'ngx-otp-input';
import _default from "chart.js/dist/plugins/plugin.tooltip"
import callbacks = _default.descriptors.callbacks

@Component ({
	selector: 'app-global-dialog',
	templateUrl: './global-dialog.component.html',
	styleUrls: ['./global-dialog.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(90deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in'))
		])
	]
})
export class GlobalDialogComponent
{
	public popupData: any;
	public showPopup: boolean = false;
	public kips: any; // HOLDS THE NUMBER OF KIPS
	public pupupActionMessage: string = ''; // HOLDS THE FORM ACTION DISPLAY MESSAGE
	public pupupActionMessageClass: string = '';
	public apiProcessing: boolean = false;
	public flip: boolean =  false;
	public link: any = '';
	public scanImage: any = '';
	public currentStep: number = 1;
	public isInvisiphoneExists : any = false;
	public currentPlan: any;
	public callBackFunction: any;
	
	// SET QR OUTER CSS
	public qrOuterCSS: any = {width: '220px', height: '220px'};
	public qrSize: number = 212;
	public qrLogoCSS: any = {width: '45px', height: '45px', top: '85px', left: '85px'};
	public isShowPlanLists: boolean = false;
	public currentBundleData: any = [];
	public bundleList: any = [];
	public bundleTexts: any = [];
	public isMonthlyListsView : any = false;
	public currentPlanId: any = 0;
	// CLASS CONSTRUCTOR, THIS WILL BE FIRST FUNCTION TO BE EXECUTED WHEN THIS CLASS LOADS.
	// HERE WE WILL TELL ANGULAR TO INJECT A DEPENDENCY BY SPECIFYING A CONSTRUCTOR
	// PARAMETER WITH THE DEPENDENCY TYPE.
	constructor
	(
		// IMPORTING THE DIALOG SERVICE.
		public dialogService: DialogService,
		private router: Router,
		public helper: HelperService,
		private cdr: ChangeDetectorRef,
		private toastr: ToastrService,
		private clipboard: Clipboard,
	)
	{
	}

	ngOnInit ()
	{
		this.pupupActionMessage = '';
		this.pupupActionMessageClass = '';
		this.dialogService.dialogObserver.subscribe ((argString: string) =>
		{
			this.pupupActionMessage = '';
			this.pupupActionMessageClass = '';
			const argObj = JSON.parse (argString);
			console.log ('dialog subscribed', argObj);
			this.popupData= argObj;
			this.cdr.detectChanges();
			this.kips = this.popupData.kips;
		
			if (this.popupData.showPopup && this.popupData.type)
			{
				// CHECK IF THERE IS A CALLBACK FUNCTION IN THE RECEIVED MESSAGE AND IT'S A FUNCTION
				//if (this.popupData && this.popupData.callback2)
				//{
					// Assign the callback function to this.callBackFunction
					this.callBackFunction = this.popupData.callback2;
				//}
				///else
				//{
				//	console.log ("function not get");
				//}
				
				if(this.popupData.type == 'scanImage_feature')
				{
					this.link = this.popupData.link;
					this.showPopup = true;
				}
				else if(this.popupData.type == 'upgrade_invisiphone')
				{
					this.getBundleList();
				}
				else
				{
					this.showPopup = true;
				}
			}
			else
			{
				this.showPopup = false;
			}
		});
	}

	// GET BUNDLE LIST.
	getBundleList ()
	{
	}

	loadBundleData()
	{
			this.bundleList.map((item)=>{
				let bundleText = this.bundleTexts.filter((x:any)=>x['lkp-bundle_id'] === item['lkp-bundle_id']);
				item.bundleText = bundleText;
			})
			this.currentBundleData = this.bundleList.filter ((item: any) => item.bundletype === 'INVISIPHONE');



			if (this.helper.selectedLKPhone['lkp-bundle_id'] != 'FREE')
			{
				this.isInvisiphoneExists = true;
				this.currentPlanId = this.helper.selectedLKPhone['lkp-bundle_id'];
				this.currentPlan = this.currentBundleData.find ((item: any) => item['lkp-bundle_id'] === this.currentPlanId);
				this.currentPlan.isCurrentPlan = true;
			}
			else
			{
				this.isInvisiphoneExists = false;
				this.isShowPlanLists = true;
			}

		this.currentBundleData.map((item)=>{
			if(!this.isMonthlyListsView)
			{
				item.price = item.bundlebasemonthlyprice;
			}else{
				item.price = item.bundlebaseannualprice;
			}

			if(this.currentPlanId == item['lkp-bundle_id'])
			{
				item.isCurrentPlan = true;
			}else{
				item.isCurrentPlan = false;
			}

		})
		this.showPopup = true;
		console.log('currentBundleDat',this.currentBundleData);
	}

	// CLOSE ANY POPUP.
	public closePopup ()
	{
		this.showPopup = false;
		this.popupData = null;
		this.currentStep = 1;
	}

	// CONFIRM ACTION ON POPUP
	public confirmChange (data: any = null)
	{
		let poupObj: any = this.popupData;
		poupObj['button_action'] = 'confirm';
		// SET THE UNLOCK LATEST DATA IN data OBJECT.
		poupObj['data'] = data;
		this.dialogService.poupButtonObserver.next (JSON.stringify (poupObj));
		this.closePopup ();
	}

	public cancelChange ()
	{
		let poupObj = {popup_id: this.popupData.popup_id, type: this.popupData.type, button_action: 'cancel'};
		this.dialogService.poupButtonObserver.next (JSON.stringify (poupObj));
		this.closePopup ();
	}

	// THIS FUNCTION WILL BE CALLED TO CLOSE THE DIALOG AND NAVIGATE USER TO ACCOUNT/KIPS PAGE
	public showKipsInfoPopup ()
	{
		this.popupData.type = 'kips_info';
	}

	// THIS FUNCTION WILL BE CALLED TO CLOSE THE DIALOG AND NAVIGATE USER TO ACCOUNT/UPGRADE PAGE
	public navigateToUpgrade ()
	{
		this.router.navigate (['/lkphone/account/upgrade']);
		this.showPopup = false;
	}

	// THIS FUNCTION WILL BE CALLED TO UNLOCK A FEATURE
	public unlockFeature ()
	{

	}
	
	// THIS FUNCTION WILL BE CALLED TO UNLOCK A TRADE
	public unlockTrade ()
	{
		let poupObj: any = this.popupData;
		poupObj['button_action'] = 'confirm';
		
		// SET THE UNLOCK LATEST DATA IN data OBJECT.
		poupObj['response'] = true;
		this.dialogService.poupButtonObserver.next (JSON.stringify (poupObj));
		this.closePopup ();
	}

	// THIS FUNCTION HANDLE FLIP CARD
	public gotoStep (step: number)
	{
		this.flip = true;
		setTimeout(()=>{
			this.currentStep = step;
			setTimeout(()=>{
				this.flip = false;
			},500);
		},500);
	}

	// THIS FUNCTION WILL BE CALLED TO COPY LINK TO CLIPBOARD
	copyToClipboard ()
	{
		this.clipboard.copy (this.link);
		this.toastr.info (this.helper.sitemessageslkp['MSGLKP-ADMIN-GENERAL-LINKCOPIED'].message);
	}

	// THIS FUNCTION CALL FOR DOWNLOAD QR CODE IMAGE
		public downloadQrCode ()
		{
			var node = document.getElementById ('qr-image');

			htmlToImage.toPng (node).then ((dataUrl) =>
			{
				let qr64Array = dataUrl.split (',');

				// SET THE SCAN KINECT IMAGE BASE 64 DATA.
				let ImageBase64 = qr64Array[1];

				var a = document.createElement ("a"); //Create <a>
				a.href = "data:image/png;base64," + ImageBase64; //Image Base64 Goes here
				a.download = "letskinect-link-qrcode.png"; //File name Here
				a.click (); //Downloaded file
			});
		}


	// THIS FUNCTION CALL FOR DOWNLOAD SCAN IMAGE
	public downloadScanImage ()
	{
		var node = document.getElementById ('scan-image');

		htmlToImage.toPng (node).then ((dataUrl) =>
		{
			let qr64Array = dataUrl.split (',');

			// SET THE SCAN KINECT IMAGE BASE 64 DATA.
			let ImageBase64 = qr64Array[1];

			var a = document.createElement ("a"); //Create <a>
			a.href = "data:image/png;base64," + ImageBase64; //Image Base64 Goes here
			a.download = "letskinect-link-scancode.png"; //File name Here
			a.click (); //Downloaded file
		});
	}

	public showAllPlanLists()
	{
		this.isShowPlanLists = true;
	}

	public backToCurrentplan()
	{
		this.isShowPlanLists = false;
	}
	//loadBundleData
	public onSwitchChange(event)
	{
		console.log(event);
		this.loadBundleData();
	}
}
