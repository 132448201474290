import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component ({
	selector: 'delete-dialog',
	templateUrl: './deletedialog.component.html',
	styleUrls: ['./deletedialog.component.scss']
})
export class DeleteDialogComponent
{
  @Output() onConfirm = new EventEmitter;
  @Output() onCancel = new EventEmitter;
  
  @Input() isOpen:boolean;
  @Input() isLoading:boolean;

	constructor () {}

  public onDeleteCancel()
  {
    this.onCancel.emit();
  }

  public onDeleteConfirm()
  {
    this.onConfirm.emit();
  }
}
