// FOR RUNNING IN LOCAL HOST
export const environment = {
  firebase: {
    projectId: "lkphonemobile-347308",
    appId: "1:842886067836:web:e8ffe2efa710af56b57bf9",
    databaseURL: "https://lkphonemobile-347308-default-rtdb.firebaseio.com",
    storageBucket: "lkphonemobile-347308.appspot.com",
    apiKey: "AIzaSyBeW5fK3lNdxA-U3xoUJTIAT0I5s_vD4x0",
    authDomain: "lkphonemobile-347308.firebaseapp.com",
    messagingSenderId: "842886067836",
    measurementId: "G-6KLVSBJEHH",
  },
  production: false,
  webURL: "http://localhost:4201",
  mainLKURL: "https://dev.letskinect.com/",
  socket: "https://dev.letskinect.com/",
  apiURL: "https://dev.letskinect.com/server",
  // apiURL: 'http://localhost:3009',
  path: "/server/socket.io",
  mobile_socket: "https://dev.letskinect.com",
  phoneApiUrl: "https://dev.letskinect.com/lkpserver",
  //phoneApiUrl: 'http://localhost:3007',
  mobile_socket_path: "/lkpserver/socket.io",
  linkDomain: "https://dev.letskinect.com/go/",
  CHAT_GPT_APIKEY: "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",
  AWS_SECRET_KEY: "2Pscqkw77GT7B/n5JeYcLUDLr31bZHz8oaLw85aU",
  AWS_ACCESS_KEY: "AKIAVGL27I4NBEYEFW6S",
  AWS_BUCKET_NAME: "lkphone",
};
