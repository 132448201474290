import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms';

import {PopupComponent} from './popup/popup.component';
import {EditableComponent} from './editable/editable.component';
import {QuestionToastComponent} from './question-toast/question-toast.component';
import { QRCodeModule } from 'angularx-qrcode';
// import { ToastrModule } from 'ngx-toastr';


@NgModule(
{
    imports: [CommonModule, FormsModule, QRCodeModule],
    declarations: [EditableComponent, PopupComponent, QuestionToastComponent],
    exports: [EditableComponent, PopupComponent, QuestionToastComponent],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class UtilsModule {}
