import {Component} from '@angular/core';
import {HelperService} from "../../services/helper.service";
import {SelectEvent} from "@progress/kendo-angular-layout";
import {ActivatedRoute, ChildrenOutletContexts, NavigationEnd, Router, RoutesRecognized} from '@angular/router';
import {trigger, state, style, animate, transition} from '@angular/animations';

@Component({
	selector: 'app-avatarmanager',
	templateUrl: './avatarmanager.component.html',
	styleUrls: ['./avatarmanager.component.scss'],
	animations: [
		// EXTENSION CARDS SHOW / HIDE ANIMATION
		trigger('enterExitLeft', [
			transition(':enter', [
				style({
					opacity: 0,
					transform: 'translateX(-500px)'
				}),
				animate(
					'400ms ease-in',
					style({
						opacity: 1,
						transform: 'translateX(0)'
					})
				),
			]),
			transition(':leave', [
				animate(
					'400ms ease-in',
					style({
						opacity: 0,
						transform: 'translateX(-500px)'
					})
				),
			]),
		]),

		// EXTENSION OVERVIEW SHOW / HIDE ANIMATION
		trigger('enterExitRight', [
			transition(':enter', [
				style({
					opacity: 0,
					transform: 'translateX(500px)'
				}),
				animate(
					'400ms ease-in',
					style({
						opacity: 1,
						transform: 'translateX(0)'
					})
				),
			]),
			transition(':leave', [
				animate(
					'400ms ease-in',
					style({
						opacity: 0,
						transform: 'translateX(500px)'
					})
				),
			]),
		]),
	]
})
export class AvatarManagerComponent
{
	// CONFIGURATION FOR ADD NEW LIST BUTTON AND MESSAGES
	filterConfig: any =
		{
			search: false,
			overviewSwitch: true,
			addNew: false
		};

	// HOLD THE CURRENT ROUTE TAB.
	public currentRoute: string = '';

	public currentView: any = 'dashboard';

	// THIS ARRAY WILL HOLD THE ALL TABS AND THEIR RESPECTIVE ROUTES
	public avatarTabs =
		[
			{
				index: 0,
				id: 'BOOLEAN-TAB-BOTBUILDER',
				route: 'manageavatar',
				name: 'Avatar Manager',
				active: false
			},
			{
				index: 1,
				id: 'BOOLEAN-TAB-MANAGEBOT',
				route: 'createavatar',
				name: 'Create Avatar',
				active: true
			},
		]

	constructor
		(
			public helper: HelperService, // LOADING HelperService CLASS WITH ALIAS helper TO GET ACCESS TO THE GLOBAL DATA.
			public router: Router, // LOADING ROUTER CLASS, THIS WILL REDIRECT FROM ONE COMPONENT TO ANOTHER EITHER FROM A CLICK EVENT, A URL, OR A JUMPCODE.
		)
	{
		// ADDING DIFFERENT CLASS NAME TO BODY DEPENDING ON THE DIFFERENT ROUTE.
		// FOR EXAMPLE, THE BODY ATTRIBUTE OF ROOM PAGE WILL HAVE ROOM CLASS, WHILE ON
		// FIND ROOM PAGE IT WILL BE FINDROOM.
		this.router.events.subscribe((routeinfo: any) =>
		{
			if (routeinfo instanceof NavigationEnd)
			{
				// SUBSCRIBING TO NAVIGATIONEND WHICH IS ABOUT TO HAPPEN
				console.log('Navigation end happened');
				// IF WE SUCCESSFULLY GOT THE URL?
				if (routeinfo.url)
				{
					const url = routeinfo.url; // STORING THE URL INTO LOCAL VARIABLE.
					const urlArray = url.split('/'); // SPLITTING THE URL ON THE BASIS OF SLASH.
					let route = urlArray[1]; // GETTING THE VALUE AT INDEX 1.

					// IF THE VALUE AT INDEX 1 MATCHES THE WORD PRV OR PUB THEN WE ARE IN THE ROOM.
					if (route == 'createavatar')
					{
						this.avatarTabs[0].active = false;
						this.avatarTabs[1].active = true;
					} else
					{
						this.avatarTabs[0].active = true;
						this.avatarTabs[1].active = false;
					}
				}
			}
		});
	}

	// ON TOGGLE VIEW / OVERVIEW SWITCH CHANGE.
	onToggleOverview(switchState: boolean)
	{
		console.log('receiving', switchState);
		this.currentView = '';

		// IF VIEW EXTENSION IS ENABLED.
		setTimeout(() =>
		{
			this.currentView = switchState ? 'overview' : 'dashboard';
		}, 500);
	}
}
