<section class="main-layout-container bg-white position-relative">
	<div class="simple-content">
		<div class="filter-content">
			<div class="top-bar filters-bar pull-right">
				<ng-container>
					<app-search [config]="topbarConfig"></app-search>
				</ng-container>
			</div>
		</div>
		<div class="tradenow-container">
			<div class="header-content">
				<div class="title">Time for you to place your trade!</div>
				<div class="instruction">
					<div class="instruction-child">
						<img src="/assets/images/trade/step1.svg" class="list-icon" />
						<p class="list-text">Place your entry</p>
					</div>
					<div class="instruction-child">
						<img src="/assets/images/trade/step2.svg" class="list-icon" />
						<p class="list-text">Place your exit</p>
					</div>
					<div class="instruction-child">
						<img src="/assets/images/trade/file.svg" class="list-icon" />
						<p class="list-text">Add a leg if necessary</p>
					</div>
					<div class="instruction-child">
						<img src="/assets/images/trade/preview.svg" class="list-icon" />
						<p class="list-text">Flip the <b>Overview</b> switch above to find out how to use this page.</p>
					</div>
				</div>
			</div>
			
			<div class="main-content">
				<div class="row">
					<div class="col-md-12 d-flex justify-content-between">
						<div class="dropdownlist-content">
							<div class="label">Create Your Trade</div>
						</div>
					</div>
				</div>
				<div class="row trade-card">
					<div class="col-md-12">
						<kendo-card class="trade-card-content" [width]="'100%'" style="">
							<kendo-card-header class="k-hstack">
								<div class="card-header-containter">
									<div class="options">
										<div class="toggleable-button" >
											<div class="label">Stock/Option:</div>
											<div class="button-option">
												<button
													kendoButton (click)="onSelectedChange('stock')"
													[selected]="selectedButton === 'stock'"
													[ngClass]="{'selectedButton': selectedButton === 'stock', 'button': selectedButton !== 'stock'}"
												>
													Stock
												</button>
												<button
													kendoButton
													(click)="onSelectedChange('option')"
													[selected]="selectedButton === 'option'"
													[ngClass]="{'selectedButton': selectedButton === 'option', 'button': selectedButton !== 'option'}"
												>
													Option
												</button>
											</div>
										</div>
										
										<div class="dropdownlist">
											<div class="label">Underlying Symbol:</div>
											<kendo-dropdownlist
													[style.width.px]="172"
													textField="text"
													valueField="value"
											>
											</kendo-dropdownlist>
										</div>
										<div class="dropdownlist">
											<div class="label">Derivative Symbol:</div>
											<kendo-dropdownlist
													[style.width.px]="172"
													textField="text"
													valueField="value"
											>
											</kendo-dropdownlist>
										</div>
									</div>
									<button
										[style.width.px]="92"
										kendoButton class="btn"
										[ngClass]="{'green-button': submitedBtn === 'Submit', 'red-button':
										submitedBtn === 'Submitted'}"
											(click)="onSubmithandle()"
									>{{submitedBtn}}</button>
								</div>
							</kendo-card-header>
							<kendo-card-body style="max-height: 44.5vh; overflow: hidden; min-height: 44.5vh">
								<div class="yourturn-card-body">
									<div class="addleg-button" (click)="addPanel()">
										<img src="/assets/images/trade/addicon.svg" class="action-plus-icon"> Add a leg
									</div>
									<div style=" overflow: auto; flex:
									 1; box-sizing: border-box; max-height: 39.5vh; min-height: 39.5vh">
									<div *ngFor="let panel of panelList; let i = index">
										<div class="d-flex align-items-start flex-column  mb-3" style="margin-right:
										5px; margin-left: 10px;">
											<div class="counting-number">Leg {{i + 1}} </div>
												<div class="content-body">
													<div class="flex-container">
														<div class="flex-item">
															<div class="heading">Entry Condition</div>
															<div class="content-bg">
															<form spellcheck="false" class="text-center">
																<div class="condition-wrapper">
																	<div class="d-flex">
																		<div class="options toggleable-button" >
																			<div class="label">Action:</div>
																			<div class="button-option">
																				<button
																						kendoButton
																						(click)="onConditionButtonChange ('entry','buy', i)"
																						[selected]="panel.entryBtnOption === 'buy'"
																						[ngClass]="{'selectedButton': panel.entryBtnOption === 'buy', 'button': panel.entryBtnOption !== 'buy'}"
																				>
																					Buy
																				</button>
																				<button
																						kendoButton
																						(click)="onConditionButtonChange ('entry','sell', i)"
																						[selected]="panel.entryBtnOption === 'sell'"
																						[ngClass]="{'selectedButton': panel.entryBtnOption === 'sell', 'button': panel.entryBtnOption !== 'sell'}"
																				>
																					Sell
																				</button>
																			</div>
																		</div>
																		<div class="options togglnumberOption" >
																			<div class="label"> Quantity:</div>
																			<div class="toggle-number">
																				<img src="assets/images/trade/minus.svg" class="minus-btn" />
																				<input
																						class="k-input-inner input-padding"
																						[style.width.px]="50"
																						[style.height.px]="36"
																						placeholder="1"
																						kendoTextBox
																				/>
																				<img src="assets/images/trade/plus.svg" class="plus-btn" />
																			</div>
																		</div>
																		<div class="options dropdownlist">
																			<div class="label"> Strategy:</div>
																			<kendo-dropdownlist
																				class=""
																				[style.width.px]="119"
																				[textField]="'text'"
																				[valueField]="'value'"
																			></kendo-dropdownlist>
																		</div>
																		
																		<div class="options dropdownlist">
																			<div class="label"> Order Type:</div>
																			<kendo-dropdownlist
																				class=""
																				[style.width.px]="102"
																				[textField]="'text'"
																				[valueField]="'value'"
																			></kendo-dropdownlist>
																		</div>
																		
																		<div class="options inputbox">
																			<div class="label"> Limit:</div>
																			<input
																				class="k-input-inner input-padding"
																				placeholder="$ 250"
																				[style.width.px]="60"
																				[style.height.px]="36"
																				kendoTextBox
																			/>
																		</div>
																	</div>
																</div>
															</form>
															</div>
														</div>
														<div class="flex-item">
															<div class="heading">Exit Condition</div>
															<form spellcheck="false" class="text-center">
																<div class="content-bg">
																	<form spellcheck="false" class="text-center">
																		<div class="condition-wrapper">
																			<div class="d-flex">
																				<div class="options toggleable-button" >
																					<div class="label">Action:</div>
																					<div class="button-option">
																						<button
																								kendoButton
																								(click)="onConditionButtonChange ('entry','buy', i)"
																								[selected]="panel.exitBtnOption === 'buy'"
																								[ngClass]="{'selectedButton': panel.exitBtnOption === 'buy', 'button': panel.exitBtnOption !== 'buy'}"
																						>
																							Buy
																						</button>
																						<button
																								kendoButton
																								(click)="onConditionButtonChange ('entry','sell',i)"
																								[selected]="panel.exitBtnOption === 'sell'"
																								[ngClass]="{'selectedButton': panel.exitBtnOption === 'sell', 'button': panel.exitBtnOption !== 'sell'}"
																						>
																							Sell
																						</button>
																					</div>
																				</div>
																				<div class="options togglnumberOption" >
																					<div class="label"> Quantity:</div>
																					<div class="toggle-number">
																						<img src="assets/images/trade/minus.svg" class="minus-btn" />
																						<input
																								class="k-input-inner input-padding"
																								[style.width.px]="50"
																								[style.height.px]="36"
																								placeholder="1"
																								kendoTextBox
																						/>
																						<img src="assets/images/trade/plus.svg" class="plus-btn" />
																					</div>
																				</div>
																				<div class="options dropdownlist">
																					<div class="label"> Strategy:</div>
																					<kendo-dropdownlist
																							class=""
																							[style.width.px]="119"
																							[textField]="'text'"
																							[valueField]="'value'"
																					></kendo-dropdownlist>
																				</div>
																				
																				<div class="options dropdownlist">
																					<div class="label"> Order Type:</div>
																					<kendo-dropdownlist
																							class=""
																							[style.width.px]="102"
																							[textField]="'text'"
																							[valueField]="'value'"
																					></kendo-dropdownlist>
																				</div>
																				
																				<div class="options inputbox">
																					<div class="label"> Limit:</div>
																					<input
																							class="k-input-inner input-padding"
																							placeholder="$ 250"
																							[style.width.px]="60"
																							[style.height.px]="36"
																							kendoTextBox
																					/>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</form>
														</div>
													</div>
												</div>
											</div>
									</div>
									</div>
								</div>
							</kendo-card-body>
						</kendo-card>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
