<div class="main-container">
	<div class="header_section">
		<div class="header-bg"></div>
		<!-- Header -->
		<nav class="navbar navbar-expand-xl header">
			<div class="container-fluid p-0">
				<a class="navbar-brand" (click)="redirectHome()">
					<li class="logo-container">
						<div class="logo-icon" (click)="redirectHome()">
							<img class="logo" src="./assets/images/logo.svg" alt="logo" />
							<div class="logo-text">
								LetsKinect Trader
							</div>
						</div>
					</li>
				</a>
				<button class="navbar-toggler" (click)="openModalWithComponent()" type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
					aria-expanded="false" aria-label="Toggle navigation">
					<span class="toggler-icon">
						<img class="toggler" src="./../assets/images/menu.svg">
					</span>
				</button>
				<div class="collapse navbar-collapse">
					<ul class="navbar-nav mb-2 mb-lg-0">
						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link active" aria-current="page"
								[routerLink]="['/dashboard']">Dashboard</a>
						</li>
						<li class="nav-item" *ngIf="helper.user_id" routerLinkActive="active">
							<a class="nav-link" [routerLink]="['/tradeidea']">Trade Ideas</a>
						</li>
						<li class="nav-item" *ngIf="helper.user_id" routerLinkActive="active">
							<a class="nav-link" [routerLink]="['/tradenow']">Trade Now</a>
						</li>
						<li class="nav-item" *ngIf="helper.user_id" routerLinkActive="active">
							<a class="nav-link" [routerLink]="['/tradehistory']">Trade Manager</a>
						</li>
						<li class="nav-item" *ngIf="helper.user_id" routerLinkActive="active">
							<a class="nav-link" [routerLink]="['/avatarmanager']">Avatar Manager</a>
						</li>
						<li class="nav-item" *ngIf="helper.user_id" routerLinkActive="active">
							<a class="nav-link" [routerLink]="['/links']">Links</a>
						</li>
						<li class="nav-item" *ngIf="helper.user_id" routerLinkActive="active">
							<a class="nav-link" [routerLink]="['/account']">Account</a>
						</li>
					</ul>
					
					<!-- SHOW THE CREDITS AND KIPS OF USER.-->
					<ng-container
						*ngIf="helper.isUserLoggedIn && helper.user_id"
					>
						<app-kipscredits></app-kipscredits>
					</ng-container>
					
					<div class="d-flex head-r-nav">
						<form class="d-flex head-r-nav last-menu-item" role="search">
							<div class="head-r-nav last-menu-item">
								<button *ngIf="!helper.user_id" type="button" name="login" (click)="goToLogin()"
									class="signin-button"> Sign In
								</button>
								<button *ngIf="helper.user_id" type="button" name="login" (click)="logOut()"
									class="signin-button"> Sign Out
								</button>
							</div>
						</form>
						
						<div class="head-r-nav menu-avatar-continer last-menu-item d-flex align-items-center"
						*ngIf="helper.user_id">
							<div class="avatar {{helper.deviceType}} me-2" [ngStyle]="{color: helper.screenNameFontColor}">
								<app-editable
									(keyup.enter)="helper.updateUserScreenName($event, 'top');"
									label="username"
									(change)="helper.updateUserScreenName($event, 'top');"
									[(ngModel)]="helper.userscreenname"
									[required]="true" type="text" position="top">
								</app-editable>
							</div>
							<img class="avatar-image" src="{{userAvatar}}" alt="profile-picture" />
						</div>
					</div>
				</div>
			</div>
		</nav>
		<!-- HEADER END -->
	</div>
	
	<div #appContainer id="app-container"
		[ngStyle]="appContainerCss"
	>
		<!-- CONTENT SECTION END -->
		<section style="position: relative;" #mainArea id="main_area" class="main_area clearfix"
			[ngStyle]="helper.mobileMain">

			<div class="mobile-error-container" *ngIf="isUserOnMobile">
				<div class="image">
				  <img src="/assets/images/404.svg" alt="Image" />
				</div>
				<div class="bold-text">
					  {{this.helper.sitemessageslkp['MSGLKP-LKADMIN-ACCESSINGTHROUGHMOBILE'].message}}
				</div>
			  </div>
			
			<div id="router_container" [@routeAnimations]="outlet && outlet.activatedRouteData
      && outlet.activatedRouteData['animation']" *ngIf="!isUserOnMobile">
				<router-outlet #outlet="outlet" ></router-outlet>
			</div>
			
			<!-- CONTENT SECTION END -->
			<app-popup></app-popup>
			<!--<div class="bottom-menu"><a [routerLink]="['/privacy']">Privacy policy</a></div>-->
		</section>
	</div>
	
	<!--FOOTER SECTION-->
	<div class="footer-section">
		<div class="footer-copyright">
			© 2024 LetsKinect, LLC. All rights reserved.
		</div>
		<div class="footer-links">
			<ul class="navbar-nav d-flex mb-2 mb-lg-0">
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/about']">Terms of Use</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/privacy']">Privacy Policy</a>
				</li>
			</ul>
		</div>
	</div>
</div>

<!-- DIALOG COMONENT SHOW HIDE THROUGH OBSERVER -->
<app-global-dialog></app-global-dialog>

<!-- POPUP WINDOWS TO SHOW IF BROWSER IS NOT SUPPORTED -->
<div class="k-overlay" *ngIf="!helper.browserSupport"></div>
<kendo-dialog *ngIf="!helper.browserSupport" class="popup lkpopup browsersupport {{helper.layoutType}}"
	title="{{helper.browserStatus.title}}">
	<div class="brower-support-message" [innerHTML]="helper.browserStatus.message"></div>
	<div class="gap" id="roomurl">{{helper.currentUrl}}</div>
	<kendo-dialog-actions class="browsersupport-actions">
		<a kendoButton class="blue-button kendo-cyan kendo-btn" (click)="helper.copyLink()">Copy Link</a>
		<a *ngIf="helper.suggestedBrowser=='chrome' && helper.upgradeBrowserLink==''" kendoButton
			class="blue-button kendo-grey kendo-btn"
			href="{{helper.suggestedDownloadLink}}">Download Chrome</a>
		<a *ngIf="helper.upgradeBrowserLink!=''" target="_blank" kendoButton class="blue-button kendo-grey kendo-btn"
			href="{{helper.upgradeBrowserLink}}">Upgrade {{helper.browserName | titlecase}}</a>
	</kendo-dialog-actions>
</kendo-dialog>

<!-- DIALOG BOX TO CONFIRM USER TO LEAV OR STAY IN THE ROOM -->
<kendo-dialog title="{{dialogService.title}}" *ngIf="dialogService.popup_id == 'contentPopupLeavingRoom'"
	(close)="dialogService.hideDialog()" [minWidth]="250" [width]="550">
	<div class="textline text-center" [innerHTML]="dialogService.tagline"></div>
	<div id="popup_content" class="popup-contents textlight" [innerHTML]="dialogService.content"></div>
	<kendo-dialog-actions class="twobuttons">
		<button kendoButton class="dialog-button-1" (click)="dialogService.hideDialogAndNavigate('')">Leave</button>
		<button kendoButton class="dialog-button-2" (click)="dialogService.hideDialog()">Stay</button>
	</kendo-dialog-actions>
</kendo-dialog>
