import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-overview',
  templateUrl: './dashboardoverview.component.html',
  styleUrls: ['./dashboardoverview.component.scss']
})
export class DashboardoverviewComponent
{
	visibleListItems: number = 8; // NUMBER OF VISIBLE ITEMS
	currentIndex: number = 0; // CURRENT INDEX FOR PAGINATION
	activeListIndex: number = 0; // INITIAL SELECTED INDEX
	startListIndex: number = 0;
	incrementIndexValue: number = 0;
	endListIndex: number = this.visibleListItems;
	
	public currentSource: any =
	[
		{ title: "Video Title Goes Here1" },
		{ title: "Video Title Goes Here2" },
		{ title: "Video Title Goes Here3" },
		{ title: "Video Title Goes Here4" },
		{ title: "Video Title Goes Here5" },
		{ title: "Video Title Goes Here6" },
		{ title: "Video Title Goes Here7" },
		{ title: "Video Title Goes Here8" },
		{ title: "Video Title Goes Here9" },
		{ title: "Video Title Goes Here10" },
	];
	
	navigate(direction: 'up' | 'down'): void
	{
		if (direction === 'up' && this.currentIndex > 0)
		{
			this.currentIndex -= 1;
			
			// ADJUST ACTIVELISTINDEX BASED ON CURRENTINDEX CHANGE
			this.activeListIndex = this.currentIndex;
			
			// CHECK THE CURRENT ACTIVE INDEX IS EQUAL TO VISIBLE CURRENT ITEM SHOW.
			if (this.incrementIndexValue > 0 || this.currentIndex >= this.visibleListItems)
			{
				this.endListIndex -= 1;
				this.startListIndex -= 1;
				this.incrementIndexValue -= 1;
			}
		}
		else if (direction === 'down' && this.endListIndex < this.currentSource.length)
		{
			this.currentIndex += 1;
			
			// ADJUST ACTIVELISTINDEX BASED ON CURRENTINDEX CHANGE
			this.activeListIndex = this.currentIndex;
			
			// CHECK THE CURRENT ACTIVE INDEX IS EQUAL TO VISIBLE CURRENT ITEM SHOW.
			if (this.currentIndex >= this.visibleListItems)
			{
				this.startListIndex += 1;
				this.endListIndex += 1;
				this.incrementIndexValue += 1;
			}
		}
	}
	
	// THE IS FUNCTION TO SELECTED ACTIVE LIST ITEM INDEX.
	selectedActiveIndexItem (index: number): void
	{
		// CALCULATE THE OVERALL INDEX IN THE SOURCE ARRAY
		const overallIndex = (this.currentIndex >= this.visibleListItems ? this.incrementIndexValue : 0)  + index;
		this.activeListIndex = overallIndex;
		this.currentIndex = index
	}
	
	// CHECK THE LIST IS TOP THEN DISABLE TO NAVIGATE THE UP TO LIST ITEM.
	isListTopDisabled(): boolean {
		return this.startListIndex === 0;
	}
	
	// CHECK THE LIS IS BOTTOM THEN DISABLE TO NAVIGATE THE DOWN TO LIST ITEM.
	isListDownDisabled(): boolean
	{
		return this.endListIndex >= this.currentSource.length;
	}
}
