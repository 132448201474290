/*
           FILENAME: lk_client/src/app/pipes/findreplace.pipe.ts
	         AUTHOR: Zulaikha Moidin
 	        SUMMARY: FIND IF THERE ARE WORDS USED IN THE GIVEN INPUT STRING THAT WE WANT TO REPLACE. THE WORDS ARE COMPARED WITH THE REPLACE TABLE AND REPLACED WITH SUGGESTIONS FOUND IN THAT TABLE
            PURPOSE: TO REPLACE WORDS FOUND IN REPLACE TABLE WITH ITS SUGGESTIONS
    IMPORTING FILES: helper.service.ts
  SUBSCRIBING FILES: app.module.ts | privatechat.component.ts | publicchat.component.ts 
   LAST COMMIT DATE: July 13, 2020
*/

// IMPORTING ANGULAR CORE LIBRARIES
import {Injector, Pipe, PipeTransform} from '@angular/core';

// IMPORTING THE LK HELPER SERVICE TO GET ACCESS TO THE GLOBAL DATA AND SERVICES SO THAT WE CAN USE THE GLOBAL DATA AND FUNCTIONS.
import {HelperService} from "../services/helper.service";

// IMPORTING THE TOASTER SERVICE SO THAT WE CAN SHOW THE TOAST MESSAGES AT THE TOP RIGHT OF THE SCREEN.

// ANGULAR DECLARATION FOR A PIPE
@Pipe
({
  name: 'findReplace'
})

// DECLARING THE FindReplacePipe CLASS THAT IMPLEMENTS PIPETRANSFORM ANGULAR INTERFACE
export class FindReplacePipe implements PipeTransform
{
	// CONSTRUCTOR FUNCTION OF THE CLASS. IT WILL BE THE FIRST FUNCTION THAT WILL BE EXECUTED WHEN THIS CLASS LOADS.
	constructor (
		private injector:Injector,
		 private helper: HelperService) {}

	// STANDARD PIPE FUNCTION TO DISPLAY AFTER PROCESSING THE GIVEN INPUT
	transform(value: string, args?: any): any
	{
		// STORE THE VALUE TO TEST.
		let inputString = value;

		// FIND WORDS IN THE REPLACE TABLE IN THE GIVEN INPUT STRING AND REPLACE WITH THE REPLACE VALUE IN THE TABLE.
		this.helper.findReplaceData.forEach(row =>
		{
			let currentword = "\\s"+row.find+"\\s"; // GET THE VALUE IN THE FIND COLUMN
			let currentreplace = " "+row.replace+" "; // GET THE VALUE IN THE REPLACE COLUMN
			var regexp = new RegExp(currentword, "gi"); // CREATE REGULAR EXPRESSION FORM OF THE CURRENTWORD

			// REPLACE IF THERE IS A MATCH FOR THE CURRENT WORD
			inputString = inputString.replace(regexp, currentreplace);
		});

		// RETURN THE REPLACED VALUES
		return inputString;
	}
}
