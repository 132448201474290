<kendo-dialog title="" class="delete-dialog" (close)="onDeleteCancel()" *ngIf="isOpen" [minWidth]="250" [width]="450">

  <app-loading *ngIf="isLoading"></app-loading>

  <p>
    Are you sure you want to delete this record?
  </p>

  <div class="d-flex justify-content-between footer">
    <div class="cancel-button" (click)="onDeleteCancel()">
      Cancel
    </div>

    <div class="confirm-button d-flex" (click)="onDeleteConfirm()" look="clear">
      <img src="/assets/images/trashred.svg" class="me-2"/>Delete</div>
  </div>

</kendo-dialog>