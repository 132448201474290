import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HelperService} from "../../services/helper.service"
import {DbService} from 'src/app/services/db.service';
import {error} from 'jquery';
import {trigger, state, style, animate, transition} from '@angular/animations';

@Component({
	selector: 'app-tradehistory',
	templateUrl: './tradehistory.component.html',
	styleUrls: ['./tradehistory.component.scss'],
	animations: [
		// EXTENSION CARDS SHOW / HIDE ANIMATION
		trigger('enterExitLeft', [
			transition(':enter', [
				style({
					opacity: 0,
					transform: 'translateX(-500px)'
				}),
				animate(
					'400ms ease-in',
					style({
						opacity: 1,
						transform: 'translateX(0)'
					})
				),
			]),
			transition(':leave', [
				animate(
					'400ms ease-in',
					style({
						opacity: 0,
						transform: 'translateX(-500px)'
					})
				),
			]),
		]),

		// EXTENSION OVERVIEW SHOW / HIDE ANIMATION
		trigger('enterExitRight', [
			transition(':enter', [
				style({
					opacity: 0,
					transform: 'translateX(500px)'
				}),
				animate(
					'400ms ease-in',
					style({
						opacity: 1,
						transform: 'translateX(0)'
					})
				),
			]),
			transition(':leave', [
				animate(
					'400ms ease-in',
					style({
						opacity: 0,
						transform: 'translateX(500px)'
					})
				),
			]),
		]),
	]
})
export class TradeHistoryComponent
{
	// CONFIGURATION FOR ADD NEW LIST BUTTON AND MESSAGES
	topbarConfig: any =
		{
			search: false,
			overviewSwitch: true,
			addNew: false
		};

	// GRID DATA ACTION HEADER AND COLUMN STYLE.
	tradeHeaderStyle = {"min-width": "200px"};

	// TO HANDLE PAGINATION OF CONTACTS
	public gridTotal: number;
	public gridSkip = 0;
	public gridPageSize = 8;
	public form: FormGroup;
	public filterform: FormGroup;

	public currentView: any = 'dashboard';

	public endDate: any = new Date();
	public startDate: any = new Date(new Date().setDate(this.endDate.getDate() - 30));

	// DEMO DATA
	public tradelist = [];

	public tradeStatusSource: Array<{text: string; value: any}> = [
		{text: "Any Trade Status", value: ""},
	];

	public strategyTypeSource: any = [{
		tradestrategy: "Any Strategy Type",
		tradestrategy_id: '',
	}];

	public securityTypeSource: Array<{text: string; value: any}> = [
		{text: "Any Security Type", value: ""},
	];

	public range = {start: null, end: null};

	public tradeAvatarDataSource: any = [];

	public typingTimer: any;

	// LAST MODIFIED DROPDOWN DATA SOURCE.
	public lastModifiedDatasource: Array<{text: string, value: string}> =
		[
			{
				text: 'Today',
				value: 'today'
			},
			{
				text: 'Yesterday ',
				value: 'yesterday'
			},
			{
				text: 'Last 3 Days ',
				value: 'last_3_days'
			},
			{
				text: 'Last 7 Days',
				value: 'last_7_days'
			},
			{
				text: 'Last 30 Days',
				value: 'last_30_days'
			},
			{
				text: 'Last 90 Days',
				value: 'last_90_days'
			},
			{
				text: 'All Time',
				value: 'all_time'
			},
		];


	constructor
		(
			public dbService: DbService, // DB SERVICE.
			private formBuilder: FormBuilder, // FORM BUILDER FOR CREATING REACTIVE FORMS
			public helper: HelperService, // LOADING HelperService CLASS WITH ALIAS helper TO GET ACCESS TO THE GLOBAL DATA.
		)
	{
	}

	ngOnInit()
	{
		if (this.helper.avatarData)
		{
			this.tradeAvatarDataSource = this.tradeAvatarDataSource.concat(this.helper.avatarData);
		}

		// CREATING REACTIVE FORM SO THAT WE CAN CONTROL THE HTML FORM FROM THIS FILE.
		this.filterform = this.formBuilder.group
			({
				keyword: '',
				tradeAvatar: '',
				strategyType: '',
				tradeStatus: '',
				securityType: '',
				lastmodified: 'last_7_days'
			});

		// LOAD THE DATA.
		this.loadData();
	}

	// LOAD DATA
	loadData()
	{
		// WAITING FOR LOGIN ATTEMPT TO COMPLETE.
		if (this.helper.loginAttemptCompleted)
		{
			console.log(this.helper.avatarData, 'avatarData');
			this.tradeAvatarDataSource = this.helper.avatarData;
			this.filterform.controls.tradeAvatar.setValue(this.tradeAvatarDataSource[0]?.avatar_id);
			this.getTradeList();
			this.getTradeStatusLists();
			this.getTradeStrategyLists();
			this.getTradeSecurityTypeLists();
		} else
		{
			setTimeout(() =>
			{
				this.loadData();
			}, 100);
		}
	}

	resetFilter()
	{
		this.filterform.controls.keyword.setValue('');
		this.filterform.controls.tradeAvatar.setValue(this.tradeAvatarDataSource[0]?.avatar_id);
		this.filterform.controls.strategyType.setValue('');
		this.filterform.controls.tradeStatus.setValue('');
		this.filterform.controls.securityType.setValue('');
		this.filterform.controls.lastmodified.setValue('last_7_days');
		this.getTradeList();
	}

	// THIS FUNCTION WILL BE CALLED ON SEARCH INPUT KEY UP
	public inputKeyup()
	{
		// CLEAR THE PREVIOUS TYPING TIMER.
		clearTimeout(this.typingTimer);

		// SET THE NEW TYPING TIMER
		this.typingTimer = setTimeout(() =>
		{
			this.getTradeList();
		}, 750);
	}

	//  FUNCTION TO GET TRADE LIST
	public async getTradeList()
	{
		let postObj: any = {skip: this.gridSkip, limit: this.gridPageSize, lkphone_id: this.helper.lkphone_id};
		// IF KEYWORD IS NOT BLANK.
		if (this.filterform.controls.keyword.value != '')
		{
			postObj.keyword = this.filterform.controls.keyword.value;
		}

		// IF AVATER IS NOT BLANK.
		if (this.filterform.controls.tradeAvatar.value != '')
		{
			postObj.avatar_id = this.filterform.controls.tradeAvatar.value;
		}

		// IF STRAGY TYPE IS NOT BLANK.
		if (this.filterform.controls.tradeStatus.value != '')
		{
			postObj.tradeStatus = this.filterform.controls.tradeStatus.value;
		}

		// IF STRAGY TYPE IS NOT BLANK.
		if (this.filterform.controls.strategyType.value != '')
		{
			postObj.strategyType = this.filterform.controls.strategyType.value;
		}

		// IF SECURITY TYPE IS NOT BLANK.
		if (this.filterform.controls.securityType.value != '')
		{
			postObj.securityType = this.filterform.controls.securityType.value;
		}

		let dateRange = this.helper.getDateRangeByLastModified(this.filterform.controls.lastmodified.value);
		console.log('dateRange', dateRange);
		// IF START DATE IS NOT BLANK.
		if (dateRange?.startDate != '')
		{
			postObj.startDate = dateRange?.startDate;
		}

		// IF END DATE IS NOT BLANK.
		if (dateRange?.endDate != '')
		{
			postObj.endDate = dateRange?.endDate;
		}


		this.dbService.performPostRequest('/api/lktraders/trademanager/trade-manage', postObj).subscribe((response: any) =>
		{
			if (response.success)
			{
				this.tradelist = response.data.data;
				this.gridTotal = response.data.total_count;
			}
		});
	}

	// THIS FUNCTION GET ALL TRADE SECURITY TYPE FROM TABLE
	public getTradeSecurityTypeLists()
	{
		this.dbService.performGetRequest(`/api/lktraders/trademanager/get-all-trade-security-type`).subscribe((response: any) =>
		{
			if (response.success)
			{
				this.securityTypeSource = this.securityTypeSource.concat(response.data);
			}
		});
	}

	// THIS FUNCTION GET ALL TRADE STATUS FROM TABLE
	public getTradeStatusLists()
	{
		this.dbService.performGetRequest(`/api/lktraders/trademanager/get-all-trade-status`).subscribe((response: any) =>
		{
			if (response.success)
			{
				this.tradeStatusSource = this.tradeStatusSource.concat(response.data);
			}
		});
	}

	// Function to determine the CSS class based on trade status
	public getTradeStatusColorClass(tradeStatus: string): string
	{
		return (tradeStatus === 'Canceled' || tradeStatus === 'Closed by Exit Condition') ? 'closed-status' :
			(tradeStatus === 'Filed' || tradeStatus === 'Open') ? 'filled-status' : '';
	}

	// FUNCTION TO CALL API
	public onCancel(data: any)
	{
		console.log(data, "DATA")
		this.dbService.performPostRequest(`/api/lktraders/trademanager/cancel-trade?tradeleg_id=${data?.tradeleg_id}`, data).subscribe((response: any) =>
		{
			// console.log(response, "cancel");
		});
	}

	public getTradeStrategyLists()
	{
		this.dbService.performGetRequest('/api/lktraders/trademanager/trade-strategy').subscribe((response: any) =>
		{
			if (response.success)
			{
				this.strategyTypeSource = this.strategyTypeSource.concat(response.data);
			}
		});
	}

	// THIS FUNCTION WILL BE CALLED WHEN PAGE IS CHANGED
	public onPageChange(e: any)
	{
		this.gridSkip = e.skip;
		this.gridPageSize = e.take;
		this.getTradeList();
	}

	// ON TOGGLE VIEW / OVERVIEW SWITCH CHANGE.
	onToggleOverview(switchState: boolean)
	{
		console.log('receiving', switchState);
		this.currentView = '';

		// IF VIEW EXTENSION IS ENABLED.
		setTimeout(() =>
		{
			this.currentView = switchState ? 'overview' : 'dashboard';
		}, 500);
	}

}
