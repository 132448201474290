// zoom.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {
  private originalWidth = 1920;
  private originalHeight = 1080;

  /**
   * THIS FUNCTION WILL GET THE CURRENT SCREEN RESOLUTION AND GIVE US A PERCENTAGE OF RESOLUTION
   * @returns number
   */
  getScreenResolutionPercentage(): number {
    const screenWidth = window.innerWidth;
    const widthPercentage = (screenWidth / this.originalWidth) * 100;

    if (widthPercentage < 90 && widthPercentage > 60) {
      return widthPercentage * 1.1
    }
    else if (widthPercentage < 120) {
      return 100
    }
    else {
      return widthPercentage
    }
  }

  /**
   * THIS FUNCTION WILL ADJUST THE ZOOM OF ANY ELEMENT TO PROVIDED LEVEL
   * @param elementId string
   * @param zoomLevel number
   */
  setZoom(elementId: string, zoomLevel: number): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.style.transform = `scale(${zoomLevel})`;
    }
  }
}
