/*
		   FILENAME: lk_client/src/app/services/login.service.ts
	         AUTHOR: Qasim Zubair
 	        SUMMARY: MAKING COMMON LOGIN FUNCTIONS ACCESSIBLE TO DIFFERENT COMPONENTS.
            PURPOSE: TO PUT COMMON FUNCTIONS LIKE LOGIN, LOGOUT, CHECK COOKIES ETC IN ONE PLACE WHERE
                     ANY COMPONENT CAN EASILY ACCESS IT
    IMPORTING FILES: db.service.ts | helper.service.ts | cookie.service.ts
  SUBSCRIBING FILES: app.component.ts | init.provider.ts | login.component.ts | myaccount.component.ts
                     | reset.component.ts | myaccount.component.ts | register.component.ts
   LAST COMMIT DATE: July 13, 2020.
*/

// IMPORTING THE ANGULAR MODULES FOR PERFORMING BASIC ANGULAR FRAMEWORK OPERATIONS.
import {Injectable} from '@angular/core';

// IMPORTING THE LK DB SERVICE TO COMMUNICATE WITH THE DATABASE.
import {DbService} from "./db.service";

// IMPORTING THE LK HELPER SERVICE TO GET ACCESS TO THE GLOBAL DATA AND SERVICES SO THAT
// WE CAN USE THE GLOBAL DATA AND FUNCTIONS LIKE MESSAGING AND SITE VARIABLES.
import {HelperService} from "./helper.service";

// IMPORTING ANGULAR COOKIE SERVICE TO GET ACCESS TO SITE COOKIE.
import {CookieService} from "ngx-cookie-service";

// @Injectable DECLARES THIS CLASS AS A SERVICE SO THAT WE CAN INJECT THIS SERVICE INTO ANY OTHER COMPONENT OR SERVICE.
@Injectable
({
	providedIn: 'root'
})

// DECLARING THE LoginService CLASS WITH EXPORT SO THAT WE CAN IMPORT THIS SERVICE INTO ANY OTHER COMPONENT OR SERVICE.
export class LoginService
{
	// THIS VARIABLE WILL HOLD THE USER OBJECT FROM DATABASE.
	public user:any = {};
	isLocal: boolean;
	hostName:string;
	
	// NEED TO TIME SET BY MINUTE
	public cookiesMaxAge : any = 60*24;

	// CLASS CONSTRUCTOR, THIS WILL BE FIRST FUNCTION TO BE EXECUTED WHEN THIS CLASS LOADS.
	// HERE WE WILL TELL ANGULAR TO INJECT A DEPENDENCY BY SPECIFYING A CONSTRUCTOR
	// PARAMETER WITH THE DEPENDENCY TYPE.
	constructor
	(
		private dbService: DbService, // LOADING DATABASE SERVICE TO INTERACT WITH DATABASE.
		public helper: HelperService, // LOADING HELPER SERVICE TO USE APP GLOBAL VARIABLES.
		private cookieService: CookieService, // LOADING COOKIE SERVICE TO HANDLE SITE COOKIES.
	)
	{
		console.log("location hostname",window.location.hostname)
		this.isLocal = window.location.hostname === 'localhost';
		this.hostName = this.isLocal ? 'localhost' : "letskinect.com";
	}

	// FUNCTION TO VALIDATE USER'S LOGIN COOKIE. THIS WILL CHECK IF THE COOKIE PASSWORD
	// THAT IS STORED IN DATABASE MATCHES WITH COOKIE VALUE OF USER.
	// THIS IS FOR SECURITY CHECK FOR COOKIE TEMPERING, AND WE WILL KNOW IF THE USER HAD CHANGED
	// COOKIE OR NOT.
	validateLoginCookie (passcode)
	{
		// GETTING COOKIE FROM USER'S BROWSER.
		let lk_user_code = this.cookieService.get ("lk_user_code");

		// IF THE COOKIE VALUE MATCHES WITH THE VALUE STORED IN DATABASE THEN EVERYTHING IS GOOD.
		if (lk_user_code && lk_user_code == passcode)
		{
			return true;
		}
		else
		{
			// IF COOKIE DOES NOT MATCH THEN WE WILL RETURN FALSE.
			return false;
		}
	}

	// THIS FUNCTION WILL LOGOUT THE USER AND CLEAR THE LOGIN COOKIE.
	public logoutUser()
	{
		return new Promise (resolve =>
		{
			// IF THE USER IS NOT LOGGED IN THEN THERE IS NO NEED TO LOGOUT THEM.
			// WE WILL JUST KILL THE FURTHER EXECUTION OF THIS FUNCTION.
			if (!this.helper.isLoggedIn()) resolve (false);
			else
			{
				// SENDING REQUEST TO SERVER SO WE CAN LOGOUT THIS USER ON SERVER SIDE.
				this.dbService.logoutUser(this.user.user_id, this.helper.device_id).subscribe(data=>
				{

					// IF THE USER HAS SUCCESSFULLY LOGGED OUT FROM THE SERVER THEN WE WILL LOGOUT
					// THEM FROM CLIENT SIDE AS WELL BY DESTROYING THE VARIABLES.
					if (data ["success"])
					{

						// IF THERE WAS NO COOKIE SAVED IN USER'S BROWSER OR IT WAS INVALID,
						// THEN WE WILL CREATE ANOTHER COOKIE AND SAVE
						//  USER ID IN COOKIE.
						this.cookieService.set ("lk_user_id", '', 60 * 60 * 24 * 180, '/', this.hostName);

						// SAVE A RANDOMLY GENERATED PASSWORD IN USER'S BROWSER. WE WILL USE IT TO VERIFY IF THE COOKIE
						// IS VALID OR NOT. WE WILL ALSO SAVE THIS COOKIE PASSWORD IN DATABASE.
						this.cookieService.set ("lk_user_code", '', 60 * 60 * 24 * 180, '/');
						
						sessionStorage.removeItem ('lk_username'); // REMOVING USERNAME FROM SESSION STORAGE.
						sessionStorage.removeItem ('lk_user_id'); // REMOVING USER ID FROM SESSION STORAGE.
						sessionStorage.removeItem ('avatarData'); // REMOVING AVATAR DATA SESSION STORAGE
						sessionStorage.removeItem ('lkphoneData'); // REMOVING LKPHONE DATA SESSION STORAGE

						if (data ["screenname"])
						{
							// STORING USERNAME IN GLOBAL VARIABLE SO IT CAN BE USED ANYWHERE IN THE APP.
							this.helper.userscreenname = data ["screenname"];
						}
						else
						{
							this.helper.userscreenname = 'Anonymous User';
						}

						this.helper.refreshScreenName.next(this.helper.userscreenname);

						// SET CURRENT USER ROLE ID TO NULL
						this.helper.userCurrentRole = null;

						// SET USER ID TO NULL.
						this.helper.user_id = null;

						// CLEAR USER BUNDLE DATA.
						this.helper.userBundleData = null;

						// SET CURRENT USER ROLE NAME TO NULL
						this.helper.userCurrentRoleName = null;

						// PROCESS SITE JUMPCODE FOR CURRENT USER.
						this.helper.processCurrentUserJumpcodes ();

						console.log ("user logged out = ", this.helper.isLoggedIn ());

						// SETTING USER LOGGED-IN VARIABLE TO FALSE, AS USER IS NO LONGER LOGGED IN.
						this.helper.isUserLoggedIn = false;
						sessionStorage.clear(); // CLEARING THE LOCAL SESSION STORAGE.
						this.helper.processSiteAvatar.next(true); // CHANGE THE AVATAR IN TOP BAR.
						resolve (true); // RESOLVING THE PROMISE.
					}
					else
					{
						// THERE WAS SOME ISSUE ON THE SERVER SIDE. SO WE CAN'T LOGOUT THE USER AT THIS TIME.
						// RESOLVING THE PROMISE WITH FALSE FLAG.
						resolve (false);
					}
				});
			}
		});
	}

	// THIS FUNCTION WILL SET LOGIN COOKIE IF THERE IS NO PREVIOUS COOKIE.
	public createCookie()
	{
		// GETTING THE COOKIE VALUE FROM USER'S BROWSER.
		let passwordCookie = this.cookieService.get ("lk_user_code");
		
		console.log ("passwordCookie stored: ", passwordCookie, "db passwordCookie: ", this.user ['cookiepassword'], "this.hostName", this.hostName);

		// IF THE VALUE SAVED IN USER'S BROWSER IS EQUAL TO THE DATABASE VALUE
		// THEN THEY ALREADY HAVE THE COOKIE, NO NEED TO DO ANYTHING ELSE.
		if (passwordCookie && passwordCookie == this.user ['cookiepassword'])
		{
			return true;
		}
		
		// IF THERE WAS NO COOKIE SAVED IN USER'S BROWSER OR IT WAS INVALID,
		// THEN WE WILL CREATE ANOTHER COOKIE AND SAVE
		//  USER ID IN COOKIE.
		const dateNow = new Date();
		dateNow.setMinutes(dateNow.getMinutes() + this.cookiesMaxAge);

		// IF THERE WAS NO COOKIE SAVED IN USER'S BROWSER OR IT WAS INVALID,
		// THEN WE WILL CREATE ANOTHER COOKIE AND SAVE
		//  USER ID IN COOKIE.
		this.cookieService.set ("lk_user_id", this.user ['user_id'], dateNow, '/', this.hostName);

		// IF THERE WAS NO COOKIE SAVED IN USER'S BROWSER OR IT WAS INVALID,
		// THEN WE WILL CREATE ANOTHER COOKIE AND SAVE
		//  USER ID IN COOKIE.
		this.cookieService.set ("lk_user_id", this.user ['user_id'], dateNow, '/', this.hostName);
		
		// SAVE A RANDOMLY GENERATED PASSWORD IN USER'S BROWSER. WE WILL USE IT TO VERIFY IF THE COOKIE
		// IS VALID OR NOT. WE WILL ALSO SAVE THIS COOKIE PASSWORD IN DATABASE.
		this.cookieService.set ("lk_user_code", this.user ['cookiepassword'], dateNow, '/', this.hostName);
	}

	// THIS FUNCTION WILL SET QRCODE COOKIE IF THERE IS NO PREVIOUS COOKIE.
	public createQrCookie()
	{
		// GETTING THE COOKIE VALUE FROM USER'S BROWSER.
		let passwordCookie = this.cookieService.get ("lk_qr_code");
		
		console.log ("passwordCookie stored: ", passwordCookie, "db passwordCookie: ", this.user ['cookiepassword'], "this.hostName", this.hostName);
		
		// IF THE VALUE SAVED IN USER'S BROWSER IS EQUAL TO THE DATABASE VALUE
		// THEN THEY ALREADY HAVE THE COOKIE, NO NEED TO DO ANYTHING ELSE.
		if (passwordCookie && passwordCookie == "true")
		{
			return true;
		}
		
		// CALCULATE THE EXPIRATION TIME IN MINUTES FOR 10 YEARS
		const tenYearsInMinutes = 365 * 24 * 60 * 10;
		const expirationDate = new Date();
		expirationDate.setMinutes(expirationDate.getMinutes() + tenYearsInMinutes);
		
		
		// SAVE A RANDOMLY GENERATED PASSWORD IN USER'S BROWSER. WE WILL USE IT TO VERIFY IF THE COOKIE
		// IS VALID OR NOT. WE WILL ALSO SAVE THIS COOKIE PASSWORD IN DATABASE.
		this.cookieService.set ("lk_qr_code", "true", expirationDate, '/', this.hostName );
	}
	
	
	// THIS METHOD WILL CREATE THE LOGIN INSTANCE, SET COOKIES ETC.
	createLoginInstance (data)
	{
		// RETURNING A PROMISE.
		return new Promise (async (resolve, reject) =>
		{
			// SETTING LOGIN COOKIE.
			this.createCookie ();
			
			// SETTING FIRST TIME APP COOKIE.
			this.createQrCookie();
			
			// SETTING IT TRUE. SO WE CAN CHECK THIS VARIABLE AND SEE IF THE USER IS LOGGED IN OR NOT.
			this.helper.setData ("isUserLoggedIn", true);
			sessionStorage.setItem ('lk_username', this.user ['username']); // STORING USERNAME IN LOCAL STORAGE.
			sessionStorage.setItem ('lk_user_id', this.user ['user_id']); // STORING USER ID IN LOCAL STORAGE.

			// IF USER BUNDLE INFORMATION FOUND.
			if (data ['bundleinfo'])
			{
				// LKP ADMIN BUNDLE DATA.
				this.helper.bundleData = data ['bundleinfo'];

				// IF LKPHONE ARRAY IS NOT EMPTY THEN UPDATE THE LKPHONE VARIABLES IN HELPER SERVICE.
				if (data ['lkphone_array'] && data ['lkphone_array'].length > 0)
				{
					let selectedLKPhone = this.updateLKPhoneVariables (data ['lkphone_array']);

					// IF THE SELECTED LKPHONE IS NOT NULL THEN GET THE INVISIPHONE NUMBERS.
					if (selectedLKPhone && typeof selectedLKPhone.lkphone_id != 'undefined')
					{
						// GET INVISIPHONE NUMBERS.
						this.dbService.getInvisiphoneNumbers (selectedLKPhone.lkphone_id).subscribe (data =>
						{
							// IF THE RESPONSE IS SUCCESSFUL AND THERE ARE SOME INVISIPHONE NUMBERS
							// THEN UPDATE THE INVISIPHONE VARIABLES IN HELPER SERVICE.
							if (data.success)
							{
								this.updateInvisiPhoneVariables (data.phonelist);
							}
						});
					}
				}
			}

			// STORE THE USER ID IN HELPER VARIABLE.
			this.helper.user_id = this.user ['user_id'];

			// STORING USERNAME IN GLOBAL VARIABLE SO IT CAN BE USED ANYWHERE IN THE APP.
			this.helper.userscreenname = this.user ['namefirst'];
			this.helper.refreshScreenName.next (this.helper.userscreenname);

			this.helper.loginAttemptCompleted = true;

			// THIS IS SAME AS THE USER ID AND WILL INDICATE THAT THE USER HAS ACTUALLY SUBSCRIBED TO
			// SOME ACCOUNT PLAN.
			this.helper.subscriber_id = this.helper.getSubscriberId ();

			// GETTING CURRENT ROLE OF THE USER. ARE THEY ADMIN? SUPER ADMIN? HOST? SUPER HOST OR GENERAL USER.
			this.helper.userCurrentRole = data ['user_role'].role_id;

			// GETTING CURRENT ROLE NAME OF THE USER.
			this.helper.userCurrentRoleName = data ['user_role'].role;

			// BOOLEAN VARIABLE TO SHOW IF THE CURRENT USER IS AN ADMIN
			this.helper.isAdmin = (data ['user_role'].role_id === "A" || data ['user_role'].role_id === "SA") ? true: false;

			// PROCESS SITE JUMPCODE FOR CURRENT USER.
			this.helper.processCurrentUserJumpcodes ();

			// PROCESS USER AVATAR.
			this.helper.processSiteAvatar.next (true);

			resolve(true);
		});
	}

	// UPDATE LKPHONE RELATED VARIABLES IN HELPER SERVICE. SO THESE VARIABLES/DATA CAN BE AVAILABLE IN WHOLE APP.
	updateLKPhoneVariables (data: any)
	{
		// MAKE SURE VALID DATA IS PROVIDED.
		if (data && data.length > 0)
		{
			if (this.helper.lkphone_array.length > 0)
			{
				data.map((item: any) =>
				{
					let dbItem = this.helper.lkphone_array.find((x: any)=> x.lkphone_id === item.lkphone_id);

					if(dbItem) {
						item['lkp-bundle_id'] = dbItem['lkp-bundle_id'];
					}
					return item;
				});

			}

			this.helper.setData ("lkphone_array", data);

			// SET THE FIRST PHONE AS SELECTED ONE.
			let selectedLKPhone = data [0];
			this.helper.setData ("selectedLKPhone", selectedLKPhone);
			this.helper.setData ("lkphone_id", selectedLKPhone.lkphone_id);
			this.helper.setData ("userBundleData", this.helper.bundleData.filter (x => x['lkp-bundle_id'] == selectedLKPhone['lkp-bundle_id']));
			return selectedLKPhone;
		}
	}

	// UPDATE INVISIPHONE RELATED VARIABLES IN HELPER SERVICE. SO THESE VARIABLES/DATA CAN BE AVAILABLE IN WHOLE APP.
	updateInvisiPhoneVariables (data: any)
	{
		// VALIDATE THE PROVIDED DATA FIRST.
		if (data && data.length > 0)
		{
			let selectedInvisiPhone = data[0];
			this.helper.setData ("selectedInvisiPhone", selectedInvisiPhone);
			this.helper.setData ("invisiphone_number", selectedInvisiPhone.phonenumber);

			// TRIGGER THE OBSERVER TO UPDATE THE INVISIPHONE NUMBER LIST.
			this.helper.invisiphoneNumbersArray.next (data || []);
			return selectedInvisiPhone;
		}
	}

	// THIS FUNCTION WILL ATTEMPT TO LOG THE USER IN WHEN THEY OPEN THE APP.
	// IT WILL CHECK FOR COOKIE IF IT EXISTS THEN IT WILL GET THE USER DATA OBJECT
	// AND RETURN TO THE APP. IF IT DOES NOT EXIST THEN IT WILL NOT DO ANYTHING.
	// ITS PURPOSE IS TO SEAMLESSLY LOGIN THE USER IF THERE IS A COOKIE.
	async attemptToLogin()
	{
		// GETTING COOKIE FROM USER'S BROWSER
		let passwordCookie = this.cookieService.get ("lk_user_code");
		console.log('attemptToLogin passwordCookie', passwordCookie);

		// IF THE COOKIE EXISTS THEN WE WILL SEE IF IT IS VALID OR NOT.
		if (passwordCookie && typeof passwordCookie !== "undefined")
		{
			// VALIDATING THE COOKIE STORED IN USER'S BROWSER. WE WILL COMPARE IT WITH
			// THE COOKIE PASSWORD STORED IN DATABASE. IF IT MATCHES THEN THE COOKIE IS VALID.
			this.dbService.validateCookie (passwordCookie).subscribe (data => {
				console.log ("login data from passwordCookie: ", data);
				// IF THE COOKIE IS VALID . . .
				if (data ['success'])
				{
					data ['user']['cookiepassword'] = passwordCookie;
					//. . . WE GOT THE USER OBJECT, SO LET'S LOG IN THE USER AND SEND DATA TO APP.
					this.user = data ['user']; // STORING USER DATA FROM DATABASE INTO CLASS VARIABLE.
					this.createLoginInstance (data).then(()=>
					{
						this.createLkAvatarInstance();
					});
				}
				else
				{
					sessionStorage.removeItem ('lk_username'); // REMOVING USERNAME FROM SESSION STORAGE.
					sessionStorage.removeItem ('lk_user_id'); // REMOVING USER ID FROM SESSION STORAGE.
					sessionStorage.removeItem ('avatarData'); // REMOVING AVATAR DATA SESSION STORAGE
					sessionStorage.removeItem ('lkphoneData'); // REMOVING LKPHONE DATA SESSION STORAGE
					
					// PROCESS SITE JUMPCODE FOR CURRENT USER.
					this.helper.processCurrentUserJumpcodes ();
					
					this.helper.loginAttemptCompleted = true;

					// FAILED TO LOG USER IN. WE DON'T NEED TO DO ANYTHING.
					console.log ("Code87, login failed.", data)
				}
			});
		}
		else
		{
			sessionStorage.removeItem ('lk_username'); // REMOVING USERNAME FROM SESSION STORAGE.
			sessionStorage.removeItem ('lk_user_id'); // REMOVING USER ID FROM SESSION STORAGE.
			sessionStorage.removeItem ('avatarData'); // REMOVING AVATAR DATA SESSION STORAGE
			sessionStorage.removeItem ('lkphoneData'); // REMOVING LKPHONE DATA SESSION STORAGE

			// PROCESS SITE JUMPCODE FOR CURRENT USER.
			this.helper.processCurrentUserJumpcodes ();
			
			this.helper.loginAttemptCompleted = true;

			// COOKIE DOES NOT EXIST. WE DON'T NEED TO DO ANYTHING.
			console.log ("passwordCookie does not exist");
		}
	}

	// STORE AVATAR ID IN GLOBAL.
	createLkAvatarInstance()
	{
		if(sessionStorage.getItem ('avatarData') && sessionStorage.getItem ('avatarData') != 'undefined' && sessionStorage.getItem ('lkphoneData') && sessionStorage.getItem ('lkphoneData') != 'undefined')
		{
			let avatarData = sessionStorage.getItem ('avatarData');
			let lkphoneData = sessionStorage.getItem ('lkphoneData');
			
			// STORE AVATAR DATA.
			this.helper.setData ("avatarData", JSON.parse(avatarData));
			this.helper.setData ("lkphoneData", JSON.parse(lkphoneData));
			this.helper.loginAttemptCompleted = true;
			
		}else{
			
			this.getAvatarId(this.helper.lkphone_id).then((data: any)=>
			{
				if (data.success)
				{
					// STORE AVATAR DATA IN HELPER.
					this.helper.setData ("avatarData", data.avatar);
					// STORE AVATAR DATA IN SESSION STORAGE.
					sessionStorage.setItem ('avatarData',JSON.stringify(data.avatar));
					// STORE LKPHONE DATA IN HELPER.
					this.helper.setData ("lkphoneData", data.lkphone);
					// STORE LKPHONE DATA IN SESSION STORAGE.
					sessionStorage.setItem ('lkphoneData',JSON.stringify(data.lkphone));
					
					this.helper.loginAttemptCompleted = true;
				}
				else{
					this.helper.loginAttemptCompleted = true;
				}
			});
		}
	}


	// THIS FUNCTION WILL ATTEMPT TO LOG THE USER IN BY SCAN KINECT RESPONSE.
	// IT WILL CHECK FOR COOKIE IF IT EXISTS THEN IT WILL GET THE USER DATA OBJECT
	// AND RETURN TO THE APP. IF IT DOES NOT EXIST THEN IT WILL NOT DO ANYTHING.
	// ITS PURPOSE IS TO SEAMLESSLY LOGIN THE USER IF THERE IS A COOKIE.
	doAutoLogin(user: any)
	{
		// RETURNING A PROMISE.
		return new Promise ((resolve, reject) =>
		{
			let postObj =
			{
				device_id: this.helper.device_id,
				user_id: user.user_id,
				user: user
			}

			// VALIDATING THE COOKIE STORED IN USER'S BROWSER. WE WILL COMPARE IT WITH
			// THE COOKIE PASSWORD STORED IN DATABASE. IF IT MATCHES THEN THE COOKIE IS VALID.
			this.dbService.doAutoLogin (postObj).subscribe (data =>
			{
				console.log ("login data from passwordCookie: ", data);
				// IF THE COOKIE IS VALID . . .
				if (data ['success'])
				{
					//. . . WE GOT THE USER OBJECT, SO LET'S LOG IN THE USER AND SEND DATA TO APP.
					this.user = data ['user']; // STORING USER DATA FROM DATABASE INTO CLASS VARIABLE.
					this.createLoginInstance (data).then(()=>
					{
						this.createLkAvatarInstance();
						resolve(true);
					});
				}

			}, (error: any)=> {
				console.log ("auto login api error: ", error);
				reject();
			});
		});
	}

	deleteScanSuccess(scankinect: any)
	{
		// RETURNING A PROMISE.
		return new Promise ((resolve, reject) =>
		{
			let postObj = {scankinect: scankinect};
			// VALIDATING THE COOKIE STORED IN USER'S BROWSER. WE WILL COMPARE IT WITH
			// THE COOKIE PASSWORD STORED IN DATABASE. IF IT MATCHES THEN THE COOKIE IS VALID.
			this.dbService.deleteScanSuccess (postObj).subscribe (data =>
			{
				resolve(true);
			}, (error: any)=>{
				console.log (": ", error);
			});
		});
	}

	getAvatarId(lkphoneId: any)
	{
		// RETURNING A PROMISE.
		return new Promise ((resolve, reject) =>
		{
			// VALIDATING THE COOKIE STORED IN USER'S BROWSER. WE WILL COMPARE IT WITH
			// THE COOKIE PASSWORD STORED IN DATABASE. IF IT MATCHES THEN THE COOKIE IS VALID.
			this.dbService.getAvatarId (lkphoneId).subscribe (data =>
			{
				resolve(data);
			}, (error: any)=>{
				console.log (": ", error);
			});
		});
	}
}
