/*
        FILENAME: card.component.ts
          AUTHOR: SAAD BIN ZIA
         SUMMARY: HOLDS THE CARD TO BE SHOWN IN DIALOG.
         PURPOSE: TO ALLOW ADMIN TO BE ABLE TO VIEW AND ADD ENTITIES
LAST COMMIT DATE: 23 Ayg 2023
 */

import { Component, Input } from "@angular/core";


// COMPONENT DECLARATION. HERE WE CAN DEFINE HTML TEMPLATE, CSS FILES AND COMPONENT OPTIONS.
@Component({
	selector: 'app-dialog-card',
	templateUrl: './dialog-card.component.html',
	styleUrls: ['./dialog-card.component.scss'],
})

// DECLARING THE ExtensionsTabComponent CLASS WITH EXPORT SO THAT WE CAN IMPORT THIS INTO ANOTHER COMPONENT
export class DialogCardComponent {

  // TYPE: FREE | PAID
	@Input() type: any;

  //HEADING
	@Input() heading: any;

  // SUBTITLE
	@Input() subtitle: any;

  //BOTTOM-LINE
	@Input() bottomLine: any;
}
