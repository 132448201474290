/*
		   FILENAME: lk_client/src/app/services/global-error-handler.service.ts
	         AUTHOR: Anindya Dutta
 	        SUMMARY: THIS SERVICE FILE IS USED FOR HANDLING ANY ERROR GLOBALLY.
            PURPOSE: HANDLING ANY ERROR GLOBALLY.
    IMPORTING FILES: NA
  SUBSCRIBING FILES: app.module.ts
   LAST COMMIT DATE: September 25, 2020.
*/

import { ErrorHandler, Injectable} from '@angular/core';

// @Injectable DECLARES THIS CLASS AS A SERVICE SO THAT WE CAN INJECT THIS SERVICE INTO ANY OTHER COMPONENT OR SERVICE.
@Injectable()

// DECLARING THE GlobalErrorHandlerService CLASS WITH EXPORT SO THAT WE CAN IMPORT THIS SERVICE INTO ANY OTHER COMPONENT OR SERVICE.
export class GlobalErrorHandlerService implements ErrorHandler 
{
  	// THIS FUNCTION WILL BE CALLED WHEN ANY ERROR OCCURED IN ANGULAR.
	handleError(error: any): void 
	{
		console.log('Angular handleError called', error);

		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (chunkFailedMessage.test(error)) 
		{
			setTimeout(() => 
			{
				window.location.reload();
			}, 2000);
		}
	}
}