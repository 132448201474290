import { Component } from '@angular/core';
import {HelperService} from "../../services/helper.service"

@Component({
  selector: 'app-yourturn',
  templateUrl: './yourturn.component.html',
  styleUrls: ['./yourturn.component.scss']
})
export class YourTurnComponent
{
	// CONFIGURATION FOR ADD NEW LIST BUTTON AND MESSAGES
	topbarConfig: any =
	{
		search: false,
		overviewSwitch: true,
		addNew: false,
	};
	public panelList: any =
	[
		{ entryBtnOption: 'buy', exitBtnOption: 'sell' },
		{ entryBtnOption: 'buy', exitBtnOption: 'sell' },
	];
	public selectedButton: any = 'stock';
	public submitedBtn: any = 'Submit';
	
	constructor
	(
		public helper: HelperService, // LOADING HelperService CLASS WITH ALIAS helper TO GET ACCESS TO THE GLOBAL DATA.
	)
	{
	}
	
	// FUNCTION TO ADD A NEW PANEL WITH DEFAULT STATE (NOT EXPANDED)
	public addPanel(): void
	{
		this.panelList.push ({ entryBtnOption: 'buy', exitBtnOption: 'sell' });
	}
	
	onSubmithandle ()
	{
		this.submitedBtn = 'Submitted';
	}
	
	onSelectedChange(buttonName: string): void
	{
		console.log (buttonName)
		//if (this.selectedButton === buttonName)
		//{
			this.selectedButton = buttonName;
		//}
	}
	
	onConditionButtonChange (type: string, name: string, index: number)
	{
		if (type == "entry")
		{
			this.panelList[index].entryBtnOption = name;
		}
		else
		{
			this.panelList[index].exitBtnOption = name;
		}
	}
}
