<kendo-card class="entity-card">
  <kendo-card-body>
    <div class="type">
      {{type}}
    </div>
    <div>
      <div class="heading">
        {{heading}}
      </div>
      <div class="subtitle">
        {{subtitle}}
      </div>
    </div>
    <div class="bottom-line">
      {{bottomLine}}
    </div>
  </kendo-card-body>
</kendo-card>