/*
           FILENAME: lk_client/src/app/components/utils/editable/editable.component.ts
	         AUTHOR: Zulaikha Moidin, Qasim Zubair
 	        SUMMARY: IT WILL BE ACTIVATED WHEN USER CLICKS ON SCREEN NAME AT RIGHT OF THE HEADER OR ON
 	                 THEN IDENTITY RECTANGLE IN ROOM. IT WILL CREATE AN INPUT FIELD WHERE USER CAN ENTER
 	                 NEW NAME AND IT WILL UPDATE THE NAME IN DATABASE.
            PURPOSE: TO LET USER EDIT THEIR SCREEN NAME.
    IMPORTING FILES: helper.service.ts
  SUBSCRIBING FILES: editable.component.ts | utils.module.ts | helper.service.ts
   LAST COMMIT DATE: July 14, 2020
*/

// IMPORTING THE ANGULAR MODULES FOR PERFORMING BASIC ANGULAR FRAMEWORK OPERATIONS.
import {Component,	Input,	ElementRef, ViewChild,	forwardRef,	OnInit, ChangeDetectorRef, Injector} from '@angular/core';

// TBD
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

// IMPORTING THE TOASTER SERVICE SO THAT WE CAN SHOW THE TOAST MESSAGES AT THE TOP RIGHT OF THE SCREEN.
import {ActiveToast, ToastrService} from "ngx-toastr";

// IMPORTING THE ANGULAR SUBJECT TO USE AS AN OBSERVABLE. 
import {Subject} from "rxjs";

// IMPORTING THE LK HELPER SERVICE TO GET ACCESS TO THE GLOBAL DATA AND SERVICES SO THAT WE CAN USE THE GLOBAL DATA AND FUNCTIONS.
import {HelperService} from "../../../services/helper.service";
import {Router} from '@angular/router';

// DECLARE VARIABLE $ TO ANY TYPE. LATER IT CAN BE CAST TO DESIRED TYPE.
declare var $: any;

// USED TO PROVIDE A CONTROL VALUE ACCESSOR FOR FORM CONTROLS.
const INLINE_EDIT_CONTROL_VALUE_ACCESSOR =
{
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => EditableComponent),
	multi: true
};

// COMPONENT DECLARATION. HERE WE CAN DEFINE HTML TEMPLATE, CSS FILES AND COMPONENT OPTIONS.
@Component
({
	selector: 'app-editable',
	templateUrl: './editable.component.html',
	providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
	styleUrls: ['./editable.component.scss']
})

// DECLARING THE EditableComponent CLASS WITH EXPORT SO THAT WE CAN IMPORT THIS SERVICE INTO ANY OTHER COMPONENT OR SERVICE.
export class EditableComponent implements ControlValueAccessor, OnInit
{
	// VARIABLE DECLARATIONS NEEDED FOR THIS COMPONENT
	// ELEMENT REFERENCE OF INPUT FIELD IN HTML WHERE USERS WILL PUT THEIR NEW SCREEN NAMES.
	@ViewChild ('inlineEditControl', { static: true}) inlineEditControl: ElementRef;
	@Input() label: string = '';  // LABEL VALUE FOR INPUT ELEMENT
	@Input() type: string = 'text'; // THE TYPE OF INPUT ELEMENT
	@Input() required: boolean = false; // IS INPUT REQUIRED?
	@Input() disabled: boolean = false; // IS INPUT DISABLED?
	
	// SCREEN NAME AND USERNAME REFERRED IN THIS COMPONENT ARE SAME.
	// OLD USERNAME OF THE USER. WE WILL KEEP RECORD OF IT IN CASE USER PUTS AN INVALID USERNAME.
	// IF THIS HAPPENS WE WILL REVERT TO OLD USERNAME.
	@Input() oldusername: any;
	@Input() streamId: any; // UNIQUE ID OF THE USER. IT WILL BE UNIQUE TO CURRENT SESSION.
	
	// AN OBSERVABLE THAT WILL SHOW THE INPUT FIELD SO USER CAN EDIT THEIR USERNAME.
	@Input() editName: Subject<boolean>;
	
	// BOOLEAN VARIABLE TO DETERMINE IF THE USERNAME SHOULD BECOME AVAILABLE TO EDIT WHEN THIS COMPONENT
	// STARTS OR NOT.
	@Input() autoEditing: boolean = true;
	public placeholderTxt: string = 'Type name . . .'; // PLACEHOLDER DISPLAY IN INPUT FIELD.
	public value: string = ''; // PRIVATE VARIABLE FOR INPUT VALUE.
	public editing: boolean = false; // IS COMPONENT IN EDIT MODE?
	public onChange: any = Function.prototype; // TRANSCEND THE ONCHANGE EVENT
	public onTouched: any = Function.prototype; // TRANSCEND THE ON TOUCH EVENT.
	
	// WE WILL DISPLAY A MESSAGE IN TOOLTIP WHEN USER START CHANGING THEIR SCREEN NAME.
	// WE WILL STORE THE TOOLTIP OBJECT IN THIS VARIABLE SO WE CAN GET RID OF THE TOOLTIP ONCE
	// THE USERNAME IS CHANGED.
	public nameInfoToast: ActiveToast<any>
	private toastr: ToastrService;
	
	// CLASS CONSTRUCTOR, THIS WILL BE FIRST FUNCTION TO BE EXECUTED WHEN THIS CLASS LOADS.
	// HERE WE WILL TELL ANGULAR TO INJECT A DEPENDENCY BY SPECIFYING A CONSTRUCTOR
	// PARAMETER WITH THE DEPENDENCY TYPE.
	constructor
	(
		private injector:Injector,
		public helper: HelperService,
		public cdr: ChangeDetectorRef,
        public router: Router
	)
	{}
	
	// ANGULAR LIFE CYCLE HOOK THAT WILL BE CALLED WHEN THIS COMPONENT IS INITIALIZED.
	ngOnInit()
	{
		this.helper.refreshScreenName.subscribe (screenname =>
		{
			this.value = screenname;
			this.cdr.detectChanges();
		});

		// IF THE VARIABLE HAS A VALUE AND ITS NOT UNDEFINED. IT WILL HAVE VALUE IF USER HAS CLICKED ON SCREEN NAME
		// TO EDIT IT.
		if (this.editName)
		{
			// SUBSCRIBING TO VALUE CHANGE FOR editName VARIABLE.
			this.editName.subscribe (value =>
			{
				// IF VALUE IS EQUAL TO STREAM ID OF CURRENT USER THEN WE WILL INITIATE THE EDITING PROCESS.
				if (value == this.streamId)
				{
					this.editing = true; // DISPLAYING THE INPUT FIELD.
					this.edit (); // FUNCTION TO START EDITING OF SCREEN NAME.
				}
				else
				{
					// HIDING THE INPUT ELEMENT.
					this.editing = false;
				}
			})
		}
	}
	
	// REDIRECT TO ACCOUNT PAGE
	public redirectToAccount()
    {
        sessionStorage.setItem('edit_name', 'editMode')
        this.router.navigate(['/lkphone']);
    }
	
	// CONTROL VALUE ACCESSORS FOR NG MODEL
	get getValue(): any
	{
		return this.value;
	}
	
	// REQUIRED FOR CONTROL VALUE ACCESSOR INTERFACE
	writeValue(value: any)
	{
		this.value = value;
	}
	
	// REQUIRED FOR CONTROL VALUE ACCESSOR INTERFACE
	public registerOnChange(fn: (_: any) => {}): void
	{
		this.onChange = fn;
	}
	
	// REQUIRED FOR CONTROL VALUE ACCESSOR INTERFACE
	public registerOnTouched(fn: () => {}): void
	{
		this.onTouched = fn;
	}
	
	// DO STUFF WHEN THE INPUT ELEMENT LOSES FOCUS
	onBlur($event)
	{
		this.toastr = this.injector.get(ToastrService);
		// WE WILL HIDE THE INPUT FIELD.
		this.editing = false;
		
		// IF NEW ENTERED VALUE BY USER IS AN EMPTY VALUE THEN WE WILL SET THE OLD USERNAME.
		if (!$event.target.value || $event.target.value == "")
		{
			this.value = this.helper.userscreenname;
		}
		else
		{
			// IF NEW USERNAME IS VALID THEN WE WILL UPDATE IT.
			this.value = $event.target.value;
		}
		
		// REMOVING ANY TOOLTIP MESSAGE.
		this.toastr.clear();
	}
	
	// THIS WILL BE CALLED WHEN USER HITS ENTER AFTER CHANGING THEIR USERNAME.
	onEnter($event)
	{
		this.toastr = this.injector.get(ToastrService);
		// HIDING THE INPUT FIELD.
		this.editing = false;
		this.toastr.clear(); // CLEARING THE TOOLTIP MESSAGE.
	}
	
	// FUNCTION TO BE CALLED ON KEY UP EVENT. WE CAN LISTEN TO ENTER KEY.
	public onKeyupEvent($event)
	{
		// IF THE ENTER KEY WAS PRESSED?
		if ($event.toLowerCase() == "enter")
		{
			//this.enterClicked = true;
		}
	}
	
	// STARTING THE PROCESS OF EDITING USERNAME.
	// WE WILL DISPLAY THE INPUT FIELD AND FOCUS IT. SO USER CAN START TYPING.
	edit()
	{
		// IF THE INPUT FIELD IS DISABLED THEN WE WON'T EXECUTE THE PROCESS.
		if (this.disabled)
		{
			return; // KILLING THE FUNCTION.
		}
		
		// DISPLAYING THE INPUT FIELD SO USER CAN CHANGE THEIR USERNAME.
		this.editing = true;
		
		// AFTER 300 MILLISECONDS WE WILL FOCUS THE INPUT FIELD.
		setTimeout(()=>
		{
			$('#userinputbox').focus();
		},300);
	}
	
	// FUNCTION TO ADJUST THE WIDTH/SIZE OF INPUT ELEMENT. THIS WILL BE CALLED ON WINDOW RESIZE.
	public resizeInput()
	{
		// GETTING WIDTH OF THE INPUT ELEMENT.
		let textwidth = $('#hideusername').width();
		$('#userinputbox').css('width', textwidth+'px'); // APPLYING NEW WIDTH TO CSS.
	}
	
	// THIS WILL BE CALLED WHEN INPUT FIELD IS FOCUSED, WE WILL DISPLAY A NOTIFICATION.
	inputFocused ()
	{
		this.toastr = this.injector.get(ToastrService);
		// WE WILL NOT DISPLAY THIS NOTIFICATION IS USER IS ON MOBILE DEVICE.
		if (this.helper.deviceType!="mobile")
		{
			this.nameInfoToast = this.toastr.info ('After entering your name, press enter.');
		}
	}
}
