/*
	       AUTHOR: TBD
 	      SUMMARY: TBD
          PURPOSE: TBD
  DEPENDENT FILES: TBD
  REFERRING FILES: TBD
 LAST COMMIT DATE: TBD
*/

// TBD
import {Directive, ElementRef, OnInit} from '@angular/core';
import {NgControl} from '@angular/forms';

// TBD
@Directive
({
    selector: '[autoSpellCheck]'
})

// TBD
export class AutoSpellCheckDirective implements OnInit
{
  // TBD
  constructor(private el: ElementRef, private control: NgControl)
  {
  }

  // TBD
  ngOnInit()
  {
    this.control.valueChanges.subscribe((value) =>
    {
      this.spellCheck(value);
    });
  }

  // TBD
  public spellCheck(value: string): void
  {
    // CARRY OUT SPELL CHECK CODE HERE
    if (value === 'bad')
    {
      // Something
    }
  }
}
