/*
 AUTHOR: Zulaikha Moidin, Qasim Zubair
 SUMMARY: THIS FILE WILL BE MODIFIED TO CONTAIN ONLY THOSE MODULES THAT ARE NEEDED TO RUN THE COMPONENT THAT WILL BE ASSIGNED FOR DEVELOPMENT IN THE DEVELOPMENT PHASE. IN PRODUCTION IT WILL CONTAIN ALL OF THE MODULES.
 PURPOSE: THIS FILE CONTAINS ALL THE MODULES THAT ARE NECESSARY TO RUN THE APPLICATION.
 DEPENDENT FILES: N/A
 REFERRING FILES: main
 LAST COMMIT DATE: July 15, 2020
 */

// STANDARD IMPORTS
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

// KENDO IMPORTS
import {DropDownListModule, DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {PopupModule} from '@progress/kendo-angular-popup';
import {LayoutModule} from '@progress/kendo-angular-layout';
import {ToolBarModule} from '@progress/kendo-angular-toolbar';
import {DialogModule, WindowModule} from '@progress/kendo-angular-dialog';
import {DatePickerModule, DateInputsModule} from '@progress/kendo-angular-dateinputs';
import {UploadModule} from '@progress/kendo-angular-upload';
import {TreeViewModule} from '@progress/kendo-angular-treeview';
import {InputsModule} from '@progress/kendo-angular-inputs';
import { IntlModule } from "@progress/kendo-angular-intl";

// OTHER LIBRARIES
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {CookieService} from 'ngx-cookie-service';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';

// APP COMPONENTS
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';

// LETSKINECT SHARED COMPONENTS
import {UtilsModule} from './components/utils/utils.module';
import {PageNotFoundComponent} from './components/utils/page-not-found/page-not-found.component';

// LETSKINECT SERVICES
import {InitProvider} from './services/init.provider';
import {DbService} from './services/db.service';

// IMPORTING THE LK HELPER SERVICE TO GET ACCESS TO THE GLOBAL DATA AND SERVICES SO THAT WE CAN USE THE GLOBAL DATA AND FUNCTIONS.
import {HelperService} from './services/helper.service';
import {LoginService} from './services/login.service';

// IMPORTING GLOBAL ERROR HANDLER.
import {GlobalErrorHandlerService} from './services/global-error-handler.service';

// LETSKINECT DIRECTIVES
import {AutoSpellCheckDirective} from './directives/spellcheck.directive';
import {CheckForbiddenWord} from './pipes/checkforbidden.pipe';
import {FindReplacePipe} from './pipes/findreplace.pipe';
import {TransformLink} from './pipes/clickablelink.pipe';
import {SafePipe} from './pipes/safe.pipe';
import {MenuModule} from '@progress/kendo-angular-menu';
import {SharedModule} from './components/shared/shared.module';
// import { AngularFormsInputMasksModule } from 'angular-forms-input-masks';

// IMPORTING THE GLOBAL DIALOG COMPONENT.
import { GlobalDialogComponent } from './components/utils/global-dialog/global-dialog.component';
import { DialogCardComponent } from './components/utils/dialog-card/dialog-card.component';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { KipscreditsComponent } from './components/utils/kipscredits/kipscredits.component';
import { PointsCounterComponent } from "./components/utils/kipscredits/pointscounter.component";
import { EditorModule } from '@progress/kendo-angular-editor';
import { QRCodeModule } from 'angularx-qrcode';
import { TradeIdeaComponent } from './components/tradeidea/tradeidea.component';
import {GridModule} from "@progress/kendo-angular-grid"
import {PagerModule} from "@progress/kendo-angular-pager";
import { TradeNowComponent } from './components/tradenow/tradenow.component';
import {TradeHistoryComponent} from './components/tradehistory/tradehistory.component';
import { AvatarManagerComponent } from './components/avatarmanager/avatarmanager.component';
import { LinksComponent } from './components/links/links.component';
import {YourTurnComponent} from './components/yourturn/yourturn.component'
import { LegComponent } from "./components/tradenow/leg/leg.component";
import {IframeService} from "./services/iframe.service"
import {RouteReuseStrategy} from "@angular/router"
import {CustomReuseStrategy} from "./customreusestrategy"

// CONFIGURATION FOR SOCKET.IO FILE. WE NEED TO PASS OPTIONS SO SOCKET CAN PERFORM PROPERLY.
// WE ARE USING SOCKET TO COMMUNICATE BETWEEN CLIENT AND SERVER.
const config: SocketIoConfig =
    {
        url: environment.socket, // URL OF THE NODE SERVER.
        options: {path: environment.path, transports: ['websocket']} // PATH OF SOCKET.IO. IT WILL BE CURRENT PATH BY DEFAULT.
    };

// STARTING A PROVIDER THAT WILL LOAD REQUIRED DATA WHICH IS NEED TO RUN THE APP.
// LIKE STIEVARIABLE AND MESSAGE DATA.
export function initProviderFactory (provider: InitProvider)
{
    // IF THERE IS A PROVIDER TO BE LOADED ON APP START?
    if (typeof provider !== 'undefined')
    {
        // CALLING THE LOAD FUNCTION IN THAT PROVIDER. THE PROVIDER'S NAME IS INIT.PROVIDER.
        // IT WILL LOAD DATA FROM DATABASE.
        return () => provider.load ();
    }
}

// AN ANGULAR DECORATOR THAT IDENTIFIES THE MODULE'S OWN COMPONENTS, DIRECTIVES, AND PIPES. SO THAT EXTERNAL COMPONENTS CAN USE THEM
@NgModule (
    {
		declarations: [
			AppComponent,
			PageNotFoundComponent,
			GlobalDialogComponent,
			AutoSpellCheckDirective,
			FindReplacePipe,
			TransformLink,
			SafePipe,
			CheckForbiddenWord,
			KipscreditsComponent,
			KipscreditsComponent,
			PointsCounterComponent,
			DialogCardComponent,
			TradeIdeaComponent,
			TradeNowComponent,
			LegComponent,
			TradeHistoryComponent,
			AvatarManagerComponent,
			LinksComponent,
			YourTurnComponent
		],
		imports: [
			CommonModule,
			AppRoutingModule,
			UtilsModule,
			WindowModule,
			DialogModule,
			BrowserModule,
			HttpClientModule,
			BrowserAnimationsModule,
			NgSelectModule,
			DropDownListModule,
			FormsModule,
			UploadModule,
			TreeViewModule,
			NgxExtendedPdfViewerModule,
			ToastrModule.forRoot ({
				timeOut: 10000,
				preventDuplicates: true
			}),
			SocketIoModule.forRoot (config),
			ButtonsModule,
			DropDownsModule,
			PopupModule,
			ReactiveFormsModule,
			ToolBarModule,
			LayoutModule,
			DatePickerModule,
			MenuModule,
			SharedModule,
			QRCodeModule,
			InputsModule,
			provideFirebaseApp (() => initializeApp (environment.firebase)),
			provideAuth (() => getAuth ()),
			provideFirestore (() => getFirestore ()),
			EditorModule,
			GridModule,
			PagerModule,
			IntlModule,
			DateInputsModule
			// AngularFormsInputMasksModule
		],
    providers: [
        CookieService,
        InitProvider,
        DbService,
        FindReplacePipe,
        TransformLink,
        SafePipe,
        CheckForbiddenWord,
        LoginService,
		IframeService,
        HelperService,
        {
            provide: APP_INITIALIZER,
            useFactory: initProviderFactory,
            deps: [InitProvider],
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService
        },
		{ provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
	],
    bootstrap: [AppComponent],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ]
})

// EXPORTING THE CLASS INTO THE APP.
export class AppModule
{
}
