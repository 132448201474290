<div class="container">
  <div class="image">
    <img src="/assets/images/404.svg" alt="Image" />
  </div>
  <div class="bold-text">
    Whoops! We couldn’t find what you’re looking for.
  </div>
  <div class="text">
    If you are using a valid link, it could be because we are <br/> refreshing our database, please revisit after two hours.    
  </div>
</div>
