<div class="credits-box"
	*ngIf="
		helper.isUserLoggedIn &&
	    helper.getData('selectedInvisiPhone') &&
	    helper.getData('selectedLKPhone')
">
	
	<app-counter
		[pointType]="'C'"
		[newPoints]="(helper.getData('selectedInvisiPhone')).phonecreditsremaining"
	/>
	<app-counter
		[pointType]="'K'"
		[newPoints]="(helper.getData('selectedLKPhone')).kips"
	/>
</div>
