<div class="trade-card-body">
	<div *ngFor="let panel of panelList; let i = index">
	<div class="d-flex align-items-start justify-content-between">
		<div class="counting-number">Leg {{i + 1}} </div>
		<kendo-expansionpanel
			[expanded]="panel.isExpanded"
			(expandedChange)="togglePanel(i)"
			[svgExpandIcon]="chevronDownIcon"
			[svgCollapseIcon]="chevronRightIcon"
		>
			<div class="flex-container2">
				<ng-template kendoExpansionPanelTitleDirective *ngIf="!panel.isExpanded">
					<div class="k-panelbar-item-custom-text">
						<div *ngIf="tradeType">
							<span class="k-text-green">Buy:</span> {{ tradeType }} |
						</div>
						<div *ngIf="panel.entryquantity">
							<span class="k-text-green">Quantity:</span>  {{ panel.entryquantity }} |
						</div>
						<div *ngIf="panel.entrylimitprice">
							<span class="k-text-green">Price:</span> $ {{ panel.entrylimitprice }} |
						</div>
						<div *ngIf="panel.exittakeprofittype !== 'N' || panel.exittrailingstoplosstype !== 'N' || panel.exitstoplosstype !== 'N' || panel.exittimedexittype !== 'N'">
							<span class="k-text-green">Exit:</span> {{ panel.exittakeprofittype !== 'N' ? 'Take profit, ' : '' }} {{ panel.exittrailingstoplosstype !== 'N' ? 'Trailing Stop Loss, ' : '' }} {{ panel.exitstoplosstype !== 'N' ? 'Stop Loss, ' : '' }} {{ panel.exittimedexittype !== 'N' ? 'Timed Exit ' : '' }}
						</div>
					</div>
				</ng-template>
				<kendo-expansionpanel-content *ngIf="panel.isExpanded">
					<div class="content-body">
						<div class="flex-container">
							<div class="flex-item flex-item-left">
								<div class="heading">Entry Condition</div>
								<div class="content-bg d-flex align-items-center">
									<div spellcheck="false" class="text-center"
										[style.width.%]="100">
										<div class="entry-content">
											<div class="d-flex justify-content-between align-items-center" [style.width.px]="350">
												<div class="large-section">
													<div class="label"> Action:</div>
													<div class="action-buttons">
														<div class="button active">
															Buy
														</div>
														<div class="button">
															Sell
														</div>
													</div>
												</div>
												<div class="small-section">
													<div class="label"> Quantity:</div>
													<div class="toggle-number" [style.width.px]="100">
														<img src="assets/images/trade/minus.svg" class="ms-2 minus-btn" (click)="decreaseQuantity(panel)"/>
														<input
															class="k-input-inner input-padding"
															[style.width.px]="50"
															[style.height.px]="40"
															placeholder="1"
															kendoTextBox
															[(ngModel)]="panel.entryquantity"
														/>
														<img src="assets/images/trade/plus.svg" class="me-2 plus-btn" (click)="increaseQuantity(panel)"/>
													</div>
												</div>
											</div>

											<div class="d-flex justify-content-between align-items-center" [style.width.px]="350">
												<div class="large-section">
													<div class="label"> Order Type:</div>
													<div class="option-buttons">
														<div class="button active">
															Market
														</div>
														<div class="button">
															Limit
														</div>
														<div class="button">
															Buy Stop
														</div>
													</div>
												</div>
												<div class="small-section">
													<div class="label">
														Limit:
													</div>
													<input 
														class="k-input-inner input-padding boxed-border" 
														[style.width.px]="100" 
														[style.height.px]="40" 
														kendoTextBox 
														[(ngModel)]="panel.entrylimitprice"
														[disabled]="panel.entryordertype === 'M'" 
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="flex-item flex-item-right">
								<div class="heading">Exit Condition</div>
								<div class="content-bg">
									<div spellcheck="false" class="text-center">
										<div class="exit-content">
											<div class="exit-wrapper">
												<div class="feature-options">
													<div class="checkbox-feature">
														<label
															class="item"
															[for]="'take_profit'"
														>
															<input
																type="checkbox"
																id="take_profit"
																kendoCheckBox
																[checked]="panel.exittakeprofittype != 'N'"
																(change)="onCheckboxChange(panel, 'exittakeprofittype', $event.target.checked)"
															/>
															<span>Take Profit</span>
														</label>
													</div>
													<div class="combine-feature">
														<div class="radio-feature">
															<label class="item">
																<input name="take_profit" type="radio"
																	value="P"
																	[(ngModel)]="panel.exittakeprofittype"
																	kendoRadioButton/>
																<span>Percentage</span>
															</label>
															<label class="item">
																<input name="take_profit" type="radio"
																	value="A"
																	[(ngModel)]="panel.exittakeprofittype"
																	kendoRadioButton/>
																<span>Amount</span>
															</label>
														</div>
														<div class="textbox-feature">
															<input
																class="k-input-inner input-padding boxed-border"
																[(ngModel)]="panel.exittakeprofitvalue"
																[readonly] = "panel.exittakeprofittype === 'N'"
																kendoTextBox
															/>
															<div class="feature-extra">
																<div class="info-img">
																	<img src="assets/images/trade/help.svg" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="exit-wrapper">
												<div class="feature-options">
													<div class="checkbox-feature">
														<label
															class="item"
															[for]="'trailing_stop_loss'"
														>
															<input
																type="checkbox"
																id="trailing_stop_loss"
																#trailing
																[checked]="panel.exittrailingstoplosstype != 'N'"
																(change)="onCheckboxChange(panel, 'exittrailingstoplosstype', $event.target.checked)"
																kendoCheckBox />
															<span>Trailing SL</span>
														</label>
													</div>
													<div class="combine-feature">
														<div class="trailing-sl-continer">
															<div class="custom-label">
																Trigger %
															</div>
															<div class="textbox-feature">
																<input
																	class="k-input-inner input-padding boxed-border"
																	[(ngModel)]="panel.exittrailingstoplossvalue"
																	[readonly] = "panel.exittrailingstoplosstype === 'N'"
																	kendoTextBox
																/>
															</div>
														</div>

														<div class="trailing-sl-continer">
															<div class="custom-label">
																Trailing %
															</div>
															<div class="textbox-feature">
																<input
																	class="k-input-inner input-padding boxed-border"
																	[(ngModel)]="panel.exittrailingstoplossvalue"
																	[readonly] = "panel.exittrailingstoplosstype === 'N'"
																	kendoTextBox
																/>
																<div class="feature-extra">
																	<div class="info-img">
																		<img src="assets/images/trade/help.svg" />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="exit-wrapper">
												<div class="feature-options">
													<div class="checkbox-feature">
														<label
															class="item"
															[for]="'stop_loss'"
														>
															<input
																type="checkbox"
																id="stop_loss"
																[checked]="panel.exitstoplosstype != 'N'"
																(change)="onCheckboxChange(panel, 'exitstoplosstype', $event.target.checked)"
																kendoCheckBox
															/>
															<span>Stop Loss</span>
														</label>
													</div>
													<div class="combine-feature">
														<div class="radio-feature">
															<label class="item">
																<input name="stop_loss"
																	type="radio" value="P"
																	[(ngModel)]="panel.exitstoplosstype"
																	kendoRadioButton/>
																<span>Percentage</span>
															</label>
															<label class="item">
																<input name="stop_loss"
																	type="radio" value="A"
																	[(ngModel)]="panel.exitstoplosstype"
																	kendoRadioButton/>
																<span>Amount</span>
															</label>
														</div>
														<div class="textbox-feature">
															<input
																class="k-input-inner input-padding boxed-border"
																[(ngModel)]="panel.exitstoplossvalue"
																[readonly] = "panel.exitstoplosstype === 'N'"
																kendoTextBox
															/>
															<div class="feature-extra">
																<div class="info-img">
																	<img src="assets/images/trade/help.svg" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="exit-wrapper">
												<div class="feature-options time-exit">
													<div class="checkbox-feature">
														<label
															class="item"
															[for]="'timed_exit'"
														>
															<input type="checkbox" id="timed_exit"
															[checked]="panel.exittimedexittype != 'N'"
															(change)="onCheckboxChange(panel, 'exittimedexittype', $event.target.checked)"
															kendoCheckBox checked/>
															<span>Time</span>
														</label>
													</div>
													<div class="combine-feature extended">
														<div class="time-exit-wrapper">
															<div class="radio-feature">
																<label class="item">
																	<input
																		type="radio"
																		name="timed_exit"
																		value="C"
																		[(ngModel)]="panel.exittimedexittype"
																		kendoRadioButton/>
																	<span>Weeks</span>
																</label>
																<label class="item">
																	<input
																		name="timed_exit"
																		type="radio"
																		value="H"
																		[(ngModel)]="panel.exittimedexittype"
																		kendoRadioButton/>
																	<span>Days</span>
																</label>
															</div>
			
														</div>

														<div class="textbox-feature">
															<input
																class="k-input-inner input-padding boxed-border"
																[(ngModel)]="panel.exittimedexitvalue"
																[readonly] = "panel.exittimedexittype === 'N'"
																kendoTextBox
															/>
															<div class="feature-extra">
																<div class="info-img">
																	<img src="assets/images/trade/help.svg" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											
											<div class="exit-wrapper">
												<div class="feature-options time-exit">
													<div class="checkbox-feature">
														<label
															class="item"
															[for]="'extra'"
														>
															<input type="checkbox" id="extra"
															[checked]="panel.exittimedmarketdaypart != 'N'"
															(change)="onCheckboxChange(panel, 'exittimedmarketdaypart', $event.target.checked)"
															kendoCheckBox checked/>
															<span>Market</span>
														</label>
													</div>
													<div class="combine-feature extended">
														<div class="time-exit-wrapper">
															<div class="radio-feature">
																<label class="item">
																	<input
																		type="radio"
																		checked
																		name="extra"
																		value="O"
																		[(ngModel)]="panel.exittimedmarketdaypart"
																		kendoRadioButton/>
																	<span>Open</span>
																</label>
																<label class="item">
																	<input
																		name="extra"
																		type="radio"
																		value="C"
																		[(ngModel)]="panel.exittimedmarketdaypart"
																		kendoRadioButton/>
																	<span>Close</span>
																</label>
														</div>
			
														</div>

														<div class="textbox-feature">
															<div class="feature-extra">
																<div class="info-img">
																	<img src="assets/images/trade/help.svg" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</kendo-expansionpanel-content>
			</div>
		</kendo-expansionpanel>
		<div class="deletepanel" (click)="removePanelItem(i)">
			<img src="/assets/images/trade/x-mark-circle.svg">
		</div>
	</div>
</div>
	<div class="addleg-button" (click)="addPanel()">
		<img src="/assets/images/trade/addicon.svg" class="action-plus-icon"> Add a leg
	</div>
</div>
