/*
           FILENAME: lk_client/src/app/pipes/safe.pipe.ts
	         AUTHOR: Anindya Dutta
 	        SUMMARY: THIS FILE IS USED TO SANITIZE A URL AND MAKE IT A SAFE URL TO BE ADDED AS AN IFRAME SRC IN SCROLL VIEW COMPONENT
            PURPOSE: SANITIZING URL
    IMPORTING FILES: NONE
  SUBSCRIBING FILES: app.module.ts | scrollview.component.ts
   LAST COMMIT DATE: July 13, 2020
*/

// IMPORTING ANGULAR CORE LIBRARIES
import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

// DECLARING THIS AS A PIPE TO SANITIZE A URL
@Pipe
({
  name: 'safe'
})

// DECLARING THE SafePipe CLASS TO
export class SafePipe implements PipeTransform
{
  // DEFINING THE CONSTRUCTOR OF THE CLASS
  constructor
  (
    // sanitizer IS THE ALIAS OF THE DomSanitizer, WE WILL USE THIS sanitizer ALIAS TO CALL THE FUNCTIONS OF DomSanitizer CLASS
    private sanitizer: DomSanitizer
  ) 
  {
  }

  // THIS FUNCTION WILL RECEIVE THE URL AND RETURN IT IN SANITIZED FORMAT TO BE USED AS IFRAME SRC
  transform(url)
  {
    // RETURN THE SANITIZED URL
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
