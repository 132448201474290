<!-- POPUP WINDOWS TO SHOW MESSAGE IF THE DEIVE(S) IS/ARE BLOCKED -->
<ng-template #joinRoomDeviceBlocked id="joinRoomDeviceBlocked" let-modal>
  <div id="joinaroom-device-blocked-popup" class="lkpopup modal-body">
    <h3>Device Access Blocked</h3>
    <div class="textline">
      Sorry! To continue, you need to allow access to a microphone or camera, or both.
    </div>
    <div class="popup-contents textlight gap3">
      <div>Please refresh the browser in one of the following ways:</div>
      <div>
        <div class="clearfix" id="joinaroom-popup-instruction-images">
          <div class="icon-f5">
            <div class="gap"><span class="li-count">A</span> Press F5</div>
            <img src="../assets/images/icon-f5.svg">
          </div>
          <div class="icon-refresh-button">
            <div class="gap"><span class="li-count">B</span> Click the Refresh button</div>
            <img src="../assets/images/icon-refresh.svg">
          </div>
        </div>
        <div class="popup-dismiss-button gap2">
          <button kendoButton class="btn-primary" (click)="modal.close();">OK</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<!-- POPUP WINDOWS TO SHOW MESSAGE  -->
<ng-template #contentsPopup let-modal>
  <div class="modal-body">
    <h3 id="popup_title"></h3>
    <div id="popup_tagline"></div>
    <div id="popup_content" class="popup-contents textlight gap3"></div>
  </div>
</ng-template>
