<section class="main-layout-container bg-white position-relative">
	<div class="simple-content tradenow">
		<div class="filter-content">
			<div class="top-bar filters-bar pull-right">
				<ng-container>
					<app-search [config]="topbarConfig" (onChangeSwitch)="onToggleOverview ($event)"></app-search>
				</ng-container>
			</div>
		</div>
		<div class="tradenow-container" *ngIf="currentView === 'dashboard'" @enterExitLeft>
			<div class="flip-card" [ngClass]="{'flipped': flipCard}">
				<div class="flip-card-inner">
					<div class="main-content pt-0 flip-card-front">
						<div class="avatar-container" *ngIf="!flipCard">
							<div class="avatar">
								<div class="dropdownlist-content">
									<div class="label">Create Your Trade</div>
									<div class="dropdownlist">
										<kendo-dropdownlist
											[style.width.px]="170"
											*ngIf="avatarList.length"
											class="dark-border"
											[data]="avatarList"
											[(ngModel)]="tradeData.avatar_id"
											textField="name"
											valueField="avatar_id"
											[valuePrimitive]="true"
										>
										</kendo-dropdownlist>
									</div>
								</div>
								
								<!--<div class="switch-container d-flex align-items-center">-->
								<!--	<span class="me-2">Private</span>-->
								<!--	<kendo-switch class="attoswitch"-->
								<!--	></kendo-switch>-->
								<!--	<span class="ms-2 d-flex align-items-center">Public <img-->
								<!--			src="assets/images/lock-dark-icon.svg" class="ms-1"-->
								<!--	/></span>-->
								<!--</div>-->
							</div>
							<div class="instruction">
								<div class="instruction-child">
									<img src="/assets/images/trade/step1.svg" class="list-icon" />
									<p class="list-text">Pick a Security and a Strategy.</p>
								</div>
								<div class="instruction-child">
									<img src="/assets/images/trade/step2.svg" class="list-icon" />
									<p class="list-text">Place your Entry and Exit instructions.</p>
								</div>
								<div class="instruction-child">
									<img src="/assets/images/trade/file.svg" class="list-icon" />
									<p class="list-text">Add a Leg if necessary.</p>
								</div>
								<div class="instruction-child">
									<img src="/assets/images/trade/preview.svg" class="list-icon" />
									<p class="list-text">Flip the <b>Overview</b> switch above to find out how to use this page.</p>
								</div>
							</div>
			
						</div>
						<div class="row trade-card" *ngIf="!flipCard" >
							<div class="col-md-12">
								<kendo-card class="trade-card-content" [width]="'100%'" style="">
									<kendo-card-header class="k-hstack">
										<div class="form-containter">
											<div class="options">
												<div class="dropdownlist" *ngIf="typeList.length" >
													<div class="label">Type:</div>
													<kendo-dropdownlist
														[style.min-width.px]="90"
														[data]="typeList"
														[(ngModel)]="tradeData.type"
														textField="tickertype"
														valueField="tickertype_id"
														[valuePrimitive]="true"
														(selectionChange)="getTickerByTickerTypeId($event)"
														>
													</kendo-dropdownlist>
												</div>
												<div class="dropdownlist">
													<div class="label">Chart:</div>
													<kendo-dropdownlist
														[style.min-width.px]="160"
														[data]="chartList"
														[(ngModel)]="tradeData.chartinterval"
														textField="text"
														valueField="value"
														[valuePrimitive]="true"
													>
													</kendo-dropdownlist>
												</div>
												<div class="dropdownlist" *ngIf="tickerList.length">
													<div class="label">Ticker:</div>
													<kendo-multicolumncombobox
														[style.min-width.px]="130"
														[data]="filteredTickerList"
														[(ngModel)]="tradeData.ticker_id"
														textField="ticker_id"
														valueField="ticker_id"
														[valuePrimitive]="true"
														[filterable]="true"
														(filterChange)="handleFilter($event)"
														(selectionChange)="getDerivativeTickerByTickerTypeId($event)"
													>
													<kendo-combobox-column field="ticker_id" title="Ticker Id" [width]="122">
													</kendo-combobox-column>
													<kendo-combobox-column field="ticker" title="Ticker Name" [width]="150">
													</kendo-combobox-column>
												</kendo-multicolumncombobox>
												</div>

												<div class="dropdownlist" *ngIf="strategyList.length" >
													<div class="label">Strategy:</div>
													<kendo-combobox
														[style.min-width.px]="200"
														[data]="filteredStrategyList"
														[(ngModel)]="tradeData.tradestrategy_id"
														textField="tradestrategy"
														valueField="tradestrategy_id"
														[valuePrimitive]="true"
														[filterable]="true"
														(filterChange)="handleFilterStrategy($event)"
													>
												</kendo-combobox>
												</div>

												<div class="dropdownlist">
													<div class="label test">Derivative Type:</div>
													<kendo-dropdownlist
														[style.max-width.px]="104"
														[data]="derivativeType"
														[(ngModel)]="tradeData.derivative_type"
														textField="text"
														valueField="value"
														[valuePrimitive]="true"
													>
													</kendo-dropdownlist>
												</div>

												<div class="dropdownlist" *ngIf="tradeData.derivative_type != 'Options'">
													<div class="label">Derivative Ticker:</div>
													<kendo-dropdownlist
														[style.min-width.px]="90"
														[style.width.px]="90"
														[data]="derivativeTickerList"
														[(ngModel)]="tradeData.derivativeTicker"
													>
													</kendo-dropdownlist>
												</div>
												
											</div>
											<button kendoButton class="btn green-button" (click)="onSubmit()">Submit</button>
										</div>
									</kendo-card-header>
									<kendo-card-body class="leg-wrapper">
										<app-leg [tradeType]="tradeData.ticker_id"></app-leg>
									</kendo-card-body>
								</kendo-card>
							</div>
						</div>
					</div>
					
					<!--Flip Card Back-->
					<div class="flip-card-back">
						<div class="content">
							<div class="message-block">
								Your trade has been saved successfully.
							</div>
							<div class="buttons-block">
								<div class="button-child secondary" (click)="newTrack()">Create New Trade</div>
								<div class="button-child" (click)="tradeManager()">Goto Trade Manager</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div class="main-wrapper bg-white overview-wrapper" *ngIf="currentView === 'overview'"  @enterExitRight>
			<div class="row">
				<div class="col-sm-12">
					<app-dashboard-overview></app-dashboard-overview>
				</div>
			</div>	
		</div>

	</div>
</section>
