import {Component, ViewChild} from "@angular/core";
import {chevronRightIcon, chevronDownIcon, SVGIcon} from "@progress/kendo-svg-icons";
import {LegComponent} from "./leg/leg.component"
import {NetworkService} from "src/app/services/network.service";
import {Router} from "@angular/router";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {HelperService} from "src/app/services/helper.service";

@Component({
	selector: "app-tradenow",
	templateUrl: "./tradenow.component.html",
	styleUrls: ["./tradenow.component.scss"],

	animations: [
		// EXTENSION CARDS SHOW / HIDE ANIMATION
		trigger('cardFlip', [
			state('default', style({
				transform: 'rotateY(0deg)'
			})),
			state('flipped', style({
				transform: 'rotateY(80deg)'
			})),
			transition('default => flipped', [
				animate('500ms')
			]),
			transition('flipped => default', [
				animate('500ms')
			])
		]),
		// EXTENSION CARDS SHOW / HIDE ANIMATION
		trigger('enterExitLeft', [
			transition(':enter', [
				style({
					opacity: 0,
					transform: 'translateX(-500px)'
				}),
				animate(
					'400ms ease-in',
					style({
						opacity: 1,
						transform: 'translateX(0)'
					})
				),
			]),
			transition(':leave', [
				animate(
					'400ms ease-in',
					style({
						opacity: 0,
						transform: 'translateX(-500px)'
					})
				),
			]),
		]),

		// EXTENSION OVERVIEW SHOW / HIDE ANIMATION
		trigger('enterExitRight', [
			transition(':enter', [
				style({
					opacity: 0,
					transform: 'translateX(500px)'
				}),
				animate(
					'400ms ease-in',
					style({
						opacity: 1,
						transform: 'translateX(0)'
					})
				),
			]),
			transition(':leave', [
				animate(
					'400ms ease-in',
					style({
						opacity: 0,
						transform: 'translateX(500px)'
					})
				),
			]),
		]),
	]
})
export class TradeNowComponent
{
	// CONFIGURATION FOR ADD NEW LIST BUTTON AND MESSAGES
	topbarConfig: any =
		{
			search: false,
			overviewSwitch: true,
			addNew: false,
		};

	@ViewChild(LegComponent) legComponent!: LegComponent;

	public chevronRightIcon: SVGIcon = chevronRightIcon;
	public chevronDownIcon: SVGIcon = chevronDownIcon;
	public flipCard: boolean = false;

	public currentView: any = 'dashboard';

	public avatarList: Array<any> = [];
	public typeList: Array<any> = [];
	public chartList: Array<any> = [{text: 'Daily', value: 'D'}, {text: 'Good Til Canceled', value: 'G'}];
	public derivativeType: Array<any> = [{text: 'ETF', value: 'E'}, {text: 'Options', value: 'o'}];
	public tickerList: Array<any> = [];
	public derivativeTickerList: Array<any> = [];
	public strategyList: Array<any> = [];
	public filteredTickerList: Array<any>
	public filteredStrategyList: Array<any>
	public tradeData = {
		type: null,
		chartinterval: null,
		ticker_id: null,
		derivativeTicker: null,
		tradestrategy_id: null,
		avatar_id: null,
		derivative_type: null,
	}

	constructor
		(
			public router: Router,
			public helper: HelperService, // LOADING HELPER SERVICE TO USE APP GLOBAL VARIABLES.
			private networkService: NetworkService // LOADING NetworkService CLASS WITH ALIAS networkService TO GET ACCESS TO THE NETWORK SERVICES.
		)
	{
	}

	// FUNCTION TO SET INITIAL DATA
	ngOnInit()
	{
		this.tradeData =
		{
			type: 'STOCK',
			chartinterval: 'D',
			ticker_id: this.tickerList[0],
			derivativeTicker: '',
			tradestrategy_id: 'BUY',
			avatar_id: this.helper.avatarData?.avatar_id,
			derivative_type: 'E'
		}

		this.getAllTickerTypes();
		this.getAllTradeStrategies();
		this.getAllAvatars();
		this.getTickerByTickerTypeId({tickertype_id: 'STOCK'});
	}

	// FUNCTION TO GET TICKER DATA FROM API
	getTickerByTickerTypeId(type: any)
	{
		this.networkService.get('lktraders/tradenow/get-ticker-by-ticker-type-id/' + type.tickertype_id).then
			(
				(response: any) =>
				{
					if (response.success)
					{
						this.tickerList = response.data;
						this.tradeData.ticker_id = this.tickerList[0].ticker_id;
						this.filteredTickerList = this.tickerList;
					}

				}
			)
	}

	// FUNCTION TO GET AVATAR DATA FROM API
	getAllAvatars()
	{
		// TODO: NEED TO GET LK PHONE ID FROM HELPER SERVICE
		this.networkService.get('lktraders/tradenow/get-all-trade-avatars/' + this.helper.lkphone_id).then
			(
				(response: any) =>
				{
					if (response.success)
					{
						this.avatarList = response.data
						this.tradeData.avatar_id = this.avatarList[0].avatar_id
					}

				}
			)
	}

	// FUNCTION TO GET TRADE STRATEGIES DATA FROM API
	getAllTradeStrategies()
	{
		this.networkService.get('lktraders/tradenow/get-all-trade-strategies').then
			(
				(response: any) =>
				{
					if (response.success)
					{
						this.strategyList = response.data;
						this.filteredStrategyList = this.strategyList;
					}
				}
			)
	}

	// FUNCTION TO GET TICKER TYPES FROM API
	getAllTickerTypes()
	{
		this.networkService.get('lktraders/tradenow/get-all-ticker-types').then
			(
				(response: any) =>
				{
					if (response.success)
					{
						this.typeList = response.data
					}
				}
			)
	}

	getDerivativeTickerByTickerTypeId(ticker: any)
	{
		this.networkService.get('lktraders/tradenow/get-derivative-ticker-by-ticker-id/' + ticker.ticker_id).then
			(
				(response: any) =>
				{
					if (response.success)
					{
						this.derivativeTickerList = response.data
					}

				}
			)
	}

	// FUNCTION TO SUBMIT LEG DATA
	public onSubmit()
	{
		const tradeLegs = this.legComponent.panelList

		for (let index = 0; index < tradeLegs.length; index++)
		{
			const tradeLeg = tradeLegs[index];
			delete tradeLeg.isExpanded
			tradeLeg.exittakeprofitvalue = tradeLeg.exittakeprofitvalue ? Number(tradeLeg.exittakeprofitvalue) : null
			tradeLeg.exittrailingstoplossvalue = tradeLeg.exittrailingstoplossvalue ? Number(tradeLeg.exittrailingstoplossvalue) : null
			tradeLeg.exitstoplossvalue = tradeLeg.exitstoplossvalue ? Number(tradeLeg.exitstoplossvalue) : null
			tradeLeg.exittimedexitvalue = tradeLeg.exittimedexitvalue ? Number(tradeLeg.exittimedexitvalue) : null
		}
		this.tradeData['tradeLegs'] = this.legComponent.panelList;
		// this.tradeData['avatar_id'] = this.helper.avatarData?.avatar_id;
		this.networkService.post('lktraders/tradenow/create-trade-and-trade-legs', this.tradeData).then((response =>
		{
			this.flipCard = !this.flipCard;
		}))
	}

	// FUNCTION TO FILTER THE TICKER LIST
	public handleFilter(value)
	{

		this.filteredTickerList = this.tickerList.filter(ticker =>
			ticker.ticker.toLowerCase().includes(value.toLowerCase()) || ticker.ticker_id.toLowerCase().includes(value.toLowerCase())
		);

	}

	// FUNCTION TO FILTER THE STRATEGY LIST
	public handleFilterStrategy(value)
	{

		this.filteredStrategyList = this.strategyList.filter(strategy =>
			strategy.tradestrategy.toLowerCase().includes(value.toLowerCase()) || strategy.tradestrategy_id.toLowerCase().includes(value.toLowerCase())
		);

	}

	// FUNCTION TO FLIP CARD BACK TO CREATE TRADE
	public newTrack()
	{
		this.flipCard = !this.flipCard;
		this.legComponent.panelList = [];
		this.legComponent.panelList.push({...this.legComponent.emptyLegForm});
	}

	// FUNCTION TO ROUTE TO TRADE MANAGER
	public tradeManager()
	{
		this.router.navigate(['/tradehistory']);
	}

	// ON DASHBOARD TOGGLE VIEW / OVERVIEW SWITCH CHANGE.
	onToggleOverview(switchState: boolean)
	{
		console.log('receiving', switchState);
		this.currentView = '';

		// IF VIEW EXTENSION IS ENABLED.
		setTimeout(() =>
		{
			this.currentView = switchState ? 'overview' : 'dashboard';
		}, 500);
	}
}
