<!--<div class="namebox {{helper.layoutType}}" *ngIf="editing">-->
<!--	<input id="userinputbox" #inlineEditControl [required]="required" (keyup.enter)="onEnter($event)" (blur)="onBlur($event)"-->
<!--	[type]="type" [placeholder]="placeholderTxt" [value]="value" (focus)="inputFocused()" value="" />-->
<!--</div>-->
<div *ngIf="!editing && autoEditing">
	<div (click)="redirectToAccount()" tabindex="0"
			class="inline-edit">{{getValue}}</div>
</div>
<div *ngIf="!editing && !autoEditing">{{getValue}}</div>
<div id="hideusername">{{value}}</div>
