/*
		   FILENAME: lk_client/src/app/components/utils/popup/popup.component.ts
	         AUTHOR: Qasim Zubair
 	        SUMMARY: DISPLAYS A POPUP TO USER.
            PURPOSE: TO DISPLAY ANY MESSAGE OR CONTENT IN A POPUP TO USER.
    IMPORTING FILES: N/A
  SUBSCRIBING FILES: utils.module.ts
   LAST COMMIT DATE: July 14, 2020
*/

// IMPORTING THE ANGULAR MODULES FOR PERFORMING BASIC ANGULAR FRAMEWORK OPERATIONS.
import {Component, OnInit} from '@angular/core';

// COMPONENT DECLARATION. HERE WE CAN DEFINE HTML TEMPLATE, CSS FILES AND COMPONENT OPTIONS.
@Component
({
	selector: 'app-popup',
	templateUrl: './popup.component.html',
	styleUrls: ['./popup.component.scss']
})

// DECLARING THE PopupComponent CLASS WITH EXPORT SO THAT WE CAN IMPORT THIS SERVICE INTO ANY OTHER COMPONENT OR SERVICE.
export class PopupComponent implements OnInit
{
	// CLASS CONSTRUCTOR, THIS WILL BE FIRST FUNCTION TO BE EXECUTED WHEN THIS CLASS LOADS.
	constructor() {}
	
	// ANGULAR LIFE CYCLE HOOK THAT WILL BE CALLED WHEN THIS COMPONENT IS INITIALIZED.
	ngOnInit() {}
}
